import {Card, CardBody, Progress, Row} from "reactstrap";
import React from "react";

const QtdGruposCard = (props) => {
    return (
        <Card color="primary" className="text-white">
            <CardBody>
                <h5 className="mt-1 text-white">
                    <i className="mdi mdi-bullseye-arrow me-3"></i> Quantidade de grupos
                </h5>
                <h4 className="mt-4 text-white">2,456</h4>
                <Row>
                    <div className="col-7">
                        <p className="mb-0">
              <span className="text-success me-2">
                {" "}
                  0.16% <i className="mdi mdi-arrow-up"></i>{" "}
              </span>
                        </p>
                    </div>
                    <div className="col-5 align-self-center">
                        <Progress
                            value="62"
                            color="success"
                            className="bg-transparent progress-sm"
                            size="sm"
                        />
                    </div>
                </Row>
            </CardBody>
        </Card>
    );
};

export default QtdGruposCard;
