import {
    Button,
    CloseButton,
    Col,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner,
    UncontrolledAlert,
} from "reactstrap";
import React, {useEffect} from "react";
import {bindActionCreators} from "redux";
import * as CampanhasActions from "../../../../../store/campanhas/actions";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import * as ConexoesActions from "../../../../../store/conexoes/actions";
import {camposCampanhas, fieldsCampanhaClean} from "./fields/fields";
import {getBase64} from "../../../../../util/image";
import {validateCampanha} from "./fields/validate";
import ConexaoCampanhaSelect from "./fields/ConexaoCampanhaSelect";
import * as links from "../../../../../db/firestore/links";

const ModalCriarEditarCampanha = (props) => {
    const handleClose = () => props.createCampanhaExit();

    function handleChangeImage(e) {
        try {
            if (e.target?.files[0].type.includes("image")) {
                const url = URL.createObjectURL(e.target.files[0]);
                getBase64(e.target.files[0]).then((baseUrl) => {
                    props.createCampanhaSet(baseUrl, camposCampanhas.IMAGEM);
                    return () => URL.revokeObjectURL(url);
                });
            } else {
                props.createCampanhaFailed({[camposCampanhas.IMAGEM]: true});
            }
        } catch (e) {
            console.error(e);
            props.createCampanhaFailed({[camposCampanhas.IMAGEM]: true});
        }
    }

    const handleSubmit = () => {
        const clean = fieldsCampanhaClean(props.campanhasState.campanha);
        const validation = validateCampanha({...clean});
        if (validation.isValid) {
            props.createCampanhaFetch({...clean});
        } else {
            props.createCampanhaFailed(validation.errors);
        }
    };

    useEffect(() => {
        if (props.conexaoState.conexoes.length === 0) {
            props.getConexoes();
        }
    }, []);

    return (
        <Modal
            title="Criar Campanha"
            centered
            size="lg"
            isOpen={props.campanhasState.isOpenModalCriarEditarCampanha}
            scrollable
            toggle={handleClose}
        >
            <ModalHeader>
                <h5 className="modal-title mt-0">{props.t("Campaign Creation")}</h5>
                <button
                    type="button"
                    onClick={handleClose}
                    className="btn-close-modal"
                    data-dismiss="modal"
                    aria-label="Close"
                ></button>
            </ModalHeader>
            <ModalBody>
                {props.campanhasState.error && (
                    <UncontrolledAlert
                        color="danger"
                        className="alert-dismissible fade show"
                        role="alert"
                        fade
                    >
                        Por favor preencha os campos corretamente para criar a campanha
                        {Object.keys(props.campanhasState.campanhaFormError).map(key => {
                            if (props.campanhasState.campanhaFormError[key]) {
                                return <span key={key}>{key}</span>
                            }
                        })}
                        {props.campanhasState.campanhaFormError.conexao && (
                            <ul>
                                <br/>
                                <li>Escolha uma conexão válida</li>
                            </ul>
                        )}
                    </UncontrolledAlert>
                )}
                <FormGroup row>
                    <Label for="imagem-campanha-input" sm={3}>
                        {props.t("Campaign Image")}
                    </Label>
                    <Col sm={9}>
                        {props.campanhasState.campanha?.imagem === "" && (
                            <>
                                <Input
                                    id="imagem-campanha-input"
                                    type={"file"}
                                    className={"border border-secondary"}
                                    onChange={handleChangeImage}
                                    invalid={props.campanhasState.campanhaFormError.imagem}
                                    accept={"image/*"}
                                />
                                <FormFeedback>
                                    Por Favor Adicione uma imagem válida
                                </FormFeedback>
                            </>
                        )}
                        {props.campanhasState.campanha?.imagem !== "" && (
                            <>
                                <CloseButton
                                    onClick={() =>
                                        props.createCampanhaSet("", camposCampanhas.IMAGEM)
                                    }
                                />
                                <img
                                    className="img-thumbnail rounded-circle avatar-xl"
                                    alt="Imagem Grupo"
                                    src={props.campanhasState.campanha?.imagem}
                                />
                            </>
                        )}
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="nome-campanha-input" sm={3}>
                        {props.t("Campaign Name")}
                    </Label>
                    <Col sm={9}>
                        <Input
                            id="nome-campanha-input"
                            type="text"
                            placeholder={"Digite o nome da Campanha"}
                            value={props.campanhasState.campanha.nome}
                            className="border border-secondary form-control"
                            onChange={(e) =>
                                props.createCampanhaSet(e.target.value, camposCampanhas.NOME)
                            }
                            invalid={props.campanhasState.campanhaFormError.nome}
                        />
                        <FormFeedback>Por Favor preencha com um nome válido</FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="link-campanha-input" sm={3}>
                        {props.t("Campaign Zaplink")}
                    </Label>
                    <Col sm={9}>
                        <InputGroup className="border border-secondary">
              <span className="input-group-text">
                {process.env.REACT_APP_ZAPEASE_URL}/{links.getUserLink()}/
              </span>
                            <Input
                                id="link-campanha-input"
                                type="text"
                                disabled={props?.campanhasState?.campanha?.id}
                                invalid={props.campanhasState.campanhaFormError.link}
                                value={props?.campanhasState?.campanha?.link}
                                onChange={(e) =>
                                    props.createCampanhaSet(e.target.value, camposCampanhas.LINK)
                                }
                            />
                        </InputGroup>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="limite-campanha-input" sm={3}>
                        {/*{props.t("Campaign People Limit")}*/}
                        {props.t("Campaign Clicks Limit")}
                    </Label>
                    <Col sm={9}>
                        <Input
                            id="limite-campanha-input"
                            type={"number"}
                            placeholder={"Digite o limite"}
                            className="border border-secondary"
                            onChange={(e) =>
                                props.createCampanhaSet(
                                    e.target.value,
                                    camposCampanhas.LIMITE_PESSOAS,
                                )
                            }
                            value={props.campanhasState.campanha.limitePessoas}
                            invalid={props.campanhasState.campanhaFormError.limitePessoas}
                        />
                        <FormFeedback>Por Favor preencha com um limite válido</FormFeedback>
                    </Col>
                </FormGroup>
                {/*<FormGroup row>*/}
                {/*    <Label for="permite-lead-duplicado-switch" sm={3}>*/}
                {/*        {props.t("Campaign Lead Duplicate")}*/}
                {/*    </Label>*/}
                {/*    <Col sm={9}>*/}
                {/*        <Switch*/}
                {/*            id="permite-lead-duplicado-switch"*/}
                {/*            uncheckedIcon={<Offsymbol/>}*/}
                {/*            checkedIcon={<OnSymbol/>}*/}
                {/*            onColor="#626ed4"*/}
                {/*            className="form-label"*/}
                {/*            onChange={(e) =>*/}
                {/*                props.createCampanhaSet(e, camposCampanhas.IS_LEAD_DUPLICADO)*/}
                {/*            }*/}
                {/*            checked={props.campanhasState.campanha.isLeadDuplicado}*/}
                {/*            rows={4}*/}
                {/*            cols={40}*/}
                {/*        />*/}
                {/*    </Col>*/}
                {/*</FormGroup>*/}
                {props.conexaoState.conexoes.length === 0 && (
                    <Spinner type="border" className="m-auto" color="primary"/>
                )}
                {props.conexaoState.conexoes.length > 0 && <ConexaoCampanhaSelect/>}
                {/*TODO Implementar Lógica*/}
                {/*<FormGroup row>*/}
                {/*    <Label for="criar-grupos-switch" sm={3}>*/}
                {/*        {props.t("Campaign Groups Auto")}*/}
                {/*    </Label>*/}
                {/*    <Col sm={9}>*/}
                {/*        <Switch*/}
                {/*            id="criar-grupos-switch"*/}
                {/*            uncheckedIcon={<Offsymbol/>}*/}
                {/*            checkedIcon={<OnSymbol/>}*/}
                {/*            onColor="#626ed4"*/}
                {/*            className="form-label"*/}
                {/*            onChange={(e) =>*/}
                {/*                props.createCampanhaSet(e, camposCampanhas.IS_CRIAR_GRUPO)*/}
                {/*            }*/}
                {/*            checked={props.campanhasState.campanha.isCriarGrupo}*/}
                {/*            rows={4}*/}
                {/*            cols={40}*/}
                {/*        />*/}
                {/*    </Col>*/}
                {/*</FormGroup>*/}
                {/*{props.campanhasState.campanha.isCriarGrupo && <GroupsAutoFields/>}*/}
                {/*<FormGroup row>*/}
                {/*    <Label for="boasvindas-campanha-switch" sm={3}>*/}
                {/*        {props.t("Campaign Groups Is Default Message")}*/}
                {/*    </Label>*/}
                {/*    <Col sm={9}>*/}
                {/*        <Switch*/}
                {/*            id="boasvindas-campanha-switch"*/}
                {/*            uncheckedIcon={<Offsymbol/>}*/}
                {/*            checkedIcon={<OnSymbol/>}*/}
                {/*            onColor="#626ed4"*/}
                {/*            className="form-label"*/}
                {/*            onChange={(e) =>*/}
                {/*                props.createCampanhaSet(*/}
                {/*                    e,*/}
                {/*                    camposCampanhas.IS_MENSAGEM_BOAS_VINDAS,*/}
                {/*                )*/}
                {/*            }*/}
                {/*            checked={props.campanhasState.campanha.isMensagemBoasVindas}*/}
                {/*            rows={4}*/}
                {/*            cols={40}*/}
                {/*        />*/}
                {/*    </Col>*/}
                {/*</FormGroup>*/}
                {/*{props.campanhasState.campanha.isMensagemBoasVindas && (*/}
                {/*    <BoasVindasFields/>*/}
                {/*)}*/}
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={handleClose}
                    className="btn btn-primary waves-effect"
                    data-dismiss="modal"
                >
                    {props.t("Close")}
                </Button>
                <Button
                    type="button"
                    className="btn btn-success waves-effect waves-light"
                    onClick={handleSubmit}
                >
                    {props?.campanhasState?.campanha?.id
                        ? props.t("Update Campaign")
                        : props.t("Create Campaign")}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

const mapStatetoProps = (state) => ({
    campanhasState: state.campanhas,
    conexaoState: state.conexoes,
});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators({...CampanhasActions, ...ConexoesActions}, dispatch);

ModalCriarEditarCampanha.propTypes = {
    t: PropTypes.any,
    campanhasState: PropTypes.object,
    conexaoState: PropTypes.object,
    createCampanhaExit: PropTypes.func,
    getConexoes: PropTypes.func,
    createCampanhaSet: PropTypes.func,
    createCampanhaFailed: PropTypes.func,
    createCampanhaFetch: PropTypes.func,
};
export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(ModalCriarEditarCampanha));
