import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import PagesComingsoon from "../../Utility/pages-comingsoon";
import * as links from "../../../db/firestore/links";
import * as zaphook from "../../../webhook/zaphook";
import GruposIndisponiveis from "./GruposIndisponiveis";

const Redirect = () => {
    const {idLink, idUser} = useParams();
    const [isNotFound, setIsNotFound] = useState(false);
    const [isLoading,] = useState(true);
    useEffect(() => {
        links
            .getLinkData(idUser)
            .then((r) => {
                zaphook.postRedirection({...r[idLink], idLink});
                if (r[idLink].url) {
                    window.location.assign(r[idLink].url);
                } else {
                    setIsNotFound(true);
                }

            })
            .catch((e) => {
                console.error(e);
                setIsNotFound(true);
            });
    }, []);

    if (isNotFound) {
        return <GruposIndisponiveis/>;
    }

    if (isLoading) {
        return <PagesComingsoon/>;
    }
};

export default withTranslation()(Redirect);
