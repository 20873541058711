import {withTranslation} from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import * as MensagensActions from "../../../../store/mensagens/actions";
import {connect} from "react-redux";
import PropTypes from "prop-types";

const RemoverMensagemAlert = (props) => {
    const [successAlert, setSuccessAlert] = useState(false);

    useEffect(() => {
        setSuccessAlert(props.mensagensState.isOpenAlertRemoverMensagemSuccess);
    }, [props.mensagensState.isOpenAlertRemoverMensagemSuccess]);

    if (props.mensagensState.mensagemRemover === null) {
        return null;
    }

    return (
        <div>
            {props.mensagensState.mensagemRemover &&
            props.mensagensState.isOpenAlertRemoverMensagem ? (
                <SweetAlert
                    title={props.t("Are you sure?")}
                    warning
                    showCancel
                    confirmButtonText={props.t("Yes, remove!")}
                    cancelBtnText={props.t("Cancel")}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() =>
                        props.removerMensagemFetch(props.mensagensState.mensagemRemover)
                    }
                    onCancel={() => props.removerMensagemExit()}
                >
                    {props.t("Remove")}
                </SweetAlert>
            ) : null}
            {successAlert ? (
                <SweetAlert
                    success
                    title={props.t("Removed")}
                    onConfirm={() => {
                        setSuccessAlert(false);
                    }}
                ></SweetAlert>
            ) : null}
        </div>
    );
};

RemoverMensagemAlert.propTypes = {
    t: PropTypes.any.isRequired,
    mensagensState: PropTypes.any,
    removerMensagemExit: PropTypes.func,
    removerMensagemFetch: PropTypes.func,
};
const mapStatetoProps = (state) => ({mensagensState: state.mensagens});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators(MensagensActions, dispatch);

export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(RemoverMensagemAlert));
