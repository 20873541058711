import React, {useEffect} from "react";
import {Row, Spinner} from "reactstrap";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import * as CampanhasActions from "../../../store/campanhas/actions";
import {bindActionCreators} from "redux";
import BreadCrumbCampanhas from "./components/breadcrumb/BreadCrumbCampanhas";
import CardCampanhaDescription from "./components/card/CardCampanhaDescription";
import ModalCriarEditarCampanha from "./components/modal/ModalCriarEditarCampanha";
import logo from "../../../assets/images/logo-md-dark.png";

const Campanhas = (props) => {
    useEffect(() => {
        props.getCampanhas();
    }, []);

    const isEmptyMessage =
        !props.campanhasState.loadingCampanhas &&
        props.campanhasState.campanhas.length === 0;

    return (
        <div className="page-content">
            <Row>
                <BreadCrumbCampanhas breadcrumbItem={props.t("Campaigns")}/>
            </Row>
            <Row>
                {props.campanhasState.loadingCampanhas && (
                    <Spinner type="grow" className="m-auto" color="primary"/>
                )}
                {props.campanhasState.campanhas.length > 0 &&
                    props.campanhasState.campanhas.map((campanha) => (
                        <CardCampanhaDescription key={campanha.id} campanha={campanha}/>
                    ))}
                {isEmptyMessage && (
                    <div className="text-center">
                        <img src={logo} alt="Logo ZapEase"/>
                        <br/>
                        <h1>{props.t("No Campaigns")}</h1>
                    </div>
                )}
                {props.campanhasState.campanha && <ModalCriarEditarCampanha/>}
            </Row>
        </div>
    );
};
const mapStatetoProps = (state) => ({campanhasState: state.campanhas});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators({...CampanhasActions}, dispatch);

Campanhas.propTypes = {
    t: PropTypes.any,
    campanhasState: PropTypes.object,
    getCampanhas: PropTypes.func.isRequired,
};
export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(Campanhas));
