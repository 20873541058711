import React from "react";
import Microlink from "@microlink/react";
import { Col, FormGroup, Label } from "reactstrap";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as MensagensActions from "../../../../../../store/mensagens/actions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { camposMensagens } from "../fields";

const LinkInputView = (props) => {
  return (
    <div>
      <FormGroup row>
        <Label for="link-preview" sm={3}>
          {props.t("WA Link Preview")}
        </Label>
        <Col sm={9}>
          <Microlink
            url={props.mensagensState.mensagem[camposMensagens.LINK]}
            id="link-preview"
          />
        </Col>
      </FormGroup>
    </div>
  );
};
LinkInputView.propTypes = {
  t: PropTypes.any,
  createMensagensSet: PropTypes.func,
};
const mapStatetoProps = (state) => ({ mensagensState: state.mensagens });
const mapDispatchtoProps = (dispatch) =>
  bindActionCreators(MensagensActions, dispatch);

export default connect(
  mapStatetoProps,
  mapDispatchtoProps,
)(withTranslation()(LinkInputView));
