import {Col, Row} from "reactstrap";
import CampanhaSelect from "./fields/CampanhaSelect";
import ConexaoSelect from "./fields/ConexaoSelect";
import DataTempoSelect from "./fields/DataTempoSelect";
import AgendarButton from "./fields/AgendarButton";
import PropTypes, {object} from "prop-types";
import {withTranslation} from "react-i18next";
import ContatosInputs from "./fields/ContatosInputs";
import {fieldsAgendamentoClean} from "./validation/fields";
import {TIPO_ADD_CONTATOS} from "./validation/tipoOptions";
import * as validation from "./validation/validate";
import {transformAddContatos} from "./validation/transform";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as AgendamentosActions from "../../../../store/agendamento/actions";
import React from "react";

const AddContatos = (props) => {
    const handleAddContatos = () => {
        const clean = fieldsAgendamentoClean({...props.agendamentosState.agendamento, tipo: TIPO_ADD_CONTATOS});
        const valid = validation.validateMensagem(clean);
        if (valid.isValid) {
            props.createAgendamentoErrors({})
            const mensagem = transformAddContatos(clean);
            props.createAgendamento(mensagem)
        } else {
            props.createAgendamentoErrors(valid.errors)
        }
    }
    return (
        <Col md={6} className='offset-md-3'>
            <Row>
                <Col>
                    <div className="alert alert-danger" role="alert">
                        <h4 className="alert-heading">
                            <i className="mdi mdi-alert-outline"></i> Observação!
                        </h4>
                        <p>
                            Ao adicionar muitos contatos de uma vez diretamente pelo Zapease, existe o risco de bloqueio
                            do chip por suspeita de spam. Para evitar problemas, sugerimos adicionar os contatos
                            gradualmente e monitorar o comportamento do chip.
                        </p>
                    </div>
                </Col>
            </Row>
            <CampanhaSelect/>
            <ConexaoSelect/>
            <ContatosInputs/>
            <DataTempoSelect/>
            <AgendarButton handleAgendar={handleAddContatos}/>
        </Col>
    )
}
const mapStatetoProps = (state) => ({agendamentosState: state.agendamentos, campanhasState: state.campanhas});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators(AgendamentosActions, dispatch);
AddContatos.propTypes = {
    t: PropTypes.any,
    agendamentosState: object,
    createAgendamento: PropTypes.func,
    createAgendamentoErrors: PropTypes.func
};
export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(AddContatos));
