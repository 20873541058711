import PropTypes from 'prop-types'
import React, {Component} from "react"

import {connect} from "react-redux"
import {
    changeLayout,
    changeLayoutTheme,
    changeLayoutWidth,
    changeSidebarTheme,
    changeSidebarType,
    changeTopbarTheme
} from "../../store/actions"

import Header from "./Header"
import Sidebar from "./Sidebar"
import Footer from "./Footer"
import Rightbar from "../CommonForBoth/Rightbar"
import withRouter from '../Common/withRouter'

class Layout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
        }
        this.toggleMenuCallback = this.toggleMenuCallback.bind(this)
    }

    capitalizeFirstLetter = string => {
        return string.charAt(1).toUpperCase() + string.slice(2)
    }

    componentDidMount() {
        if (this.props.isPreloader === true) {
            document.getElementById("preloader").style.display = "block"
            document.getElementById("status").style.display = "block"

            setTimeout(function () {
                document.getElementById("preloader").style.display = "none"
                document.getElementById("status").style.display = "none"
            }, 2500)
        } else {
            document.getElementById("preloader").style.display = "none"
            document.getElementById("status").style.display = "none"
        }

        // Scroll Top to 0
        window.scrollTo(0, 0)
        let currentage = this.capitalizeFirstLetter(this.props.router.location.pathname)

        document.title =
            currentage + " | ZapEase - Sistema para Marketing Digital"
        if (this.props.leftSideBarTheme) {
            this.props.changeSidebarTheme(this.props.leftSideBarTheme)
        }

        this.props.changeLayout("detached")
        // this.props.changeLayoutTheme("dark")
        if (this.props.theme) {
            this.props.changeLayoutTheme(this.props.theme)
        }
        if (this.props.layoutWidth) {
            this.props.changeLayoutWidth(this.props.layoutWidth)
        }

        if (this.props.leftSideBarType) {
            this.props.changeSidebarType(this.props.leftSideBarType)
        }
        if (this.props.topbarTheme) {
            this.props.changeTopbarTheme(this.props.topbarTheme)
        }
    }

    toggleMenuCallback = () => {
        if (this.props.leftSideBarType === "default") {
            this.props.changeSidebarType("condensed", this.state.isMobile)
        } else if (this.props.leftSideBarType === "condensed") {
            this.props.changeSidebarType("default", this.state.isMobile)
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="preloader">
                    <div id="status">
                        <div className="spinner-chase">
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div id="layout-wrapper">
                        <Header toggleMenuCallback={this.toggleMenuCallback}/>
                        <Sidebar
                            theme={this.props.leftSideBarTheme}
                            type={this.props.leftSideBarType}
                            isMobile={this.state.isMobile}
                        />
                        <div className="main-content">
                            {this.props.children}
                            <Footer/>
                        </div>
                    </div>
                </div>
                {this.props.showRightSidebar ? <Rightbar/> : null}
            </React.Fragment>
        )
    }
}

Layout.propTypes = {
    changeLayoutWidth: PropTypes.func,
    changeSidebarTheme: PropTypes.func,
    changeSidebarType: PropTypes.func,
    changeTopbarTheme: PropTypes.func,
    children: PropTypes.object,
    isPreloader: PropTypes.any,
    layoutWidth: PropTypes.any,
    leftSideBarTheme: PropTypes.any,
    leftSideBarType: PropTypes.any,
    location: PropTypes.object,
    showRightSidebar: PropTypes.any,
    topbarTheme: PropTypes.any,
    changeLayoutTheme: PropTypes.func,
    theme: PropTypes.any,
}

const mapStatetoProps = state => {
    return {
        ...state.Layout,
    }
}
export default connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeTopbarTheme,
    changeLayoutWidth,
    changeLayoutTheme
})(withRouter(Layout))