import {
    CREATE_MENSAGENS,
    CREATE_MENSAGENS_EXIT,
    CREATE_MENSAGENS_FAIL,
    CREATE_MENSAGENS_FETCH,
    CREATE_MENSAGENS_SET,
    CREATE_MENSAGENS_SUCCESS,
    EDITAR_MENSAGEM,
    ENVIAR_MENSAGENS,
    ENVIAR_MENSAGENS_CONEXAO_SET,
    ENVIAR_MENSAGENS_EXIT,
    ENVIAR_MENSAGENS_FAIL,
    ENVIAR_MENSAGENS_SUCCESS,
    GET_MENSAGENS,
    GET_MENSAGENS_FAIL,
    GET_MENSAGENS_SUCCESS,
    REMOVER_MENSAGEM,
    REMOVER_MENSAGEM_EXIT,
    REMOVER_MENSAGEM_FAIL,
    REMOVER_MENSAGEM_FETCH,
    REMOVER_MENSAGEM_SUCCESS,
} from "./actionTypes";

const initialState = {
    loadingMensagens: false,
    loadingCriarMensagem: false,
    loadingEnviarMensagem: false,
    mensagens: [],
    mensagem: null,
    oldMensagem: null,
    mensagemRemover: null,
    mensagemEnviar: null,
    isOpenModalCriarEditarMensagem: false,
    isOpenAlertRemoverMensagem: false,
    isOpenAlertRemoverMensagemSuccess: false,
    isOpenModalEnviarMensagem: false,
    error: false,
};

const mensagens = (state = initialState, action) => {
    switch (action.type) {
        case GET_MENSAGENS:
            return {...state, loadingMensagens: true};
        case GET_MENSAGENS_SUCCESS:
            return {
                ...state,
                loadingMensagens: false,
                mensagens: action.payload.data,
            };
        case GET_MENSAGENS_FAIL:
            return {...state, loadingMensagens: false, error: action.payload.error};
        case CREATE_MENSAGENS:
            return {
                ...state,
                loadingCriarMensagem: true,
                isOpenModalCriarEditarMensagem: true,
            };
        case CREATE_MENSAGENS_FETCH:
            return {...state, loadingCriarMensagem: true};
        case CREATE_MENSAGENS_SUCCESS:
            return {
                ...state,
                loadingCriarMensagem: false,
                isOpenModalCriarEditarMensagem: false,
                mensagem: null,
                oldMensagem: null,
            };
        case CREATE_MENSAGENS_FAIL:
            return {
                ...state,
                loadingCriarMensagem: false,
                isOpenModalCriarEditarMensagem: false,
                mensagem: null,
                error: true,
                oldMensagem: null,
            };
        case CREATE_MENSAGENS_EXIT:
            return {
                ...state,
                loadingCriarMensagem: false,
                mensagem: null,
                isOpenModalCriarEditarMensagem: false,
                error: false,
                oldMensagem: null,
            };
        case CREATE_MENSAGENS_SET: {
            const data = action.payload.data;
            const field = action.payload.field;
            return {...state, mensagem: {...state.mensagem, [field]: data}};
        }
        case EDITAR_MENSAGEM:
            return {
                ...state,
                loadingCriarMensagem: true,
                isOpenModalCriarEditarMensagem: true,
                mensagem: action.payload.data,
                oldMensagem: action.payload.data,
            };
        case REMOVER_MENSAGEM:
            return {
                ...state,
                isOpenAlertRemoverMensagem: true,
                mensagemRemover: action.payload.data,
            };
        case REMOVER_MENSAGEM_FETCH:
            return {...state, isOpenAlertRemoverMensagem: true};
        case REMOVER_MENSAGEM_EXIT:
            return {
                ...state,
                isOpenAlertRemoverMensagem: false,
                isOpenAlertRemoverMensagemSuccess: false,
                mensagemRemover: null,
                error: false,
            };
        case REMOVER_MENSAGEM_SUCCESS:
            return {
                ...state,
                isOpenAlertRemoverMensagem: false,
                isOpenAlertRemoverMensagemSuccess: true,
            };
        case REMOVER_MENSAGEM_FAIL:
            return {
                ...state,
                isOpenAlertRemoverMensagem: false,
                isOpenAlertRemoverMensagemSuccess: false,
                mensagemRemover: null,
                error: true,
            };
        case ENVIAR_MENSAGENS:
            return {
                ...state,
                isOpenModalEnviarMensagem: true,
                mensagemEnviar: {mensagem: action.payload.data},
            };
        case ENVIAR_MENSAGENS_CONEXAO_SET:
            return {
                ...state,
                mensagemEnviar: {
                    ...state.mensagemEnviar,
                    conexao: action.payload.data,
                },
            };
        case ENVIAR_MENSAGENS_FAIL:
        case ENVIAR_MENSAGENS_EXIT:
            return {
                ...state,
                isOpenModalEnviarMensagem: false,
                mensagemEnviar: null,
                error: true,
                loadingEnviarMensagem: false,
            };
        case ENVIAR_MENSAGENS_SUCCESS:
            return {
                ...state,
                isOpenModalEnviarMensagem: false,
                mensagemEnviar: null,
                error: false,
                loadingEnviarMensagem: false,
            };
        default:
            return state;
    }
};

export default mensagens;
