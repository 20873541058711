import {
    CREATE_AGENDAMENTO,
    CREATE_AGENDAMENTO_END,
    CREATE_AGENDAMENTO_ERRORS,
    CREATE_AGENDAMENTO_FAIL,
    CREATE_AGENDAMENTO_SUCCESS,
    DELETE_AGENDAMENTO,
    EDIT_AGENDAMENTO,
    EDITING_AGENDAMENTO,
    GET_AGENDAMENTOS,
    GET_AGENDAMENTOS_SUCCESS,
} from "./actionTypes";

export const createAgendamento = (agendamento) => ({
    type: CREATE_AGENDAMENTO,
    payload: {data: agendamento},
});
export const createAgendamentoSuccess = () => ({
    type: CREATE_AGENDAMENTO_SUCCESS,
});
export const createAgendamentoEnd = () => ({
    type: CREATE_AGENDAMENTO_END,
});
export const createAgendamentoFail = () => ({
    type: CREATE_AGENDAMENTO_FAIL,
});
export const createAgendamentoErrors = (errors) => ({
    type: CREATE_AGENDAMENTO_ERRORS,
    payload: {data: errors},
});

export const editAgendamento = (agendamento) => ({
    type: EDIT_AGENDAMENTO,
    payload: {data: agendamento},
});

export const deleteAgendamento = (agendamentoId) => ({
    type: DELETE_AGENDAMENTO,
    payload: {data: agendamentoId},
});

export const getAgendamentos = () => ({
    type: GET_AGENDAMENTOS,
});

export const getAgendamentosPorCampanha = (campanhaId) => ({
    type: GET_AGENDAMENTOS,
    payload: {data: campanhaId},
});
export const getAgendamentosSuccess = (agendamentos) => ({
    type: GET_AGENDAMENTOS_SUCCESS,
    payload: {data: agendamentos},
});

export const editingAgendamentos = (data, field) => ({
    type: EDITING_AGENDAMENTO,
    payload: {data, field},
});
