import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Row, Spinner } from "reactstrap";
import BreadCrumbCampanhasGrupos from "./components/breadcrumb/BreadCrumbCampanhasGrupos";
import ModalCriarEditarCampanha from "../../components/modal/ModalCriarEditarCampanha";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as CampanhasActions from "../../../../../store/campanhas/actions";
import { connect } from "react-redux";
import ImportarGruposModal from "../Importar/components/modal/ImportarGruposModal";
import Pages404 from "../../../../Utility/pages-404";
import CardGrupoClickDescription from "./components/card/CardGrupoClickDescription";
import ModalCriarEditarClick from "./components/card/modal/ModalEditarClick";

const Detalhes = (props) => {
  const { idCampanha } = useParams();
  const [grupoClickEdit, setGrupoClickEdit] = useState(null);
  const isModalClick =
    grupoClickEdit !== null && props.campanhasState.isOpenModalEditarCliques;
  useEffect(() => {
    props.getCampanhas();
  }, []);

  useEffect(() => {
    if (props.campanhasState.campanhas.length !== 0) {
      props.getCampanha(idCampanha);
    }
  }, [props.campanhasState.campanhas]);

  if (!props.campanhasState.campanha) {
    return <Pages404 />;
  }

  const hasCampanha =
    !props.campanhasState.loadingGrupos && props.campanhasState?.campanha?.id;
  const hasNotCampanha =
    !props.campanhasState.loadingGrupos && !props.campanhasState?.campanha?.id;
  return (
    <div className="page-content">
      <Row>
        <BreadCrumbCampanhasGrupos
          breadcrumbItem={props.t("Campaigns Details")}
        />
      </Row>
      {props.campanhasState.loadingGrupos && (
        <Spinner type="grow" className="m-auto text-center" color="primary" />
      )}
      {hasCampanha &&
        props.campanhasState?.campanha?.grupos?.map((grupo) => (
          <CardGrupoClickDescription
            key={grupo.subjectTime}
            grupo={grupo}
            handleGroupClickEdit={setGrupoClickEdit}
          />
        ))}
      {hasNotCampanha && window.location.reload()}
      {props.campanhasState?.campanha && <ModalCriarEditarCampanha />}
      <ImportarGruposModal />
      {isModalClick && <ModalCriarEditarClick grupo={grupoClickEdit} />}
    </div>
  );
};

const mapStatetoProps = (state) => ({ campanhasState: state.campanhas });
const mapDispatchtoProps = (dispatch) =>
  bindActionCreators(CampanhasActions, dispatch);

Detalhes.propTypes = {
  t: PropTypes.any,
  campanhasState: PropTypes.object,
  getCampanha: PropTypes.func.isRequired,
  getCampanhas: PropTypes.func.isRequired,
};
export default connect(
  mapStatetoProps,
  mapDispatchtoProps,
)(withTranslation()(Detalhes));
