import {
    Button,
    Col,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    UncontrolledAlert,
} from "reactstrap";
import React, {useState} from "react";
import {bindActionCreators} from "redux";
import * as CampanhasActions from "../../../../../../../../store/campanhas/actions";
import PropTypes, {object} from "prop-types";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

const ModalCriarEditarClick = (props) => {
    const handleClose = () => props.editarCliqueGrupoExit();
    const [limites, setLimites] = useState(props.grupo.limitePessoas)
    let {grupo} = props
    const handleSubmit = () => {
        if (limites >= 1) {
            props.editarCliqueGrupoFetch(props.campanhasState.campanha, grupo)
        }
    };

    if (grupo === null) {
        return null
    }

    return (
        <Modal
            title="Editar Cliques Máximos"
            centered
            size="md"
            isOpen={props.campanhasState.isOpenModalEditarCliques}
            scrollable
            toggle={handleClose}
        >
            <ModalHeader>
                <h5 className="modal-title mt-0">{grupo.subject}</h5>
                <button
                    type="button"
                    onClick={handleClose}
                    className="btn-close-modal"
                    data-dismiss="modal"
                    aria-label="Close"
                ></button>
            </ModalHeader>
            <ModalBody>
                {props.campanhasState.error && (
                    <UncontrolledAlert
                        color="danger"
                        className="alert-dismissible fade show"
                        role="alert"
                        fade
                    >
                        Por favor preencha os campos corretamente para criar a campanha
                    </UncontrolledAlert>
                )}
                <FormGroup row>
                    <Label for="limite-campanha-input" sm={5}>
                        {/*{props.t("Campaign People Limit")}*/}
                        {props.t("Campaign Clicks Limit")}
                    </Label>
                    <Col sm={7}>
                        <Input
                            id="limite-campanha-input"
                            type={"number"}
                            placeholder={"Digite o limite"}
                            className="border border-secondary"
                            onChange={(e) => {
                                setLimites(e.target.value)
                                grupo.limitePessoas = Number(e.target.value)
                            }}
                            value={limites}
                            required
                            invalid={grupo.limitePessoas < 1}
                        />
                        <FormFeedback>Por Favor preencha com um limite válido</FormFeedback>
                    </Col>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={handleClose}
                    className="btn btn-primary waves-effect"
                    data-dismiss="modal"
                >
                    {props.t("Close")}
                </Button>
                <Button
                    type="button"
                    className="btn btn-success waves-effect waves-light"
                    onClick={handleSubmit}
                >
                    Atualizar
                </Button>
            </ModalFooter>
        </Modal>
    );
};

const mapStatetoProps = (state) => ({
    campanhasState: state.campanhas,
});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators({...CampanhasActions}, dispatch);

ModalCriarEditarClick.propTypes = {
    t: PropTypes.any,
    campanhasState: PropTypes.object,
    getConexoes: PropTypes.func,
    editarCliqueGrupoFetch: PropTypes.func,
    editarCliqueGrupoExit: PropTypes.func,
    grupo: object
};
export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(ModalCriarEditarClick));
