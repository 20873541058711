import React, { useEffect } from "react";
import { Row, Spinner } from "reactstrap";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as ConexoesActions from "../../../store/conexoes/actions";
import { bindActionCreators } from "redux";
import CardConexao from "./CardConexao";
import DesconectarAlert from "./DesconectarAlert";
import CardAddConexao from "./CardAddConexao";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ConnectModal from "./modal/ConnectModal";

const Dispositivos = (props) => {
  useEffect(() => {
    props.getConexoes();
  }, []);
  return (
    <div className="page-content">
      <Row>
        <Breadcrumb
          breadcrumbItem={props.t("Devices")}
          title={props.t("ZapEase")}
        />
      </Row>
      <Row>
        {props.conexoes.loading && (
          <Spinner type="grow" className="m-auto" color="primary" />
        )}
        {!props.conexoes.loading &&
          props.conexoes.conexoes.map((value) => (
            <CardConexao value={value} key={value.id} />
          ))}
        {!props.conexoes.loading && <CardAddConexao />}
      </Row>
      <DesconectarAlert />
      {props.conexoes.conexaoConnect && <ConnectModal />}
    </div>
  );
};

const mapStatetoProps = (state) => ({ conexoes: state.conexoes });
const mapDispatchtoProps = (dispatch) =>
  bindActionCreators(ConexoesActions, dispatch);

Dispositivos.propTypes = {
  t: PropTypes.any,
  conexoes: PropTypes.array,
  getConexoes: PropTypes.func,
  loading: PropTypes.bool,
};

export default connect(
  mapStatetoProps,
  mapDispatchtoProps,
)(withTranslation()(Dispositivos));
