import { Col, FormGroup, Label, Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as MensagensActions from "../../../../../../store/mensagens/actions";
import { connect } from "react-redux";
import React from "react";
import { AvField } from "availity-reactstrap-validation";
import { camposMensagens } from "../fields";

const NomeEnqueteView = (props) => {
  return (
    <FormGroup row>
      <Label for="nome-enquete" sm={3}>
        {props.t("WA Poll Question")}
      </Label>
      <Col sm={9}>
        <Row>
          <Col sm={10}>
            <AvField
              id="nome-enquete"
              name="Nome da Enquete"
              type={"text"}
              value={
                props.mensagensState.mensagem[camposMensagens.ENQUETE_NOME]
              }
              errorMessage="Digite o Nome da Enquete"
              className="form-control border border-secondary"
              disabled
            />
          </Col>
        </Row>
      </Col>
    </FormGroup>
  );
};
NomeEnqueteView.propTypes = {
  t: PropTypes.any,
};
const mapStatetoProps = (state) => ({ mensagensState: state.mensagens });
const mapDispatchtoProps = (dispatch) =>
  bindActionCreators(MensagensActions, dispatch);

export default connect(
  mapStatetoProps,
  mapDispatchtoProps,
)(withTranslation()(NomeEnqueteView));
