import {del, get, post, put} from "./requests";
import * as url from "./url";

const postCreateInstance = (name, token) =>
    post(url.POST_CREATE_INSTANCE, {
        // instance
        instanceName: name,
        // "token": "{{apikey}}", // (Optional)
        // "number": "{{number}}", // (Optional)
        qrcode: true, // (Optional)
        integration: "WHATSAPP-BAILEYS" // WHATSAPP-BAILEYS | WHATSAPP-BUSINESS (Default WHATSAPP-BAILEYS)
        // settings (Optional)
        // "rejectCall": false,
        // "msgCall": "",
        // "groupsIgnore": false,
        // "alwaysOnline": false,
        // "readMessages": false,
        // "readStatus": false,
        // "syncFullHistory": false,
        // // proxy (Optional)
        // "proxyHost": "",
        // "proxyPort": "",
        // "proxyProtocol": "",
        // "proxyUsername": "",
        // "proxyPassword": "",
        // // webhook (Optional)
        // "webhookUrl": "",
        // "webhookByEvents": false,
        // "webhookBase64": true,
        // "webhookEvents": [
        //     "APPLICATION_STARTUP",
        //     "QRCODE_UPDATED",
        //     "MESSAGES_SET",
        //     "MESSAGES_UPSERT",
        //     "MESSAGES_UPDATE",
        //     "MESSAGES_DELETE",
        //     "SEND_MESSAGE",
        //     "CONTACTS_SET",
        //     "CONTACTS_UPSERT",
        //     "CONTACTS_UPDATE",
        //     "PRESENCE_UPDATE",
        //     "CHATS_SET",
        //     "CHATS_UPSERT",
        //     "CHATS_UPDATE",
        //     "CHATS_DELETE",
        //     "GROUPS_UPSERT",
        //     "GROUP_UPDATE",
        //     "GROUP_PARTICIPANTS_UPDATE",
        //     "CONNECTION_UPDATE",
        //     "LABELS_EDIT",
        //     "LABELS_ASSOCIATION",
        //     "CALL",
        //     "TYPEBOT_START",
        //     "TYPEBOT_CHANGE_STATUS"
        // ],
        // // rabbitmq (Optional)
        // "rabbitmqEnabled": true,
        // "rabbitmqEvents": [
        //     "APPLICATION_STARTUP",
        //     "QRCODE_UPDATED",
        //     "MESSAGES_SET",
        //     "MESSAGES_UPSERT",
        //     "MESSAGES_UPDATE",
        //     "MESSAGES_DELETE",
        //     "SEND_MESSAGE",
        //     "CONTACTS_SET",
        //     "CONTACTS_UPSERT",
        //     "CONTACTS_UPDATE",
        //     "PRESENCE_UPDATE",
        //     "CHATS_SET",
        //     "CHATS_UPSERT",
        //     "CHATS_UPDATE",
        //     "CHATS_DELETE",
        //     "GROUPS_UPSERT",
        //     "GROUP_UPDATE",
        //     "GROUP_PARTICIPANTS_UPDATE",
        //     "CONNECTION_UPDATE",
        //     "LABELS_EDIT",
        //     "LABELS_ASSOCIATION",
        //     "CALL",
        //     "TYPEBOT_START",
        //     "TYPEBOT_CHANGE_STATUS"
        // ],
        // // sqs (Optional)
        // "sqsEnabled": true,
        // "sqsEvents": [
        //     "APPLICATION_STARTUP",
        //     "QRCODE_UPDATED",
        //     "MESSAGES_SET",
        //     "MESSAGES_UPSERT",
        //     "MESSAGES_UPDATE",
        //     "MESSAGES_DELETE",
        //     "SEND_MESSAGE",
        //     "CONTACTS_SET",
        //     "CONTACTS_UPSERT",
        //     "CONTACTS_UPDATE",
        //     "PRESENCE_UPDATE",
        //     "CHATS_SET",
        //     "CHATS_UPSERT",
        //     "CHATS_UPDATE",
        //     "CHATS_DELETE",
        //     "GROUPS_UPSERT",
        //     "GROUP_UPDATE",
        //     "GROUP_PARTICIPANTS_UPDATE",
        //     "CONNECTION_UPDATE",
        //     "LABELS_EDIT",
        //     "LABELS_ASSOCIATION",
        //     "CALL",
        //     "TYPEBOT_START",
        //     "TYPEBOT_CHANGE_STATUS"
        // ],
        // // chatwoot (Optional)
        // "chatwootAccountId": "1",
        // "chatwootToken": "TOKEN",
        // "chatwootUrl": "https://chatoot.com",
        // "chatwootSignMsg": true,
        // "chatwootReopenConversation": true,
        // "chatwootConversationPending": false,
        // "chatwootImportContacts": true,
        // "chatwootNameInbox": "evolution",
        // "chatwootMergeBrazilContacts": true,
        // "chatwootImportMessages": true,
        // "chatwootDaysLimitImportMessages": 3,
        // "chatwootOrganization": "Evolution Bot",
        // "chatwootLogo": "https://evolution-api.com/files/evolution-api-favicon.png",
    });

const getFetchInstances = (instance) =>
    get(url.GET_FETCH_INSTANCES, {params: {instanceName: instance}});

const getConnectInstace = (name) => get(url.GET_CONNECT_INSTANCE + `/${name}`);

const putRestartInstance = (name) =>
    put(url.PUT_RESTART_INSTANCE + `/${name}`, {});

const getConnectionStateInstance = (name) =>
    get(url.GET_CONNECTION_STATE_INSTANCE + `/${name}`);
const deleteLogoutInstance = (name) =>
    del(url.DELETE_LOGOUT_INSTANCE + `/${name}`);
const deleteInstance = (name) => del(url.DELETE_INSTANCES + `/${name}`);

export {
    postCreateInstance,
    getFetchInstances,
    getConnectInstace,
    putRestartInstance,
    getConnectionStateInstance,
    deleteLogoutInstance,
    deleteInstance,
};
