import React from "react";
import ReactApexChart from "react-apexcharts";

const QtdLeads = () => {
    const series = [
        {
            name: "Conversões",
            data: [32, 60, 34, 46, 34, 52, 41],
        },
    ];

    const options = {
        dataLabels: {
            enabled: !1,
        },
        stroke: {
            curve: "smooth",
            width: 3,
        },
        series,
        colors: ["#273c92", "#eeb902"],
        xaxis: {
            type: "date",
            categories: [
                "2018-09-19",
                "2018-09-20",
                "2018-09-21",
                "2018-09-22",
                "2018-09-23",
                "2018-09-24",
                "2018-09-25",
            ],
        },
        grid: {
            borderColor: "#f1f1f1",
        },
        tooltip: {
            x: {
                format: "dd/MM/yy",
            },
        },
    };

    return (
        <ReactApexChart
            options={options}
            series={series}
            type="area"
            height="350"
            className="apex-charts"
        />
    );
};

export default QtdLeads;
