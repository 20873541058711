import { Col, FormGroup, Label } from "reactstrap";
import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as MensagensActions from "../../../../../../store/mensagens/actions";
import { connect } from "react-redux";
import { AvField } from "availity-reactstrap-validation";
import { camposMensagens } from "../fields";

const WhatsAppEditorView = (props) => {
  return (
    <FormGroup row>
      <Label for="mensagem" sm={3}>
        {props.t("WA Message")}
      </Label>
      <Col sm={9}>
        <AvField
          id="mensagem-input"
          name="mensagem"
          type="textarea"
          errorMessage="Digite a Mensagem"
          className="form-control border border-secondary"
          value={props.mensagensState.mensagem[camposMensagens.MENSAGEM]}
          disabled
        />
      </Col>
    </FormGroup>
  );
};

WhatsAppEditorView.propTypes = {
  t: PropTypes.any,
  createMensagensSet: PropTypes.func,
};
const mapStatetoProps = (state) => ({ mensagensState: state.mensagens });
const mapDispatchtoProps = (dispatch) =>
  bindActionCreators(MensagensActions, dispatch);

export default connect(
  mapStatetoProps,
  mapDispatchtoProps,
)(withTranslation()(WhatsAppEditorView));
