import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as MensagensActions from "../../../store/mensagens/actions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { BreadcrumbItem, Button, Col, Row } from "reactstrap";
import React from "react";
import { camposMensagens } from "./modal/fields/fields";

const BreadCrumbMensagens = (props) => {
  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box d-flex align-items-start align-items-center justify-content-between">
          <h4 className="page-title mb-0 font-size-18">
            {props.breadcrumbItem}
          </h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                {props.mensagensState.loadingCriarMensagem ? (
                  <Button
                    color="dark"
                    className="btn btn-dark waves-effect waves-light btn-rounded"
                    size="md"
                  >
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                    {props.t("Loading")}
                  </Button>
                ) : (
                  <Button
                    disabled={props.mensagensState.loadingCriarMensagem}
                    color="light"
                    className="btn btn-md btn-light btn-rounded waves-effect waves-light w-lg"
                    size="md"
                    onClick={() => {
                      props.createMensagensSet("", camposMensagens.TITULO);
                      props.createMensagem();
                    }}
                  >
                    {props.t("Create Message")}
                  </Button>
                )}
              </BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};

BreadCrumbMensagens.propTypes = {
  t: PropTypes.any,
  breadcrumbItem: PropTypes.string,
  mensagensState: PropTypes.object,
  createMensagem: PropTypes.func,
  createMensagensSet: PropTypes.func,
};
const mapStatetoProps = (state) => ({ mensagensState: state.mensagens });
const mapDispatchtoProps = (dispatch) =>
  bindActionCreators(MensagensActions, dispatch);

export default connect(
  mapStatetoProps,
  mapDispatchtoProps,
)(withTranslation()(BreadCrumbMensagens));
