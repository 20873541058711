import {Col} from "reactstrap";
import CampanhaSelect from "./fields/CampanhaSelect";
import ConexaoSelect from "./fields/ConexaoSelect";
import AgendarButton from "./fields/AgendarButton";
import PropTypes, {object} from "prop-types";
import {withTranslation} from "react-i18next";
import DataTempoSelect from "./fields/DataTempoSelect";
import React from "react";
import ImagemGrupoField from "./fields/ImagemGrupoField";
import {bindActionCreators} from "redux";
import * as AgendamentosActions from "../../../../store/agendamento/actions";
import {connect} from "react-redux";
import {fieldsAgendamentoClean} from "./validation/fields";
import {TIPO_IMAGEM_GRUPO} from "./validation/tipoOptions";
import * as validation from "./validation/validate";
import {transformImagemGrupo} from "./validation/transform";

const ImagemGrupo = (props) => {
    const handleImagemGrupo = () => {
        const clean = fieldsAgendamentoClean({...props.agendamentosState.agendamento, tipo: TIPO_IMAGEM_GRUPO});
        const valid = validation.validateMensagem(clean);
        if (valid.isValid) {
            props.createAgendamentoErrors({})
            const mensagem = transformImagemGrupo(clean);
            props.createAgendamento(mensagem)
        } else {
            props.createAgendamentoErrors(valid.errors)
        }
    }
    return (
        <Col md={6} className='offset-md-3'>
            <CampanhaSelect/>
            <ConexaoSelect/>
            <ImagemGrupoField/>
            <DataTempoSelect/>
            <AgendarButton handleAgendar={handleImagemGrupo}/>
        </Col>
    )
}

const mapStatetoProps = (state) => ({agendamentosState: state.agendamentos, campanhasState: state.campanhas});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators(AgendamentosActions, dispatch);
ImagemGrupo.propTypes = {
    t: PropTypes.any,
    agendamentosState: object,
    createAgendamento: PropTypes.func,
    createAgendamentoErrors: PropTypes.func
};
export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(ImagemGrupo));
