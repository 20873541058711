import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Button, Spinner, UncontrolledAlert} from "reactstrap";
import React from "react";
import "toastr/build/toastr.min.css";
import * as AgendamentosActions from "../../../../../store/agendamento/actions";

const AgendarButton = (props) => {
    const handleClickAgendar = () => {
        props.handleAgendar()
        setInterval(props.createAgendamentoEnd, 5000)
    }

    return (<>
            <div className='text-center'>
                <Button
                    size='lg'
                    className="btn btn-success waves-effect"
                    onClick={handleClickAgendar}
                >
                    <i className="bx bx-calendar-plus font-size-16 align-middle me-2"></i>
                    Agendar
                </Button>
            </div>
            <br/>
            {props.agendamentosState.submit === true && <UncontrolledAlert
                color="success"
                className="alert-dismissible fade show"
                role="alert"
            >
                <i className="mdi mdi-check-all me-2"></i> Agendado com sucesso!
            </UncontrolledAlert>}
            {props.agendamentosState.submit === false && <UncontrolledAlert
                color="danger"
                className="alert-dismissible fade show"
                role="alert"
            >
                <i className="mdi mdi-block-helper me-2"></i> Falha ao agendar!
            </UncontrolledAlert>}
            {props.agendamentosState.isCreatingAgendamento && (
                <Spinner className="m-auto" color="primary"/>
            )}
        </>
    )
}
const mapStatetoProps = (state) => ({agendamentosState: state.agendamentos});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators(AgendamentosActions, dispatch);

AgendarButton.propTypes = {
    t: PropTypes.any,
    handleAgendar: PropTypes.func
};
export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(AgendarButton));
