import { withTranslation } from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import * as ConexoesActions from "../../../store/conexoes/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getPhone } from "../../../util/phone";

const DesconectarAlert = (props) => {
  const [successAlert, setSuccessAlert] = useState(false);

  useEffect(() => {
    setSuccessAlert(props.conexoes.isOpenAlertLogoutSuccess);
  }, [props.conexoes.isOpenAlertLogoutSuccess]);

  return (
    <div>
      {props.conexoes.conexaoLogout && props.conexoes.isOpenAlertLogout ? (
        <SweetAlert
          title={props.t("Are you sure?")}
          warning
          showCancel
          confirmButtonText={props.t("Yes, logout!")}
          cancelBtnText={props.t("Cancel")}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() =>
            props.logoutConexaoFetch(props.conexoes.conexaoLogout.name)
          }
          onCancel={() => props.logoutConexaoExit()}
        >
          {props.t("Disconnect")}{" "}
          {props.conexoes.conexaoLogout
            ? getPhone(props.conexoes.conexaoLogout.ownerJid)
            : ""}
        </SweetAlert>
      ) : null}
      {successAlert ? (
        <SweetAlert
          success
          title={props.t("Disconnected")}
          onConfirm={() => {
            setSuccessAlert(false);
          }}
        ></SweetAlert>
      ) : null}
    </div>
  );
};

DesconectarAlert.propTypes = {
  t: PropTypes.any.isRequired,
  isOpenAlertLogout: PropTypes.bool,
  isOpenAlertLogoutSuccess: PropTypes.bool,
  logoutConexaoExit: PropTypes.func,
  logoutConexaoFetch: PropTypes.func,
  conexoes: PropTypes.object,
};
const mapStatetoProps = (state) => ({ conexoes: state.conexoes });
const mapDispatchtoProps = (dispatch) =>
  bindActionCreators(ConexoesActions, dispatch);

export default connect(
  mapStatetoProps,
  mapDispatchtoProps,
)(withTranslation()(DesconectarAlert));
