import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardSubtitle,
    CardText,
    CardTitle,
    Col,
    Spinner,
} from "reactstrap";
import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as ConexoesActions from "../../../store/conexoes/actions";
import {connect} from "react-redux";
import {getPlano} from "../../../db/firestore/conexoes";
import SweetAlert from "react-bootstrap-sweetalert";
import logolight from "../../../assets/images/logo-md-dark.png";

const CardAddConexao = (props) => {
    const [plano, setPlano] = useState(null);
    const [loading, setLoading] = useState(false);
    const [alertUpgrade, setAlertUpgrade] = useState(false);
    const handleCreateInstance = () => {
        if (plano <= props.conexoes.conexoes.length) {
            setAlertUpgrade(true);
        } else {
            setLoading(true);
            props.createConexao();
        }
    };

    useEffect(() => {
        // declare the async data fetching function
        const fetchData = async () => {
            // get the data from the api
            const data = await getPlano();
            // convert the data to json

            // set state with the result
            setPlano(data);
        };

        // call the function
        fetchData()
            // make sure to catch any error
            .catch((error) => {
                setPlano(null);
                console.error(error);
            });
    }, []);

    return (
        <Col lg={3}>
            <Card
                className="text-center"
                onClick={handleCreateInstance}
                style={{cursor: "pointer"}}
            >
                <CardHeader>
                    <CardTitle className="h4 mt-0">{props.t("Add")}</CardTitle>
                    <CardSubtitle className="h6 font-14 text-muted">
                        {props.t("Connection")}
                    </CardSubtitle>
                </CardHeader>
                <CardBody>
                    <CardText style={{visibility: "hidden"}}>
                        <small>Nome Perfil: {props.t("Undefined")}</small>
                        <br/>
                        <small>Status: </small>
                        <br/>
                    </CardText>
                    <CardText>
                        <i
                            className="bx bx-plus-medical font-size-24 align-middle"
                            style={{visibility: "visible"}}
                        ></i>
                    </CardText>
                    <CardText style={{visibility: "hidden"}}>
                        <small>Número: {props.t("Undefined")}</small>
                        <br/>
                        <small>Quantidade de Grupos: 4</small>
                    </CardText>
                </CardBody>
                <CardFooter>
                    <Button
                        className="btn btn-success waves-effect waves-light"
                        style={{visibility: "hidden"}}
                    >
                        <i className="mdi mdi-lan-connect font-size-16 align-middle me-2"></i>{" "}
                        {props.t("Connect")}
                    </Button>
                </CardFooter>
            </Card>
            {alertUpgrade ? (
                <SweetAlert
                    title={props.t("Plan Exceeded")}
                    custom
                    customIcon={logolight}
                    timeout={5000}
                    confirmBtnText={props.t("Plan Exceeded Button")}
                    onConfirm={() => {
                        setAlertUpgrade(false);
                    }}
                >
                    {props.t("Plan Exceeded Message")}
                </SweetAlert>
            ) : null}
            {loading ? (
                <SweetAlert
                    title={props.t("Loading")}
                    custom
                    customIcon={logolight}
                    onConfirm={() => {
                    }}
                >
                    <Spinner className="m-1" color="primary"/>
                </SweetAlert>
            ) : null}
        </Col>
    );
};

CardAddConexao.propTypes = {
    t: PropTypes.any,
    createConexao: PropTypes.func,
    conexoes: PropTypes.object,
};
const mapStatetoProps = (state) => ({conexoes: state.conexoes});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators(ConexoesActions, dispatch);

export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(CardAddConexao));
