import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Col, FormGroup, Input, Label} from "reactstrap";
import React from "react";
import {camposAgendamentos} from "../validation/fields";
import * as AgendamentoActions from "../../../../../store/agendamento/actions";


const NomeGrupoInput = (props) => {
    const handleChangeNome = (event) => {
        props.editingAgendamentos(event.target.value === '' ? null : event.target.value, camposAgendamentos.NOME_GRUPO)
    }
    return (
        <FormGroup row>
            <Label for="mensagem-select" sm={3}>
                {props.t("New Group Name")}
            </Label>
            <Col sm={9}>
                <Input type='text' name='mensagem-select' id='mensagem-select' placeholder='Digite o novo nome'
                       onChange={handleChangeNome}/>
            </Col>
        </FormGroup>
    );
};

const mapStatetoProps = (state) => ({agendamentosState: state.agendamentos});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators({...AgendamentoActions}, dispatch);

NomeGrupoInput.propTypes = {
    t: PropTypes.any,
    agendamentosState: PropTypes.object,
    editingAgendamentos: PropTypes.func,
};
export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(NomeGrupoInput));
