import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Col, Modal, ModalBody, ModalHeader, Row, UncontrolledAlert,} from "reactstrap";
import * as MensagensActions from "../../../../store/mensagens/actions";
import React, {useState} from "react";
import ConexaoSelect from "./fields/send/ConexaoSelect";

const EnviarMensagensModal = (props) => {
    const [error, setError] = useState(false);
    return (
        <Row>
            <Col sm={6} md={4} xl={3}>
                <Modal
                    isOpen={props.mensagensState.isOpenModalEnviarMensagem}
                    centered
                    scrollable
                    toggle={props.enviarMensagensExit}
                >
                    <ModalHeader>
                        <h5 className="modal-title mt-0">{props.t("Send Message")}</h5>
                        <button
                            type="button"
                            onClick={props.enviarMensagensExit}
                            className="btn-close-modal"
                            data-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </ModalHeader>
                    <ModalBody>
                        {error && (
                            <UncontrolledAlert
                                color="danger"
                                className="alert-dismissible fade show"
                                role="alert"
                                fade
                                toggle={() => setError(!error)}
                            >
                                Por favor preencha os campos corretamente para criar a mensagem
                            </UncontrolledAlert>
                        )}
                        <ConexaoSelect/>
                    </ModalBody>
                </Modal>
            </Col>
        </Row>
    );
};
EnviarMensagensModal.propTypes = {
    t: PropTypes.any,
    mensagensState: PropTypes.object,
    enviarMensagensExit: PropTypes.func,
};
const mapStatetoProps = (state) => ({
    mensagensState: state.mensagens,
    conexoesState: state.conexoes,
});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators(MensagensActions, dispatch);

export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(EnviarMensagensModal));
