export const getBase64 = (file) => {
    return new Promise((resolve) => {
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);

        // on reader load somthing...
        reader.onload = () => {
            // Make a fileInfo Object
            baseURL = reader.result;
            resolve(baseURL);
        };
    });
};

// you should provide one of maxSizeMB, maxWidthOrHeight in the options
export const imageCompressionOptions = {
    maxSizeMB: 5,            // (default: Number.POSITIVE_INFINITY)
    // but, automatically reduce the size to smaller than the maximum Canvas size supported by each browser.
    // Please check the Caveat part for details.
    useWebWorker: true,        // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
    // following options are for advanced users
    // maxIteration: number,         // optional, max number of iteration to compress the image (default: 10)
    // exifOrientation: number,      // optional, see https://stackoverflow.com/a/32490603/10395024
    fileType: 'image/jpeg',             // optional, fileType override e.g., 'image/jpeg', 'image/png' (default: file.type)
    initialQuality: 0.5,       // optional, initial quality value between 0 and 1 (default: 1)
    // alwaysKeepResolution: boolean // optional, only reduce quality, always keep width and height (default: false)
}

// Função para converter Base64 para Blob
function base64ToUrl(base64) {
    const [mimeType, base64String] = base64.split(',');

    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const mime = mimeType.match(/data:([^;]+);base64/)[1];
    const byteArray = new Uint8Array(byteNumbers);
    let blob = new Blob([byteArray], {type: mime});
    return URL.createObjectURL(blob);
}
