import { Button, Col, FormGroup, Label } from "reactstrap";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as MensagensActions from "../../../../../../store/mensagens/actions";
import { connect } from "react-redux";
import { AvField } from "availity-reactstrap-validation";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { camposMensagens } from "../fields";

const WhatsAppEditor = (props) => {
  const [message, setMessage] = useState(
    props.mensagensState.mensagem[camposMensagens.MENSAGEM],
  );

  const [showPicker, setShowPicker] = useState(false);

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const handleEmojiClick = (emojiObject) => {
    setMessage((prevInput) => prevInput + emojiObject.native);
    setShowPicker(false);
  };

  useEffect(() => {
    props.createMensagensSet(message, camposMensagens.MENSAGEM);
  }, [message]);

  return (
    <>
      <FormGroup row>
        <Label for="mensagem" sm={3}>
          {props.t("WA Message")}
        </Label>
        <Col sm={9}>
          <AvField
            id="mensagem-input"
            name="mensagem"
            type="textarea"
            errorMessage="Digite a Mensagem"
            className="form-control border border-secondary"
            validate={{ required: { value: true } }}
            onChange={handleChange}
            value={message}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Emoji</Label>
        <Col sm={9}>
          <Button
            type="button"
            className="btn btn-primary waves-effect waves-light"
            onClick={() => setShowPicker((val) => !val)}
          >
            <i className="bx bx-smile font-size-16 align-middle me-2"></i>{" "}
            Escolher Emoji
          </Button>
          {showPicker && (
            <Picker
              data={data}
              onEmojiSelect={handleEmojiClick}
              previewPosition="none"
              searchPosition="none"
              locale="pt"
              dynamicWidth={true}
            />
          )}
        </Col>
      </FormGroup>
    </>
  );
};

WhatsAppEditor.propTypes = {
  t: PropTypes.any,
  createMensagensSet: PropTypes.func,
};
const mapStatetoProps = (state) => ({ mensagensState: state.mensagens });
const mapDispatchtoProps = (dispatch) =>
  bindActionCreators(MensagensActions, dispatch);

export default connect(
  mapStatetoProps,
  mapDispatchtoProps,
)(withTranslation()(WhatsAppEditor));
