import {
    Button,
    Card,
    CardBody,
    CardImg,
    CardSubtitle,
    CardTitle,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    NavItem,
    NavLink,
    Row,
    Spinner,
    TabContent,
    TabPane,
} from "reactstrap";
import {bindActionCreators} from "redux";
import * as ConexoesActions from "../../../../../../../store/conexoes/actions";
import * as CampanhasActions from "../../../../../../../store/campanhas/actions";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import classnames from "classnames";
import {getPhone} from "../../../../../../../util/phone";
import * as GroupsService from "../../../../../../../api/evolution/groups";
import GruposTable from "../table/GruposTable";

const ImportarGruposModal = (props) => {
    const handleClose = () => props.importarGruposExit();
    const [activeTab, setActiveTab] = useState(1);
    const [gruposEscolhidos, setGruposEscolhidos] = useState({});
    const [loadingGrupos, setLoadingGrupos] = useState(true);
    const [selectedRows, setSelectedRows] = useState(props.campanhasState?.campanha?.grupos?.map((grupo) => grupo.id) || []);
    const [conexaoSelecionada, setConexaoSelecionada] = useState(null);
    const handleEscolheConexao = (conexao) => {
        setActiveTab(2);
        setLoadingGrupos(true);
        fetchAllGroups(conexao);
        setConexaoSelecionada(conexao);
    };

    function renderConexao(conexao) {
        return (
            <Card
                key={conexao.toString()}
                className="rounded-pill"
                tag={Button}
                onClick={() => handleEscolheConexao(conexao)}
            >
                <Row>
                    <Col md={4}>
                        <CardImg
                            className="rounded rounded-circle img-thumbnail campanhas-thumb mx-auto"
                            src={conexao.profilePicUrl}
                            alt="Card image cap"
                        />
                    </Col>
                    <Col md={8}>
                        <CardBody>
                            <CardTitle>{conexao.profileName}</CardTitle>
                            <CardSubtitle style={{color: "#3b5de7"}}>
                                {getPhone(conexao.ownerJid)}
                            </CardSubtitle>
                        </CardBody>
                    </Col>
                </Row>
            </Card>
        );
    }

    function toggleTab(tab) {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 4) {
                setActiveTab(tab);
            }
        }
    }

    const fetchAllGroups = (conexao) => {
        GroupsService.getFetchAllGroupsWithPaticipants(conexao.name)
            .then((groups) => {
                setGruposEscolhidos(
                    groups.filter((g) => g.participants.some((participant) => participant.id === conexao.ownerJid &&
                        (participant.admin === "superadmin" || participant.admin === "admin")))
                );
                setLoadingGrupos(false);
            })
            .catch(() => props.importarGruposExit());
    };

    const handleImport = async () => {
        const map = gruposEscolhidos.filter((grupo) =>
            selectedRows.includes(grupo.id),
        );
        const gruposParaImportar = [];
        for (const mapElement of map) {
            delete mapElement.participants;
            // const newVar = await GroupsService.getGroupInvite(
            //     conexaoSelecionada.name,
            //     mapElement.id,
            // );
            gruposParaImportar.push({
                ...mapElement,
                inviteUrl: '',
            });
        }
        props.importarGruposFetch(
            props.campanhasState.campanha,
            gruposParaImportar,
            conexaoSelecionada.name
        );
    };

    useEffect(() => {
        props.getConexoes();
    }, []);

    return (
        <Modal
            centered
            size="lg"
            isOpen={props.campanhasState.isOpenModalImportarGrupos}
            scrollable
            toggle={handleClose}
        >
            <ModalHeader>
                <h5 className="modal-title mt-0">
                    {props.t("Campaign Import Groups")}
                </h5>
                <button
                    type="button"
                    onClick={handleClose}
                    className="btn-close-modal"
                    data-dismiss="modal"
                    aria-label="Close"
                ></button>
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <div className="alert alert-danger" role="alert">
                            <h4 className="alert-heading">
                                <i className="mdi mdi-alert-outline"></i> Observação!
                            </h4>
                            <p>
                                Para garantir o sucesso das suas campanhas de lançamento via
                                WhatsApp, observe algumas orientações importantes:
                            </p>
                            <p>
                                Ao criar os links de divulgação, recomendamos que utilize o seu
                                número pessoal de celular ou um chip dedicado exclusivamente
                                para esta finalidade. Evite utilizar números que possam ter sido
                                banidos, pois isso pode causar erros de redirecionamento dos
                                links.
                            </p>
                            <p>
                                É essencial que todos os links sejam gerados a partir de um
                                dispositivo confiável e regularmente utilizado por você. Isso
                                minimiza o risco de mudanças nos links e problemas durante o
                                redirecionamento.
                            </p>
                            <hr/>
                            <p>
                                Lembre-se de que a criação dos grupos de divulgação deve ser
                                feita com cuidado, utilizando um número de telefone que não
                                tenha histórico de problemas com redes sociais ou serviços de
                                mensagens.
                            </p>
                            <p className="mb-0">
                                Dessa forma, você assegura que suas campanhas terão o máximo de
                                eficácia e evitará contratempos desnecessários.
                            </p>
                        </div>
                    </Col>
                </Row>
                <div className="form-wizard-wrapper wizard clearfix">
                    <div className="steps clearfix">
                        <ul>
                            <NavItem className={classnames({current: activeTab === 1})}>
                                <NavLink className={classnames({current: activeTab === 1})}>
                                    <span className="number">1.</span> Conexões
                                </NavLink>
                            </NavItem>
                            <NavItem className={classnames({current: activeTab === 2})}>
                                <NavLink className={classnames({active: activeTab === 2})}>
                                    <span className="number">2.</span> Grupos
                                </NavLink>
                            </NavItem>
                        </ul>
                    </div>
                    <div className="content clearfix">
                        <TabContent activeTab={activeTab} className="body">
                            <TabPane tabId={1}>
                                <Container fluid>
                                    <Row>
                                        <h2>Escolha a Conexão</h2>
                                    </Row>
                                    <Row className="row justify-content-center align-items-center">
                                        <Col md={12}>
                                            {props.conexoesState.conexoes.map((conexao) => {
                                                return renderConexao(conexao);
                                            })}
                                        </Col>
                                    </Row>
                                </Container>
                            </TabPane>
                            <TabPane tabId={2}>
                                <Container>
                                    <Row>
                                        {loadingGrupos && (
                                            <Spinner
                                                type="grow"
                                                className="m-auto text-center"
                                                color="primary"
                                            />
                                        )}
                                        {!loadingGrupos && (
                                            <GruposTable
                                                data={gruposEscolhidos}
                                                handleSelectRows={setSelectedRows}
                                                selectedRows={selectedRows}
                                            />
                                        )}
                                    </Row>
                                </Container>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                {activeTab === 2 && (
                    <Button
                        onClick={() => {
                            toggleTab(activeTab - 1);
                            setGruposEscolhidos([]);
                            setSelectedRows([]);
                            setConexaoSelecionada(null);
                        }}
                        className="btn btn-primary waves-effect"
                    >
                        {props.t("Previous")}
                    </Button>
                )}
                {activeTab === 2 && (
                    <Button
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                        disabled={selectedRows.length === 0}
                        onClick={handleImport}
                    >
                        {props.t("Import")}
                    </Button>
                )}
            </ModalFooter>
        </Modal>
    );
};

const mapStatetoProps = (state) => ({
    campanhasState: state.campanhas,
    conexoesState: state.conexoes,
});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators({...CampanhasActions, ...ConexoesActions}, dispatch);

ImportarGruposModal.propTypes = {
    t: PropTypes.any,
    campanhasState: PropTypes.object,
    conexoesState: PropTypes.object,
    importarGruposExit: PropTypes.func,
    importarGruposFetch: PropTypes.func,
    getConexoes: PropTypes.func,
};
export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(ImportarGruposModal));
