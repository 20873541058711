import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Col, FormGroup, Input, Label} from "reactstrap";
import React, {useEffect, useState} from "react";

import * as ConexoesActions from "../../../../../store/conexoes/actions";
import * as AgendamentoAction from "../../../../../store/agendamento/actions";
import {getPhone} from "../../../../../util/phone";
import {camposAgendamentos} from "../validation/fields";

function convertData(conexoes) {
    if (conexoes.length > 0) {
        return conexoes.map((conexao) => {
            return (
                <option
                    key={conexao.name}
                    value={conexao.name}
                >{`${conexao.name} (${getPhone(conexao.ownerJid)})`}</option>
            );
        });
    }
    return []
}

const ConexaoSelect = (props) => {
    const [data, setData] = useState([]);
    const handleChangeConexao = (event) => {
        props.editingAgendamentos(
            event.target.value === "0" ? null : event.target.value,
            camposAgendamentos.CONEXAO,
        );
    };

    useEffect(() => {
        props.getConexoes();
    }, []);

    useEffect(() => {
        setData(convertData(props.conexoesState.conexoes));
    }, [props.conexoesState.conexoes]);

    if (data.length === 0) {
        return null;
    }

    return (
        <FormGroup row>
            <Label for="conexao-select" sm={3}>
                {props.t("WA Connections")}
            </Label>
            <Col sm={9}>
                <Input
                    type="select"
                    name="conexao-select"
                    id="conexao-select"
                    onChange={handleChangeConexao}
                    value={props.agendamentosState?.agendamento?.conexao || 0}
                    invalid={props.agendamentosState?.errors?.conexao}
                    valid={props.agendamentosState?.agendamento?.conexao}
                >
                    <option value={0}>Selecione a conexão</option>
                    {data}
                </Input>
            </Col>
        </FormGroup>
    );
};

const mapStatetoProps = (state) => ({
    conexoesState: state.conexoes,
    agendamentosState: state.agendamentos,
});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators({...ConexoesActions, ...AgendamentoAction}, dispatch);

ConexaoSelect.propTypes = {
    t: PropTypes.any,
    conexoesState: PropTypes.object,
    agendamentosState: PropTypes.object,
    editingAgendamentos: PropTypes.func,
    getConexoes: PropTypes.func,
};
export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(ConexaoSelect));
