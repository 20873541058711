import React, {useEffect} from "react";
import {Col, Container, Row} from "reactstrap";

//Import Countdown
//Import Images
import logo from "../../../assets/images/logo-dark.png";
import commingsoon from "../../../assets/images/brands/whatsappcloud.png";

const GruposIndisponiveis = () => {
    useEffect(() => {
        document.body.className = "authentication-bg";
        // remove classname when component will unmount
        return function cleanup() {
            document.body.className = "";
        };
    });

    return (
        <React.Fragment>
            <div className="my-5 pt-sm-5">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center">
                                <a href="/" className="d-block auth-logo">
                                    <img src={logo} alt="" height="24"/>
                                </a>

                                <Row className="justify-content-center mt-5">
                                    <Col sm={4}>
                                        <div className="maintenance-img">
                                            <img
                                                src={commingsoon}
                                                alt=""
                                                className="img-fluid mx-auto d-block"
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                <h3 className="mt-5" style={{color: "#1d5deb"}}>
                                    Grupos Indisponíveis!!
                                </h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default GruposIndisponiveis;
