import {del, get, post, put} from "./requests";

const url = {
    AGENDAMENTO: '/zapease/agendamento'
}

const createAgendamento = async (body) => {
    return await post(`${url.AGENDAMENTO}`, body);
}
const getAgendamentos = async (idUsuario) => {
    return await get(`${url.AGENDAMENTO}?idUsuario=${idUsuario}`)
}
const deleteAgendamento = (idAgendamento) => {
    return del(`${url.AGENDAMENTO}`, {params: {id: idAgendamento}})
}
const editAgendamento = (body) => {
    return put(`${url.AGENDAMENTO}`, body)
}


export {
    createAgendamento,
    getAgendamentos,
    deleteAgendamento,
    editAgendamento
}
