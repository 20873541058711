import {getFirebaseBackend} from "../helpers/firebase_helper";

export function getFileExtension(fileName) {
    const parts = fileName.split('.');
    return parts.length > 1 ? parts.pop() : ''; // Pega a última parte após o "."
}

export async function uploadFile(name, file) {
    let reference = await getFirebaseBackend().getFirebaseStorage().child(name);
    await reference.put(file);
    return await reference.getDownloadURL()
}

