import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {BreadcrumbItem, Button, Col, Row} from "reactstrap";
import React from "react";
import * as CampanhasActions from "../../../../../../../store/campanhas/actions";

const BreadCrumbCampanhas = (props) => {
    return (
        <Row>
            <Col className="col-12">
                <div className="page-title-box d-flex align-items-start align-items-center justify-content-between">
                    <h4 className="page-title mb-0 font-size-18">
                        {props.breadcrumbItem}
                    </h4>
                    <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                            <BreadcrumbItem>
                                <Button
                                    disabled={props.campanhasState.loagingCriarCampanha}
                                    color="light"
                                    className="btn btn-md btn-light btn-rounded waves-effect waves-light w-lg fw-semibold"
                                    size="md"
                                    onClick={() =>
                                        props.editarCampanha(props.campanhasState.campanha)
                                    }
                                >
                                    {props.t("Edit Campaign")}
                                </Button>
                            </BreadcrumbItem>
                            {/*TODO implementar Lógica*/}
                            {/*<BreadcrumbItem>*/}
                            {/*    <Button*/}
                            {/*        // disabled={props.campanhasState.loagingCriarCampanha}*/}
                            {/*        disabled={true}*/}
                            {/*        color="light"*/}
                            {/*        className="btn btn-md btn-light btn-rounded waves-effect waves-light w-lg fw-semibold"*/}
                            {/*        size="md"*/}
                            {/*        onClick={props.createCampanha}*/}
                            {/*    >*/}
                            {/*        {props.t("Campaign Create Groups")}*/}
                            {/*    </Button>*/}
                            {/*</BreadcrumbItem>*/}
                            <BreadcrumbItem>
                                <Button
                                    color="light"
                                    className="btn btn-md btn-light btn-rounded waves-effect waves-light w-lg fw-semibold"
                                    size="md"
                                    onClick={props.importarGrupos}
                                >
                                    {props.t("Campaign Import Groups")}
                                </Button>
                            </BreadcrumbItem>
                        </ol>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

const mapStatetoProps = (state) => ({campanhasState: state.campanhas});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators(CampanhasActions, dispatch);

BreadCrumbCampanhas.propTypes = {
    t: PropTypes.any,
    campanhasState: PropTypes.object,
    editarCampanha: PropTypes.func.isRequired,
    importarGrupos: PropTypes.func.isRequired,
    breadcrumbItem: PropTypes.string,
};
export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(BreadCrumbCampanhas));
