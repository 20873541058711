import React from "react"
import {connect} from "react-redux"
import {Link} from "react-router-dom"

//i18n
import {withTranslation} from "react-i18next"
import withRouter from "../Common/withRouter"
import SidebarContent from "./SidebarContent";

const Sidebar = () => {
    const storage = localStorage.getItem("authUser")
    const storageObj = JSON.parse(storage);
    return (
        <div className="vertical-menu">
            <div className="h-100">
                <div className="user-wid text-center py-4">
                    <div className="mt-3">
                        <Link to="#" className="text-body fw-medium font-size-16">Patrick Becker</Link>
                        <p className="text-muted mt-1 mb-0 font-size-13">{storageObj.email}</p>
                    </div>
                </div>
                <div data-simplebar="" className="h-100">
                    <SidebarContent/>
                </div>
            </div>
        </div>
    )
}

Sidebar.propTypes = {}

const mapStatetoProps = state => {
    return {
        layout: state.Layout,
    }
}
export default connect(
    mapStatetoProps,
    {}
)(withRouter(withTranslation()(Sidebar)))