function validate(fields) {
    const camposNecessarios = ["tipo", "titulo"];
    const campos = Object.keys(fields);
    const valores = Object.values(fields);
    return (
        camposNecessarios.every((v) => campos.includes(v)) &&
        valores.some((v) => v !== "" && v !== null && v !== undefined)
    );
}

export {validate};
