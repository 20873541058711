import {Col, Row,} from "reactstrap";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import React from "react";

const CardHeader = (props) => {

    return (
        <Col xl={12} className='mb-2'>
            <Row>
                <Col md={2}>
                    <h4 style={{textAlign: 'center'}}>Título</h4>
                </Col>
                <Col md={2} className="d-flex align-items-center justify-content-center">
                    <h4>Tipo</h4>
                </Col>
                <Col md={2} className="d-flex align-items-center justify-content-center">
                    <h4>Criado Em:</h4>
                </Col>
                <Col md={2} className="d-flex align-items-center justify-content-center">
                    <h4>Agendamento Para:</h4>
                </Col>
                <Col md={2} className="d-flex align-items-center justify-content-center">
                    <h4>Status</h4>
                </Col>
                <Col
                    md={2}
                    className="d-flex align-items-center justify-content-center flex-column"
                >
                </Col>
            </Row>
        </Col>
    );
};


CardHeader.propTypes = {
    t: PropTypes.any,
};
export default (withTranslation()(CardHeader));
