// Add the Firebase products that you want to use
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import {getFirebaseBackend} from "../../helpers/firebase_helper";
import {userHash} from "../../util/hash";


const getLinkData = async (id) => {
    return (await getFirebaseBackend().getLinksCollection().doc(id).get()).data()
};


const updateLink = (link, data) => {
    getFirebaseBackend().getLinksCollection().doc(link).set(data)
};

const getUserLink = () => {
    return userHash(getFirebaseBackend().getAuthenticatedUser().uid)
};

const setRedirectLink = (uid, redirectId, link) => {
    return getFirebaseBackend().getLinksCollection().doc(userHash(uid).toString()).set({[redirectId]: {url: link}}, {merge: true})
};

export {
    getLinkData,
    updateLink,
    getUserLink,
    setRedirectLink
}
