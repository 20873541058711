import {post} from "./requests";

const url = {
    REDIRECTION: '/redirection'
}

const postRedirection = (body) => {
    post(`${url.REDIRECTION}`, body).then(r => console.log(r));
}


export {
    postRedirection
}
