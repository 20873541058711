import PropTypes, {func} from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Col, FormGroup, Input, Label} from "reactstrap";
import React, {useEffect, useState} from "react";

import * as CampanhasActions from "../../../../../store/campanhas/actions";
import * as AgendamentoActions from "../../../../../store/agendamento/actions";
import {camposAgendamentos} from "../validation/fields";

function convertData(campanhas) {
    return campanhas.map((campanha) => {
        return <option key={campanha.id} value={campanha.id}>{campanha.nome}</option>;
    });
}

const CampanhaSelect = (props) => {
    const [data, setData] = useState([]);
    const handleChangeCampanha = (event) => {
        if (event.target.value === '0') {
            props.editingAgendamentos(null, camposAgendamentos.ID_CAMPANHA)
            props.editingAgendamentos(null, camposAgendamentos.GRUPOS)
        } else {
            props.editingAgendamentos(event.target.value, camposAgendamentos.ID_CAMPANHA)
            let campanha = props.campanhasState.campanhas.find(campanha => campanha.id === event.target.value);
            props.editingAgendamentos(campanha.grupos.map(grupo => grupo.id), camposAgendamentos.GRUPOS)
        }
    }

    useEffect(() => {
        props.getCampanhas()
    }, []);


    useEffect(() => {
        setData(convertData(props.campanhasState.campanhas));
    }, [props.campanhasState.campanhas]);

    if (data.length === 0) {
        return null;
    }

    return (
        <FormGroup row>
            <Label for="campanha-select" sm={3}>
                {props.t("Campaign")}
            </Label>
            <Col sm={9}>
                <Input type='select' name='campanha-select' id='campanha-select'
                       value={props.agendamentosState?.agendamento?.idCampanha || 0}
                       onChange={handleChangeCampanha}
                       invalid={props.agendamentosState?.errors?.idCampanha === true}
                       valid={props.agendamentosState?.agendamento?.idCampanha}
                >
                    <option value={0}>Selecione a campanha</option>
                    {data.map((option) => option)}
                </Input>
            </Col>
        </FormGroup>
    );
};

const mapStatetoProps = (state) => ({campanhasState: state.campanhas, agendamentosState: state.agendamentos});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators({...CampanhasActions, ...AgendamentoActions}, dispatch);

CampanhaSelect.propTypes = {
    t: PropTypes.any,
    campanhasState: PropTypes.object,
    agendamentosState: PropTypes.object,
    editingAgendamentos: func
};
export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(CampanhaSelect));
