import React from "react";
import {Navigate} from "react-router-dom";


//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";


// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

import Dispositivos from "../pages/ZapEase/Dispositivos/Dispositivos";
import Mensagens from "../pages/ZapEase/Mensagens/Mensagens";
import Home from "../pages/ZapEase/Home";
import Agendamentos from "../pages/ZapEase/Agendamentos/Agendamentos";
import Campanhas from "../pages/ZapEase/Campanhas/Campanhas";
import {zapeaseRoutes} from "./zapease";
import Detalhes from "../pages/ZapEase/Campanhas/Grupos/Detalhes/Detalhes";
import Analytics from "../pages/ZapEase/Campanhas/Analytics/Analytics";
import VisualizacaoAgendamentos from "../pages/ZapEase/Agendamentos/VisualizacaoAgendamentos";

const userRoutes = [

    // //profile
    {path: "/profile", component: <UserProfile/>},

    {path: zapeaseRoutes.devices, component: <Dispositivos/>},
    {path: zapeaseRoutes.messages, component: <Mensagens/>},
    {path: zapeaseRoutes.schedules, component: <VisualizacaoAgendamentos/>},
    {path: zapeaseRoutes.scheduling, component: <Agendamentos/>},
    {path: zapeaseRoutes.campaigns, component: <Campanhas/>},
    {path: zapeaseRoutes.groupsDetail, component: <Detalhes/>},
    {path: zapeaseRoutes.analytics, component: <Analytics/>},
    // this route should be at the end of all other routes
    // { path: "/", exact: true, component: () => <Navigate to="/dashboard" /> },
    {path: "/", exact: true, component: <Home/>},

    {path: "*", component: () => <Navigate to="/"/>},
];

const authRoutes = [
    {path: "/logout", component: <Logout/>},
    {path: "/login", component: <Login/>},
    {path: "/forgot-password", component: <ForgetPwd/>},
    {path: "/register", component: <Register/>},

    {path: "/pages-maintenance", component: <PagesMaintenance/>},
    {path: "/pages-comingsoon", component: <PagesComingsoon/>},
    {path: "/pages-404", component: <Pages404/>},
    {path: "/pages-500", component: <Pages500/>},

    // Authentication Inner
    {path: "/pages-login", component: <Login1/>},
    {path: "/pages-register", component: <Register1/>},
    {path: "/page-recoverpw", component: <Recoverpw/>},
    {path: "/auth-lock-screen", component: <LockScreen/>},
    // {path: zapeaseRoutes.redirect, component: <Redirect/>},
];

export {userRoutes, authRoutes};
