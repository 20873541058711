import PropTypes, {object} from "prop-types";
import {withTranslation} from "react-i18next";
import {Col} from "reactstrap";
import CampanhaSelect from "./fields/CampanhaSelect";
import ConexaoSelect from "./fields/ConexaoSelect";
import MensagemSelect from "./fields/MensagemSelect";
import VelocidadeSelect from "./fields/VelocidadeSelect";
import DataTempoSelect from "./fields/DataTempoSelect";
import AgendarButton from "./fields/AgendarButton";
import {bindActionCreators} from "redux";
import * as AgendamentosActions from "../../../../store/agendamento/actions";
import {connect} from "react-redux";
import {fieldsAgendamentoClean} from "./validation/fields";
import * as  validation from './validation/validate'
import {TIPO_MENSAGEM} from "./validation/tipoOptions";
import {transformMensagem} from "./validation/transform";

const Mensagem = (props) => {
    const handleAgendarMensagem = () => {
        const clean = fieldsAgendamentoClean({...props.agendamentosState.agendamento, tipo: TIPO_MENSAGEM});
        const valid = validation.validateMensagem(clean);
        if (valid.isValid) {
            props.createAgendamentoErrors({})
            const mensagem = transformMensagem(clean);
            props.createAgendamento(mensagem)
        } else {
            props.createAgendamentoErrors(valid.errors)
        }
    }

    return (
        <Col md={6} className='offset-md-3'>
            <CampanhaSelect/>
            <ConexaoSelect/>
            <MensagemSelect/>
            <VelocidadeSelect/>
            <DataTempoSelect/>
            <AgendarButton handleAgendar={handleAgendarMensagem}/>
        </Col>
    )
}

const mapStatetoProps = (state) => ({agendamentosState: state.agendamentos, campanhasState: state.campanhas});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators(AgendamentosActions, dispatch);

Mensagem.propTypes = {
    t: PropTypes.any,
    agendamentosState: object,
    createAgendamento: PropTypes.func,
    createAgendamentoErrors: PropTypes.func
};
export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(Mensagem));
