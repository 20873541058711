import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {Button, Col, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import {camposAgendamentos} from "../validation/fields";
import {bindActionCreators} from "redux";
import * as AgendamentoActions from "../../../../../store/agendamento/actions";
import {connect} from "react-redux";

const DescricaoGrupoTextArea = (props) => {
    const [message, setMessage] = useState("");

    const [showPicker, setShowPicker] = useState(false);

    const handleChange = (event) => {
        setMessage(event.target.value);
    };

    const handleEmojiClick = (emojiObject) => {
        setMessage((prevInput) => prevInput + emojiObject.native);
        setShowPicker(false);
    };

    useEffect(() => {
        props.editingAgendamentos(message, camposAgendamentos.DESCRICAO_GRUPO)
    }, [message]);


    return (
        <>
            <FormGroup row>
                <Label for="mensagem-boas-vindas" sm={3}>
                    {props.t("WA Message")}
                </Label>
                <Col sm={9}>
                    <Input
                        id="mensagem-boas-vindas"
                        name="mensagem"
                        type="textarea"
                        errorMessage="Digite a Mensagem"
                        className="form-control border border-secondary"
                        onChange={handleChange}
                        value={message}
                        invalid={props.agendamentosState?.errors?.descricaoGrupo}
                        valid={message !== ''}
                    />
                    <FormFeedback>
                        Por Favor adicione uma mensagem válida
                    </FormFeedback>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={3}>Emoji</Label>
                <Col sm={9}>
                    <Button
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={() => setShowPicker((val) => !val)}
                    >
                        <i className="bx bx-smile font-size-16 align-middle me-2"></i>{" "}
                        Escolher Emoji
                    </Button>
                    {showPicker && (
                        <Picker
                            data={data}
                            onEmojiSelect={handleEmojiClick}
                            previewPosition="none"
                            searchPosition="none"
                            locale="pt"
                            dynamicWidth={true}
                        />
                    )}
                </Col>
            </FormGroup>
        </>
    );
}

const mapStatetoProps = (state) => ({agendamentosState: state.agendamentos});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators({...AgendamentoActions}, dispatch);

DescricaoGrupoTextArea.propTypes = {
    t: PropTypes.any,
    agendamentosState: PropTypes.object,
    editingAgendamentos: PropTypes.func,
};
export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(DescricaoGrupoTextArea));
