import PropTypes from "prop-types"
import React, {useEffect} from "react"
import {Collapse} from "reactstrap"
import {Link} from "react-router-dom"

//i18n
import {withTranslation} from "react-i18next"

import {connect} from "react-redux"
import withRouter from "../Common/withRouter"
import {zapeaseRoutes} from "../../routes/zapease";

const Navbar = props => {

    useEffect(() => {
        let matchingMenuItem = null
        let ul = document.getElementById("navigation")
        let items = ul.getElementsByTagName("a")
        let itemsArray = [...items]; // converts NodeList to Array
        removeActivation(itemsArray);
        for (const element of items) {
            if (props.router.location.pathname === element.pathname) {
                matchingMenuItem = element
                break
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem)
        }
    })

    function activateParentDropdown(item) {
        item.classList.add("active")
        const parent = item.parentElement
        if (parent) {
            parent.classList.add("active") // li
            const parent2 = parent.parentElement
            parent2.classList.add("active") // li
            const parent3 = parent2.parentElement
            if (parent3) {
                parent3.classList.add("active") // li
                const parent4 = parent3.parentElement
                if (parent4) {
                    parent4.classList.add("active") // li
                    const parent5 = parent4.parentElement
                    if (parent5) {
                        parent5.classList.add("active") // li
                        const parent6 = parent5.parentElement
                        if (parent6) {
                            parent6.classList.add("active") // li
                        }
                    }
                }
            }
        }
        return false
    }

    const removeActivation = (items) => {
        let actiItems = items.filter((x) => x.classList.contains("active"));

        actiItems.forEach((item) => {
            if (item.classList.contains("menu-link")) {
                if (!item.classList.contains("active")) {
                    item.setAttribute("aria-expanded", false);
                }
                if (item.nextElementSibling) {
                    item.nextElementSibling.classList.remove("show");
                }
            }
            if (item.classList.contains("nav-link")) {
                if (item.nextElementSibling) {
                    item.nextElementSibling.classList.remove("show");
                }
                item.setAttribute("aria-expanded", false);
            }
            item.classList.remove("active");
        });
    };

    return (
        <div className="topnav">
            <nav
                className="navbar navbar-light navbar-expand-lg topnav-menu"
                id="navigation"
            >
                <Collapse
                    isOpen={props.leftMenu}
                    className="navbar-collapse"
                    id="topnav-menu-content"
                >
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link to={zapeaseRoutes.devices} className="nav-link">
                                {props.t("Devices")}
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={zapeaseRoutes.campaigns} className="nav-link">
                                {props.t("Campaigns")}
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={zapeaseRoutes.messages} className="nav-link">
                                {props.t("Messages")}
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={zapeaseRoutes.schedules} className="nav-link">
                                {props.t("Schedules")}
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/logout" className="nav-link">
                                <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>{" "}
                                <span>{props.t("Logout")}</span>
                            </Link>
                        </li>
                    </ul>
                </Collapse>
            </nav>
        </div>
    )
}

Navbar.propTypes = {
    leftMenu: PropTypes.any,
    location: PropTypes.any,
    menuOpen: PropTypes.any,
    t: PropTypes.any,
}

const mapStatetoProps = state => {
    const {leftMenu} = state.Layout
    return {leftMenu}
}

export default withRouter(
    connect(mapStatetoProps, {})(withTranslation()(Navbar))
)