export const GET_MENSAGENS = "GET_MENSAGENS";
export const GET_MENSAGENS_SUCCESS = "GET_MENSAGENS_SUCCESS";
export const GET_MENSAGENS_FAIL = "GET_MENSAGENS_FAIL";
export const CREATE_MENSAGENS = "CREATE_MENSAGENS";
export const CREATE_MENSAGENS_FETCH = "CREATE_MENSAGENS_FETCH";
export const CREATE_MENSAGENS_SUCCESS = "CREATE_MENSAGENS_SUCCESS";
export const CREATE_MENSAGENS_FAIL = "CREATE_MENSAGENS_FAIL";
export const CREATE_MENSAGENS_EXIT = "CREATE_MENSAGENS_EXIT";
export const CREATE_MENSAGENS_SET = "CREATE_MENSAGENS_SET";
export const REMOVER_MENSAGEM = "REMOVER_MENSAGEM";
export const REMOVER_MENSAGEM_FETCH = "REMOVER_MENSAGEM_FETCH";
export const REMOVER_MENSAGEM_SUCCESS = "REMOVER_MENSAGEM_SUCCESS";
export const REMOVER_MENSAGEM_FAIL = "REMOVER_MENSAGEM_FAIL";
export const REMOVER_MENSAGEM_EXIT = "REMOVER_MENSAGEM_EXIT";
export const EDITAR_MENSAGEM = "EDITAR_MENSAGEM";
export const EDITAR_MENSAGEM_FETCH = "EDITAR_MENSAGEM_FETCH";
export const EDITAR_MENSAGEM_SUCCESS = "EDITAR_MENSAGEM_SUCCESS";
export const EDITAR_MENSAGEM_FAIL = "EDITAR_MENSAGEM_FAIL";
export const EDITAR_MENSAGEM_EXIT = "EDITAR_MENSAGEM_EXIT";
export const EDITAR_MENSAGEM_SET = "EDITAR_MENSAGEM_SET";
export const ENVIAR_MENSAGENS = "ENVIAR_MENSAGENS";
export const ENVIAR_MENSAGENS_FETCH = "ENVIAR_MENSAGENS_FETCH";
export const ENVIAR_MENSAGENS_SUCCESS = "ENVIAR_MENSAGENS_SUCCESS";
export const ENVIAR_MENSAGENS_FAIL = "ENVIAR_MENSAGENS_FAIL";
export const ENVIAR_MENSAGENS_EXIT = "ENVIAR_MENSAGENS_EXIT";
export const ENVIAR_MENSAGENS_CONEXAO_SET = "ENVIAR_MENSAGENS_CONEXAO_SET";
