const ID = "id";
const IMAGEM = "imagem";
const NOME = "nome";
const LINK = "link";
const LIMITE_PESSOAS = "limitePessoas";
const IS_LEAD_DUPLICADO = "isLeadDuplicado";
const CONEXAO = "conexao";
const IS_CRIAR_GRUPO = "isCriarGrupo";
const IS_MENSAGEM_BOAS_VINDAS = "isMensagemBoasVindas";
const NOME_GRUPO = "nomeGrupo";
const DESCRICAO_GRUPO = "descricaoGrupo";
const IMAGEM_GRUPO = "imagemGrupo";
const QTD_GRUPO = "quantidadeGrupo";
const INICIAR_NUMERACAO_GRUPO = "iniciarNumeracaoGrupo";
const ADMINISTRADORES_GRUPO = "administradoresGrupo";
const MENSAGEM_BOAS_VINDAS = "mensagemBoasVindas";
const ANEXO_BOAS_VINDAS = "anexoBoasVindas";

export const camposCampanhas = {
    ID,
    IMAGEM,
    NOME,
    LINK,
    LIMITE_PESSOAS,
    IS_LEAD_DUPLICADO,
    CONEXAO,
    IS_CRIAR_GRUPO,
    IS_MENSAGEM_BOAS_VINDAS,
    NOME_GRUPO,
    DESCRICAO_GRUPO,
    IMAGEM_GRUPO,
    QTD_GRUPO,
    INICIAR_NUMERACAO_GRUPO,
    ADMINISTRADORES_GRUPO,
    MENSAGEM_BOAS_VINDAS,
    ANEXO_BOAS_VINDAS
};

export const fieldsCampanhaClean = (fields) => {
    if (fields.isCriarGrupo && fields.isMensagemBoasVindas) {
        return Object.fromEntries(
            [ID, IMAGEM,
                NOME,
                LINK,
                LIMITE_PESSOAS,
                IS_LEAD_DUPLICADO,
                CONEXAO,
                IS_CRIAR_GRUPO,
                IS_MENSAGEM_BOAS_VINDAS,
                NOME_GRUPO,
                DESCRICAO_GRUPO,
                IMAGEM_GRUPO,
                QTD_GRUPO,
                INICIAR_NUMERACAO_GRUPO,
                ADMINISTRADORES_GRUPO,
                MENSAGEM_BOAS_VINDAS,
                ANEXO_BOAS_VINDAS].map((key) => [key, fields[key]]),
        );
    }
    if (fields.isMensagemBoasVindas && !fields.isCriarGrupo) {
        return Object.fromEntries(
            [ID, IMAGEM,
                NOME,
                LINK,
                LIMITE_PESSOAS,
                IS_LEAD_DUPLICADO,
                CONEXAO,
                IS_CRIAR_GRUPO,
                IS_MENSAGEM_BOAS_VINDAS,
                MENSAGEM_BOAS_VINDAS,
                ANEXO_BOAS_VINDAS].map((key) => [key, fields[key]]),
        );
    }
    if (!fields.isMensagemBoasVindas && fields.isCriarGrupo) {
        return Object.fromEntries(
            [ID, IMAGEM,
                NOME,
                LINK,
                LIMITE_PESSOAS,
                IS_LEAD_DUPLICADO,
                CONEXAO,
                IS_CRIAR_GRUPO,
                IS_MENSAGEM_BOAS_VINDAS,
                NOME_GRUPO,
                DESCRICAO_GRUPO,
                IMAGEM_GRUPO,
                QTD_GRUPO,
                INICIAR_NUMERACAO_GRUPO,
                ADMINISTRADORES_GRUPO].map((key) => [key, fields[key]]),
        );
    }
    return Object.fromEntries(
        [ID, IMAGEM,
            NOME,
            LINK,
            LIMITE_PESSOAS,
            IS_LEAD_DUPLICADO,
            CONEXAO,
            IS_CRIAR_GRUPO,
            IS_MENSAGEM_BOAS_VINDAS,
        ].map((key) => [key, fields[key]]),
    );
};