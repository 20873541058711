// Função para combinar o array antigo com novos dados
export function updateArray(oldArray, newArray) {
    const updatedArray = oldArray.map((oldItem) => {
        // Encontrar item correspondente no novo array
        const newItem = newArray.find(item => item.id === oldItem.id);

        // Se encontrar o item, sobrescreve os dados antigos
        return newItem ? {...oldItem, ...newItem} : oldItem;
    });

    // Adicionar os novos itens que não estão no array antigo
    const newItems = newArray.filter(newItem =>
        !oldArray.some(oldItem => oldItem.id === newItem.id)
    );

    return [...updatedArray, ...newItems];
}
