// Add the Firebase products that you want to use
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import {getFirebaseBackend} from "../../helpers/firebase_helper";
import firebase from "firebase/compat/app";

const createMensagem = async (data) => {
    const uid = getFirebaseBackend().getAuthenticatedUser().uid
    return await getFirebaseBackend().getMensagensCollection().doc(uid).update({mensagens: firebase.firestore.FieldValue.arrayUnion(data)})
};

const removeMensagem = async (data) => {
    const uid = getFirebaseBackend().getAuthenticatedUser().uid
    return await getFirebaseBackend().getMensagensCollection().doc(uid).update({mensagens: firebase.firestore.FieldValue.arrayRemove(data)})
};


export {createMensagem, removeMensagem};
