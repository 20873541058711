import {combineReducers} from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication
import Login from './auth/login/reducer';
import Account from './auth/register/reducer';
import ForgetPassword from './auth/forgetpwd/reducer';
import Profile from './auth/profile/reducer';


import conexoes from './conexoes/reducer';
import mensagens from "./mensagens/reducer";
import campanhas from "./campanhas/reducer";
import agendamentos from "./agendamento/reducer";

const rootReducer = combineReducers({
    // public
    Layout,
    Login,
    Account,
    ForgetPassword,
    Profile,
    conexoes,
    mensagens,
    campanhas,
    agendamentos
});

export default rootReducer;
