import {isValidPhoneNumber} from "react-phone-number-input";

export function getPhone(phoneWpUrl) {
    try {
        const phone = phoneWpUrl.replace("@s.whatsapp.net", "");
        return phone.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, "+$1 ($2) $3-$4");
    } catch (e) {
        console.error(e)
        return 'desconectada'
    }

}

export const isPhoneValid = (phone) => {
    try {
        return isValidPhoneNumber("+" + phone);
    } catch (error) {
        console.error(error);
        return false;
    }
};
