import React, {useState} from "react";
import {Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import * as MensagensActions from "../../../store/mensagens/actions";
import {bindActionCreators} from "redux";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import classnames from "classnames";
import Mensagem from "./components/Mensagem";
import NomeGrupo from "./components/NomeGrupo";
import ImagemGrupo from "./components/ImagemGrupo";
import DescricaoGrupo from "./components/DescricaoGrupo";
import AddContatos from "./components/AddContatos";
import AddAdministradores from "./components/AddAdministradores";
import RemoverContato from "./components/RemoverContato";

const Agendamentos = (props) => {
    const [activeTabJustify2, setactiveTabJustify2] = useState('25');

    function toggleCustomJustified2(tab) {
        if (activeTabJustify2 !== tab) {
            setactiveTabJustify2(tab);
        }
    }

    return (
        <div className="page-content">
            <Row>
                <Breadcrumb title={props.t("Schedules")} breadcrumbItem={props.t("Schedules")}/>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <Nav pills className="nav-justified">
                                <NavItem className="waves-effect waves-light">
                                    <NavLink
                                        style={{cursor: 'pointer'}}
                                        className={classnames({
                                            active: activeTabJustify2 === '25'
                                        })}
                                        onClick={() => {
                                            toggleCustomJustified2('25');
                                        }}
                                    >
                                        <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                        <span className="d-none d-sm-block">Mensagem</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem className="waves-effect waves-light">
                                    <NavLink
                                        style={{cursor: 'pointer'}}
                                        className={classnames({
                                            active: activeTabJustify2 === '26'
                                        })}
                                        onClick={() => {
                                            toggleCustomJustified2('26');
                                        }}
                                    >
                                        <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                        <span className="d-none d-sm-block">Nome Grupo</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem className="waves-effect waves-light">
                                    <NavLink
                                        style={{cursor: 'pointer'}}
                                        className={classnames({
                                            active: activeTabJustify2 === '27'
                                        })}
                                        onClick={() => {
                                            toggleCustomJustified2('27');
                                        }}
                                    >
                                        <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                        <span className="d-none d-sm-block">Imagem Grupo</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem className="waves-effect waves-light">
                                    <NavLink
                                        style={{cursor: 'pointer'}}
                                        className={classnames({
                                            active: activeTabJustify2 === '28'
                                        })}
                                        onClick={() => {
                                            toggleCustomJustified2('28');
                                        }}
                                    >
                                        <span className="d-block d-sm-none"><i className="fas fa-cog"></i></span>
                                        <span className="d-none d-sm-block">Descrição Grupo</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem className="waves-effect waves-light">
                                    <NavLink
                                        style={{cursor: 'pointer'}}
                                        className={classnames({
                                            active: activeTabJustify2 === '29'
                                        })}
                                        onClick={() => {
                                            toggleCustomJustified2('29');
                                        }}
                                    >
                                        <span className="d-block d-sm-none"><i className="fas fa-cog"></i></span>
                                        <span className="d-none d-sm-block">Add Contatos</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem className="waves-effect waves-light">
                                    <NavLink
                                        style={{cursor: 'pointer'}}
                                        className={classnames({
                                            active: activeTabJustify2 === '30'
                                        })}
                                        onClick={() => {
                                            toggleCustomJustified2('30');
                                        }}
                                    >
                                        <span className="d-block d-sm-none"><i className="fas fa-cog"></i></span>
                                        <span className="d-none d-sm-block">Add Adms</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem className="waves-effect waves-light">
                                    <NavLink
                                        style={{cursor: 'pointer'}}
                                        className={classnames({
                                            active: activeTabJustify2 === '31'
                                        })}
                                        onClick={() => {
                                            toggleCustomJustified2('31');
                                        }}
                                    >
                                        <span className="d-block d-sm-none"><i className="fas fa-cog"></i></span>
                                        <span className="d-none d-sm-block">Remover Contatos</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent activeTab={activeTabJustify2} className="p-3 text-muted">
                                <TabPane tabId="25">
                                    <Mensagem/>
                                </TabPane>
                                <TabPane tabId="26">
                                    <NomeGrupo/>
                                </TabPane>
                                <TabPane tabId="27">
                                    <ImagemGrupo/>
                                </TabPane>
                                <TabPane tabId="28">
                                    <DescricaoGrupo/>
                                </TabPane>
                                <TabPane tabId="29">
                                    <AddContatos/>
                                </TabPane>
                                <TabPane tabId="30">
                                    <AddAdministradores/>
                                </TabPane>
                                <TabPane tabId="31">
                                    <RemoverContato/>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

const mapStatetoProps = (state) => ({mensagensState: state.mensagens});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators(MensagensActions, dispatch);

Agendamentos.propTypes = {
    t: PropTypes.any,
};
export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(Agendamentos));
