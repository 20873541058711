import {Button, Col, FormGroup, Label, Row} from "reactstrap";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as MensagensActions from "../../../../../../store/mensagens/actions";
import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import {AvField} from "availity-reactstrap-validation";
import {camposMensagens} from "../fields";

const EnqueteOpcoes = (props) => {
    const [rows, setRows] = useState(
        props.mensagensState.mensagem[camposMensagens.ENQUETE_OPCOES] || [""],
    );

    function handleAddRowNested() {
        setRows([...rows, ""]);
    }

    function handleRemoveRowNested(e, id) {
        let data = [...rows];
        data.splice(id, 1);
        setRows(data);
    }

    function handleChange(e, id) {
        const updatedFields = [...rows];
        updatedFields[id] = e.target.value;
        setRows(updatedFields);
    }

    useEffect(() => {
        props.createMensagensSet(rows, camposMensagens.ENQUETE_OPCOES);
    }, [rows]);
    return (
        <FormGroup row>
            <Label for="opcoes-enquete" sm={3}>
                {props.t("WA Poll Options")}
            </Label>
            <Col sm={9}>
                <table style={{width: "100%"}}>
                    <tbody>
                    {rows.map((item1, id) => (
                        <tr id={"nested" + id} key={id}>
                            <td>
                                <Row className="mb-2">
                                    <Col md="10">
                                        <AvField
                                            name="Opção da Enquete"
                                            type={"text"}
                                            errorMessage="Digite o opção da Enquete"
                                            className="inner form-control border border-secondary"
                                            value={item1}
                                            placeholder={
                                                props.t("WA Poll Options Placeholder") +
                                                " " +
                                                (id + 1)
                                            }
                                            validate={{required: {value: true}}}
                                            onChange={(e) => handleChange(e, id)}
                                        />
                                    </Col>
                                    <Col md="2">
                                        <Button
                                            onClick={(e) => {
                                                handleRemoveRowNested(e, id);
                                            }}
                                            color="primary"
                                            className="btn-block inner border border-secondary"
                                            style={{width: "100%"}}
                                        >
                                            {props.t("WA Poll Options Delete")}
                                        </Button>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <Button
                    onClick={() => {
                        handleAddRowNested();
                    }}
                    color="success"
                    className="mt-1"
                >
                    {props.t("WA Poll Options Add")}
                </Button>
            </Col>
        </FormGroup>
    );
};
EnqueteOpcoes.propTypes = {
    t: PropTypes.any,
    createMensagensSet: PropTypes.func,
};
const mapStatetoProps = (state) => ({mensagensState: state.mensagens});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators(MensagensActions, dispatch);

export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(EnqueteOpcoes));
