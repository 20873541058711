export const CREATE_AGENDAMENTO = "CREATE_AGENDAMENTO";
export const CREATE_AGENDAMENTO_ERRORS = "CREATE_AGENDAMENTO_ERRORS";
export const CREATE_AGENDAMENTO_SUCCESS = "CREATE_AGENDAMENTO_SUCCESS";
export const CREATE_AGENDAMENTO_FAIL = "CREATE_AGENDAMENTO_FAIL";
export const CREATE_AGENDAMENTO_END = "CREATE_AGENDAMENTO_END";
export const DELETE_AGENDAMENTO = "DELETE_AGENDAMENTO";
export const EDIT_AGENDAMENTO = "EDIT_AGENDAMENTO";
export const EDITING_AGENDAMENTO = "EDITING_AGENDAMENTO";
export const GET_AGENDAMENTOS = "GET_AGENDAMENTOS";
export const GET_AGENDAMENTOS_SUCCESS = "GET_AGENDAMENTOS_SUCCESS";
