import {
    CREATE_AGENDAMENTO,
    CREATE_AGENDAMENTO_END,
    CREATE_AGENDAMENTO_ERRORS,
    CREATE_AGENDAMENTO_FAIL,
    CREATE_AGENDAMENTO_SUCCESS,
    DELETE_AGENDAMENTO,
    EDITING_AGENDAMENTO,
    GET_AGENDAMENTOS,
    GET_AGENDAMENTOS_SUCCESS,
} from "./actionTypes";

const initialState = {
    agendamento: null,
    isCreatingAgendamento: false,
    errors: null,
    submit: null,
    agendamentos: null,
    isLoadingAgendamentos: false
};

const agendamentos = (state = initialState, action) => {
    switch (action.type) {
        case EDITING_AGENDAMENTO:
            const data = action.payload.data;
            const field = action.payload.field;
            return {...state, agendamento: {...state.agendamento, [field]: data}};
        case CREATE_AGENDAMENTO:
            return {
                ...state,
                isCreatingAgendamento: true
            };
        case CREATE_AGENDAMENTO_END:
            return {
                ...state,
                submit: null
            };
        case CREATE_AGENDAMENTO_ERRORS:
            return {
                ...state,
                errors: action.payload.data
            };
        case CREATE_AGENDAMENTO_SUCCESS:
            return {
                ...state,
                isCreatingAgendamento: false,
                errors: null,
                submit: true
            };
        case CREATE_AGENDAMENTO_FAIL:
            return {
                ...state,
                isCreatingAgendamento: false,
                agendamento: null,
                errors: null,
                submit: false
            };
        case GET_AGENDAMENTOS:
            return {
                ...state,
                isLoadingAgendamentos: true
            };
        case GET_AGENDAMENTOS_SUCCESS:
            return {
                ...state,
                isLoadingAgendamentos: false,
                agendamentos: action.payload.data
            };
        case DELETE_AGENDAMENTO:
            return state
        default:
            return state
    }
};

export default agendamentos;
