import PropTypes, {func} from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Col, FormGroup, Input, Label} from "reactstrap";
import React from "react";
import * as AgendamentoActions from "../../../../../store/agendamento/actions";
import {camposAgendamentos} from "../validation/fields";
import moment from "moment/moment";


const DataTempoSelect = (props) => {
    const handleChangeData = (event) => {
        props.editingAgendamentos(new Date(event.target.value), camposAgendamentos.DATA_ENVIO)
    }
    return (
        <FormGroup row>
            <Label for="mensagem-select" sm={3}>
                {props.t("Date")}
            </Label>
            <Col sm={9}>
                <Input className="form-control"
                       type="datetime-local"
                       onChange={handleChangeData}
                       id="example-datetime-local-input"
                       invalid={props.agendamentosState?.errors?.dataEnvio}
                       value={props.agendamentosState?.agendamento?.dataEnvio && moment(props.agendamentosState?.agendamento?.dataEnvio).format('YYYY-MM-DDTHH:mm') || ''}
                       valid={props.agendamentosState?.agendamento?.dataEnvio}
                />
            </Col>
        </FormGroup>
    );
};

const mapStatetoProps = (state) => ({agendamentosState: state.agendamentos});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators({...AgendamentoActions}, dispatch);

DataTempoSelect.propTypes = {
    t: PropTypes.any,
    agendamentosState: PropTypes.object,
    editingAgendamentos: func
};
export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(DataTempoSelect));
