import {Row} from "reactstrap";
import React from "react";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import logo from "../../assets/images/logo-md-dark.png";

const Home = (props) => {
    return (
        <div className="page-content">
            <Row>
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h4 className="page-title mb-0 font-size-18">Home</h4>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item active">{props.t("Welcome")}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="text-center">
                        <img src={logo} alt="Logo ZapEase"/>
                        <br/>
                        <h1>{props.t("Welcome")}</h1>
                    </div>
                </div>
            </Row>
        </div>
    );
};

Home.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(Home);
