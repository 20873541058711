import PropTypes from "prop-types"
import React, {useState} from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import {Link} from "react-router-dom"

//i18n
import {withTranslation} from "react-i18next"
import withRouter from "../Common/withRouter"
import {zapeaseRoutes} from "../../routes/zapease";

const SidebarContent = (props) => {
    const pathname = props.router.location.pathname;
    const [toggle, setToggle] = useState(false)
    return (
        <SimpleBar className="vertical-simplebar">
            <div id="sidebar-menu">
                <ul className="metismenu list-unstyled" id="side-menu">
                    <li className="menu-title">{props.t("Menu")} </li>
                    <li>
                        <Link to={zapeaseRoutes.devices}
                              className={pathname !== zapeaseRoutes.devices ? 'waves-effect' : 'waves-effect mm-active'}>
                            <i className="mdi mdi-cellphone"></i>
                            <span>{props.t("Devices")}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={zapeaseRoutes.campaigns}
                              className={pathname !== zapeaseRoutes.campaigns ? 'waves-effect' : 'waves-effect mm-active'}>
                            <i className="mdi mdi-bell-outline"></i>
                            <span>{props.t("Campaigns")}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={zapeaseRoutes.messages}
                              className={pathname !== zapeaseRoutes.messages ? 'waves-effect' : 'waves-effect mm-active'}>
                            <i className="mdi mdi-message-text-outline"></i>
                            <span>{props.t("Messages")}</span>
                        </Link>
                    </li>
                    {/*<li>*/}
                    {/*    <Link to={zapeaseRoutes.schedules}*/}
                    {/*          className={pathname !== zapeaseRoutes.schedules ? 'waves-effect' : 'waves-effect mm-active'}>*/}
                    {/*        <i className="mdi mdi-pin"></i>*/}
                    {/*        <span>{props.t("Schedules")}</span>*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    <li className={toggle ? "mm-active" : ""}>
                        <Link to="#" className={toggle ? "has-arrow waves-effect mm-active" : "has-arrow waves-effect"}
                              onClick={() => setToggle(!toggle)}
                              aria-expanded={toggle}>
                            <i className="mdi mdi-pin"></i>
                            <span>{props.t("Schedules")}</span>
                        </Link>
                        <ul className={toggle ? "sub-menu mm-show" : "sub-menu mm-collapse"}>
                            <li><Link to="/agendar"
                                      className={pathname !== zapeaseRoutes.scheduling ? 'waves-effect' : 'waves-effect mm-active'}>{props.t("Create")}</Link>
                            </li>
                            <li><Link to="/agendamentos"
                                      className={pathname !== zapeaseRoutes.schedules ? 'waves-effect' : 'waves-effect mm-active'}>{props.t("View")}</Link>
                            </li>

                        </ul>
                    </li>
                    <li>
                        <Link to="/logout" className="waves-effect text-danger">
                            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>{" "}
                            <span>{props.t("Logout")}</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </SimpleBar>
    )
}

SidebarContent.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    router: PropTypes.object,
}

export default withRouter(withTranslation()(SidebarContent))
