import React from "react"
import PropTypes from 'prop-types'

import {connect} from "react-redux"
import {
    changeLayout,
    changeLayoutTheme,
    changeLayoutWidth,
    changePreloader,
    changeSidebarTheme,
    changeSidebarType,
    changeTopbarTheme,
    showRightSidebarAction
} from "../../store/actions"

//SimpleBar
import SimpleBar from "simplebar-react"

import {Link} from "react-router-dom"

import "./rightbar.scss"

//Import images
import {withTranslation} from "react-i18next";

const RightSidebar = props => {
    return (
        <React.Fragment>
            <div className="right-bar">
                <SimpleBar style={{height: "900px"}}>
                    <div data-simplebar={true} className="h-100">
                        <div className="rightbar-title d-flex align-items-center px-3 py-4">
                            <h5 className="m-0 me-2">{props.t("Settings")}</h5>
                            <Link
                                to="#"
                                onClick={e => {
                                    e.preventDefault()
                                    props.showRightSidebarAction(false)
                                }}
                                className="right-bar-toggle ms-auto"
                            >
                                <i className="mdi mdi-close noti-icon"/>
                            </Link>
                        </div>

                        <hr className="my-0"/>

                        <div className="p-4">
                            <div className="radio-toolbar">
                                <span className="mb-2 d-block">{props.t("Layouts")}</span>
                                <input
                                    type="radio"
                                    id="radioVertical"
                                    name="radioFruit"
                                    value="detached"
                                    checked={props.layoutType === "detached"}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            props.changeLayout(e.target.value)
                                        }
                                    }}
                                />
                                <label htmlFor="radioVertical">{props.t("Vertical")}</label>
                                {" "}
                                <input
                                    type="radio"
                                    id="radioHorizontal"
                                    name="radioFruit"
                                    value="horizontal"
                                    checked={props.layoutType === "horizontal"}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            props.changeLayout(e.target.value)
                                            props.changeTopbarTheme(e.target.value)
                                        }
                                    }}
                                />
                                <label htmlFor="radioHorizontal">{props.t("Horizontal")}</label>
                            </div>
                            <hr className="mt-1"/>

                            <div className="radio-toolbar">
                                <span className="mb-2 d-block" id="radio-title">{props.t("Theme")}</span>
                                <input
                                    type="radio"
                                    id="radioThemeLightMode"
                                    name="radioThemeMode"
                                    value="light"
                                    checked={props.theme === "light"}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            props.changeLayoutTheme(e.target.value)
                                            props.changeSidebarTheme(e.target.value)
                                        }
                                    }}/>
                                <label htmlFor="radioThemeLightMode">{props.t("Light")}</label>
                                {"   "}
                                <input
                                    type="radio"
                                    id="radioThemeDarkMode"
                                    name="radioThemeMode"
                                    value="dark"
                                    checked={props.theme === "dark"}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            props.changeLayoutTheme(e.target.value)
                                            props.changeSidebarTheme(e.target.value)
                                        }
                                    }}/>
                                <label htmlFor="radioThemeDarkMode">{props.t("Dark")}</label>
                                {"   "}
                            </div>
                            <hr className="mt-1"/>

                            <div className="radio-toolbar">
                <span className="mb-2 d-block" id="radio-title">
                  Layout Width
                </span>
                                <input
                                    type="radio"
                                    id="radioFluid"
                                    name="radioWidth"
                                    value="fluid"
                                    checked={props.layoutWidth === "fluid"}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            props.changeLayoutWidth(e.target.value)
                                        }
                                    }}
                                />
                                <label htmlFor="radioFluid">{props.t("Fluid")}</label>
                                <input
                                    type="radio"
                                    id="radioBoxed"
                                    name="radioWidth"
                                    value="boxed"
                                    checked={props.layoutWidth === "boxed"}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            props.changeLayoutWidth(e.target.value)
                                        }
                                    }}
                                />
                                <label htmlFor="radioBoxed">{props.t("Boxed")}</label>

                            </div>
                            <hr className="mt-1"/>

                            <div className="radio-toolbar">
                <span className="mb-2 d-block" id="radio-title">
                  Topbar Theme
                </span>
                                {props.layoutType === "horizontal" ? (
                                    <>
                                        <input
                                            type="radio"
                                            id="radioThemeLight"
                                            name="radioTheme"
                                            value="light"
                                            checked={props.topbarTheme === "light"}
                                            onChange={e => {
                                                if (e.target.checked) {
                                                    props.changeTopbarTheme(e.target.value)
                                                }
                                            }}
                                        />
                                        <label htmlFor="radioThemeLight">{props.t("Light")}</label>
                                    </>
                                ) : null}
                                {"   "}
                                <input
                                    type="radio"
                                    id="radioThemeDark"
                                    name="radioTheme"
                                    value="dark"
                                    checked={props.topbarTheme === "dark"}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            props.changeTopbarTheme(e.target.value)
                                        }
                                    }}
                                />

                                <label htmlFor="radioThemeDark">{props.t("Dark")}</label>
                                {"   "}
                                <>
                                    <input
                                        type="radio"
                                        id="radioThemeColored"
                                        name="radioTheme"
                                        value="colored"
                                        checked={props.topbarTheme === "colored"}
                                        onChange={e => {
                                            if (e.target.checked) {
                                                props.changeTopbarTheme(e.target.value)
                                            }
                                        }}
                                    />
                                    <label htmlFor="radioThemeColored">{props.t("Colored")}</label>
                                </>
                            </div>

                            {props.layoutType === "detached" ? (
                                <React.Fragment>
                                    <hr className="mt-1"/>
                                    <div className="radio-toolbar">
                    <span className="mb-2 d-block" id="radio-title">
                      {props.t("Left Sidebar Type")}
                    </span>
                                        <input
                                            type="radio"
                                            id="sidebarDefault"
                                            name="sidebarType"
                                            value="default"
                                            checked={props.leftSideBarType === "default"}
                                            onChange={e => {
                                                if (e.target.checked) {
                                                    props.changeSidebarType(e.target.value)
                                                }
                                            }}
                                        />
                                        <label htmlFor="sidebarDefault">{props.t("Default")}</label>
                                        {"   "}
                                        <input
                                            type="radio"
                                            id="sidebarCompact"
                                            name="sidebarType"
                                            value="compact"
                                            checked={props.leftSideBarType === "compact"}
                                            onChange={e => {
                                                if (e.target.checked) {
                                                    props.changeSidebarType(e.target.value)
                                                }
                                            }}
                                        />
                                        <label htmlFor="sidebarCompact">{props.t("Compact")}</label>
                                        {"   "}
                                        <input
                                            type="radio"
                                            id="sidebarIcon"
                                            name="sidebarType"
                                            value="icon"
                                            checked={props.leftSideBarType === "icon"}
                                            onChange={e => {
                                                if (e.target.checked) {
                                                    props.changeSidebarType(e.target.value)
                                                }
                                            }}
                                        />
                                        <label htmlFor="sidebarIcon">{props.t("Icon")}</label>
                                    </div>


                                    <hr className="mt-1"/>
                                    <div className="radio-toolbar">
                    <span className="mb-2 d-block" id="radio-title">
                      {props.t("Left Sidebar Color")}
                    </span>
                                        <input
                                            type="radio"
                                            id="leftsidebarThemelight"
                                            name="leftsidebarTheme"
                                            value="light"
                                            checked={props.leftSideBarTheme === "light"}
                                            onChange={e => {
                                                if (e.target.checked) {
                                                    props.changeSidebarTheme(e.target.value)
                                                }
                                            }}
                                        />

                                        <label htmlFor="leftsidebarThemelight">{props.t("Light")}</label>
                                        {"   "}
                                        <input
                                            type="radio"
                                            id="leftsidebarThemedark"
                                            name="leftsidebarTheme"
                                            value="dark"
                                            checked={props.leftSideBarTheme === "dark"}
                                            onChange={e => {
                                                if (e.target.checked) {
                                                    props.changeSidebarTheme(e.target.value)
                                                }
                                            }}
                                        />

                                        <label htmlFor="leftsidebarThemedark">{props.t("Dark")}</label>
                                        {"   "}
                                        <input
                                            type="radio"
                                            id="leftsidebarThemecolored"
                                            name="leftsidebarTheme"
                                            value="colored"
                                            checked={props.leftSideBarTheme === "colored"}
                                            onChange={e => {
                                                if (e.target.checked) {
                                                    props.changeSidebarTheme(e.target.value)
                                                }
                                            }}
                                        />

                                        <label htmlFor="leftsidebarThemecolored">{props.t("Colored")}</label>
                                    </div>
                                    <hr className="mt-1"/>
                                </React.Fragment>
                            ) : null}
                            <hr className="mt-1"/>
                        </div>
                    </div>
                </SimpleBar>
            </div>
            <div className="rightbar-overlay"/>
        </React.Fragment>
    )
}

RightSidebar.propTypes = {
    changeLayoutTheme: PropTypes.func,
    changeLayout: PropTypes.func,
    changeLayoutWidth: PropTypes.func,
    changeSidebarTheme: PropTypes.func,
    changeSidebarType: PropTypes.func,
    changeTopbarTheme: PropTypes.func,
    layoutType: PropTypes.any,
    layoutWidth: PropTypes.any,
    leftSideBarTheme: PropTypes.any,
    leftSideBarType: PropTypes.any,
    showRightSidebarAction: PropTypes.func,
    topbarTheme: PropTypes.any,
    theme: PropTypes.any,
    t: PropTypes.any,
}

const mapStateToProps = state => {
    return {...state.Layout}
}

export default connect(mapStateToProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changeTopbarTheme,
    changePreloader,
    showRightSidebarAction,
    changeLayoutTheme
})(withTranslation()(RightSidebar))
