import {Col, Modal, ModalBody, ModalFooter, Row, UncontrolledAlert,} from "reactstrap";
import React, {useState} from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as MensagensActions from "../../../../store/mensagens/actions";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import WhatsAppEditor from "./fields/create/WhatsAppEditor";
import TituloInput from "./fields/create/TituloInput";
import MencaoSwitch from "./fields/create/MencaoSwitch";
import TipoSelect from "./fields/create/TipoSelect";
import {AvForm} from "availity-reactstrap-validation";
import {
    TIPO_MIDIA_ARQUIVO,
    TIPO_MIDIA_AUDIO,
    TIPO_MIDIA_IMAGEM,
    TIPO_MIDIA_VIDEO,
    TIPO_TEXTO_ENQUETE,
    TIPO_TEXTO_LINK,
    TIPO_TEXTO_TEXTO,
} from "./fields/tipoOptions";
import EnqueteOpcoes from "./fields/create/EnqueteOpcoes";
import ImagemInput from "./fields/create/ImagemInput";
import VideoInput from "./fields/create/VideoInput";
import AudioInput from "./fields/create/AudioInput";
import NomeEnquete from "./fields/create/NomeEnquete";
import ArquivoInput from "./fields/create/ArquivoInput";
import {fieldsMensagemClean} from "./fields/fields";
import {validate} from "./fields/validate";
import LinkInput from "./fields/create/LinkInput";

const CriarEditarMensagemModal = (props) => {
    const [error, setError] = useState(false);
    const handleSubmit = () => {
        const fieldsCleaned = fieldsMensagemClean(props.mensagensState.mensagem);

        if (validate(fieldsCleaned)) {
            if (props.mensagensState.oldMensagem) {
                props.editarMensagemFetch({
                    ...fieldsCleaned,
                    id: props.mensagensState.mensagem.id,
                }, props.mensagensState.oldMensagem);
            } else {
                props.createMensagensFetch(fieldsCleaned);
            }
        } else {
            setError(true);
        }
    };

    function renderTexto() {
        return (
            <div>
                <MencaoSwitch/>
                <WhatsAppEditor/>
            </div>
        );
    }

    function renderEnquete() {
        return (
            <div>
                <MencaoSwitch/>
                <NomeEnquete/>
                <EnqueteOpcoes/>
            </div>
        );
    }

    function renderImagem() {
        return (
            <div>
                <MencaoSwitch/>
                <ImagemInput/>
                <WhatsAppEditor/>
            </div>
        );
    }

    function renderVideo() {
        return (
            <div>
                <MencaoSwitch/>
                <VideoInput/>
                <WhatsAppEditor/>
            </div>
        );
    }

    function renderAudio() {
        return (
            <div>
                <MencaoSwitch/>
                <AudioInput/>
            </div>
        );
    }

    function renderArquivo() {
        return (
            <div>
                <MencaoSwitch/>
                <ArquivoInput/>
            </div>
        );
    }

    function renderLink() {
        return (
            <div>
                <MencaoSwitch/>
                <LinkInput/>
                <WhatsAppEditor/>
            </div>
        );
    }

    function renderTipo() {
        if (!props.mensagensState.mensagem) {
            return null;
        }

        switch (props.mensagensState.mensagem.tipo) {
            case TIPO_TEXTO_TEXTO:
                return renderTexto();
            case TIPO_TEXTO_ENQUETE:
                return renderEnquete();
            case TIPO_TEXTO_LINK:
                return renderLink();
            case TIPO_MIDIA_IMAGEM:
                return renderImagem();
            case TIPO_MIDIA_VIDEO:
                return renderVideo();
            case TIPO_MIDIA_AUDIO:
                return renderAudio();
            case TIPO_MIDIA_ARQUIVO:
                return renderArquivo();
            default:
                return renderTexto();
        }
    }

    if (!props.mensagensState.isOpenModalCriarEditarMensagem) {
        return null
    }
    return (
        <Row>
            <Col sm={6} md={4} xl={3}>
                <Modal
                    size="lg"
                    isOpen={props.mensagensState.isOpenModalCriarEditarMensagem}
                    toggle={props.createMensagensExit}
                    centered
                    scrollable={true}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">{props.t("Send Message")}</h5>
                        <button
                            type="button"
                            onClick={props.createMensagensExit}
                            className="btn-close-modal"
                            data-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <AvForm className="needs-validation">
                        <ModalBody>
                            {error && (
                                <UncontrolledAlert
                                    color="danger"
                                    className="alert-dismissible fade show"
                                    role="alert"
                                    fade
                                    toggle={() => setError(!error)}
                                >
                                    Por favor preencha os campos corretamente para criar a
                                    mensagem
                                </UncontrolledAlert>
                            )}
                            <TituloInput/>
                            <TipoSelect/>
                            {renderTipo()}
                        </ModalBody>
                        <ModalFooter>
                            <button
                                type="button"
                                onClick={props.createMensagensExit}
                                className="btn btn-primary waves-effect"
                                data-dismiss="modal"
                            >
                                {props.t("Close")}
                            </button>
                            <button
                                type="submit"
                                className="btn btn-success waves-effect waves-light"
                                onClick={handleSubmit}
                            >
                                {props.mensagensState.oldMensagem ? 'Editar Mensagem' : props.t("Create Message")}
                            </button>
                        </ModalFooter>
                    </AvForm>
                </Modal>
            </Col>
        </Row>
    );
};

CriarEditarMensagemModal.propTypes = {
    t: PropTypes.any,
    mensagensState: PropTypes.object,
    createMensagensExit: PropTypes.func,
    createMensagensFetch: PropTypes.func,
    editarMensagemFetch: PropTypes.func,
};
const mapStatetoProps = (state) => ({mensagensState: state.mensagens});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators(MensagensActions, dispatch);

export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(CriarEditarMensagemModal));
