import {CREATE_AGENDAMENTO, DELETE_AGENDAMENTO, GET_AGENDAMENTOS} from "./actionTypes";
import {call, put, takeEvery} from "redux-saga/effects";
import {createAgendamentoFail, createAgendamentoSuccess, getAgendamentosSuccess} from "./actions";
import {createAgendamento, deleteAgendamento, getAgendamentos} from "../../api/zaphook/agendamento";
import {getFirebaseBackend} from "../../helpers/firebase_helper";

function* createAgendamentoSaga(action) {
    try {
        yield call(createAgendamento, action.payload.data)
        yield put(createAgendamentoSuccess())
    } catch (e) {
        console.error(e)
        yield put(createAgendamentoFail())
    }
}

function* cancelAgendamentoSaga(action) {
    try {
        yield call(deleteAgendamento, action.payload.data)
        yield put(getAgendamentos())
    } catch (e) {
        console.error(e)
        yield put(getAgendamentos())
    }
}

function* getAgendamentosSaga() {
    try {

        let response = yield call(getAgendamentos, getFirebaseBackend().getAuthenticatedUser().uid);
        yield put(getAgendamentosSuccess(response))
    } catch (e) {
        console.error(e)
        yield put(createAgendamentoFail())
    }
}

function* agendamentoSaga() {
    yield takeEvery(CREATE_AGENDAMENTO, createAgendamentoSaga);
    yield takeEvery(GET_AGENDAMENTOS, getAgendamentosSaga);
    yield takeEvery(DELETE_AGENDAMENTO, cancelAgendamentoSaga);
}

export default agendamentoSaga;
