import {Col, Modal, ModalBody, Row} from "reactstrap";
import React from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as MensagensActions from "../../../../store/mensagens/actions";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {AvForm} from "availity-reactstrap-validation";
import {
    TIPO_MIDIA_ARQUIVO,
    TIPO_MIDIA_AUDIO,
    TIPO_MIDIA_IMAGEM,
    TIPO_MIDIA_VIDEO,
    TIPO_TEXTO_ENQUETE,
    TIPO_TEXTO_LINK,
    TIPO_TEXTO_TEXTO,
} from "./fields/tipoOptions";
import MencaoSwitchView from "./fields/view/MencaoSwitchView";
import WhatsAppEditorView from "./fields/view/WhatsAppEditorView";
import NomeEnqueteView from "./fields/view/NomeEnqueteView";
import EnqueteOpcoesView from "./fields/view/EnqueteOpcoesView";
import ImagemInputView from "./fields/view/ImagemInputView";
import VideoInputView from "./fields/view/VideoInputView";
import AudioInputView from "./fields/view/AudioInputView";
import ArquivoInputView from "./fields/view/ArquivoInputView";
import LinkInputView from "./fields/view/LinkInputView";
import TituloInputView from "./fields/view/TituloInputView";
import TipoSelectView from "./fields/view/TipoSelectView";

const VisualizarMensagemModal = (props) => {
    function renderTexto() {
        return (
            <div>
                <MencaoSwitchView/>
                <WhatsAppEditorView/>
            </div>
        );
    }

    function renderEnquete() {
        return (
            <div>
                <MencaoSwitchView/>
                <NomeEnqueteView/>
                <EnqueteOpcoesView/>
            </div>
        );
    }

    function renderImagem() {
        return (
            <div>
                <MencaoSwitchView/>
                <ImagemInputView/>
                <WhatsAppEditorView/>
            </div>
        );
    }

    function renderVideo() {
        return (
            <div>
                <MencaoSwitchView/>
                <VideoInputView/>
                <WhatsAppEditorView/>
            </div>
        );
    }

    function renderAudio() {
        return (
            <div>
                <MencaoSwitchView/>
                <AudioInputView/>
            </div>
        );
    }

    function renderArquivo() {
        return (
            <div>
                <MencaoSwitchView/>
                <ArquivoInputView/>
            </div>
        );
    }

    function renderLink() {
        return (
            <div>
                <MencaoSwitchView/>
                <LinkInputView/>
                <WhatsAppEditorView/>
            </div>
        );
    }

    function renderTipo() {
        if (!props.mensagensState.mensagem) {
            return null;
        }

        switch (props.mensagensState.mensagem.tipo) {
            case TIPO_TEXTO_TEXTO:
                return renderTexto();
            case TIPO_TEXTO_ENQUETE:
                return renderEnquete();
            case TIPO_TEXTO_LINK:
                return renderLink();
            case TIPO_MIDIA_IMAGEM:
                return renderImagem();
            case TIPO_MIDIA_VIDEO:
                return renderVideo();
            case TIPO_MIDIA_AUDIO:
                return renderAudio();
            case TIPO_MIDIA_ARQUIVO:
                return renderArquivo();
            default:
                return renderTexto();
        }
    }

    if (!props.mensagensState.isOpenModalCriarEditarMensagem) {
        return null;
    }

    return (
        <Row>
            <Col sm={6} md={4} xl={3}>
                <Modal
                    size="lg"
                    isOpen={props.mensagensState.isOpenModalCriarEditarMensagem}
                    toggle={props.createMensagensExit}
                    centered
                    scrollable={true}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">{props.t("Send Message")}</h5>
                        <button
                            type="button"
                            onClick={props.createMensagensExit}
                            className="btn-close-modal"
                            data-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <AvForm className="needs-validation">
                        <ModalBody>
                            <TituloInputView/>
                            <TipoSelectView/>
                            {renderTipo()}
                        </ModalBody>
                    </AvForm>
                </Modal>
            </Col>
        </Row>
    );
};

VisualizarMensagemModal.propTypes = {
    t: PropTypes.any,
    mensagensState: PropTypes.object,
    createMensagensExit: PropTypes.func,
    createMensagensFetch: PropTypes.func,
    editarMensagemFetch: PropTypes.func,
};
const mapStatetoProps = (state) => ({mensagensState: state.mensagens});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators(MensagensActions, dispatch);

export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(VisualizarMensagemModal));
