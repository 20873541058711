import toastr from "toastr";
import "toastr/build/toastr.min.css";

export default function notification() {
    toastr.options = {
        closeButton: true,
        debug: false,
        extendedTimeOut: "1000",
        hideDuration: "1000",
        hideEasing: "linear",
        hideMethod: "fadeOut",
        newestOnTop: false,
        positionClass: "toast-progress",
        preventDuplicates: false,
        progressBar: true,
        showDuration: "300",
        showEasing: "swing",
        showMethod: "fadeIn",
        timeOut: "5000",
    };
    return toastr;
}

export function notificationError(translator) {
    notification().error(translator("Default Error"), "Oopps");
}
