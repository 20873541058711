function validateAddAdminisradores(agendamento) {

}

function validateAddContatos(agendamento) {

}

function validateDescricaoGrupo(agendamento) {

}

function validateImagemGrupo(agendamento) {

}

function validateMensagem(agendamento) {
    const errors = {};
    for (const [key, value] of Object.entries(agendamento)) {
        if (
            (value === "" || value === null || value === undefined || value <= 0)
        ) {
            errors[key] = true;
        }
    }

    if (Object.entries(errors).length > 0) {
        return {
            isValid: false,
            errors,
        };
    }

    return {
        isValid: true,
    };
}

function validateNomeGrupo(agendamento) {

}

function validateRemoverContato(agendamento) {

}

export {
    validateMensagem,
    validateAddAdminisradores,
    validateAddContatos,
    validateDescricaoGrupo,
    validateImagemGrupo,
    validateNomeGrupo,
    validateRemoverContato
}
