import React from "react"
import {Col, Container, Row} from "reactstrap"

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid={true}>
                    <Row>
                        <Col sm={6}>{new Date().getFullYear()} © ZapEase.</Col>
                        <Col sm={6}>
                            <div className="text-sm-end d-none d-sm-block">
                                Design & Develop <i className="mdi mdi-heart text-danger"></i> by <a
                                href='https://github.com/g-saldanha' target='_blank'>Gbl</a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    )
}

export default Footer
