import {
  CREATE_CONEXAO,
  CREATE_CONEXAO_EXIT,
  CREATE_CONEXAO_FAIL,
  CREATE_CONEXAO_PLAN_EXCEEDED,
  CREATE_CONEXAO_SUCCESS,
  GET_CONEXOES,
  GET_CONEXOES_FAIL,
  GET_CONEXOES_SUCCESS,
  GET_CONNECT_CONEXAO,
  GET_CONNECT_CONEXAO_EXIT,
  GET_CONNECT_CONEXAO_FAIL,
  GET_CONNECT_CONEXAO_FETCH,
  GET_CONNECT_CONEXAO_SUCCESS,
  LOGOUT_CONEXAO,
  LOGOUT_CONEXAO_EXIT,
  LOGOUT_CONEXAO_FETCH,
  LOGOUT_CONEXAO_SUCCESS,
} from "./actionTypes";

export const createConexao = () => ({
  type: CREATE_CONEXAO,
});

export const createConexaoSuccessful = (conexao) => ({
  type: CREATE_CONEXAO_SUCCESS,
  payload: { data: conexao },
});

export const createConexaoFailed = (error) => ({
  type: CREATE_CONEXAO_FAIL,
  payload: error,
});
export const createConexaoPlanExceeded = () => ({
  type: CREATE_CONEXAO_PLAN_EXCEEDED,
});
export const createConexaoExit = () => ({
  type: CREATE_CONEXAO_EXIT,
});

export const getConexao = (instance) => ({
  type: GET_CONNECT_CONEXAO,
  payload: { data: instance },
});

export const getConexaoFetch = (instance) => ({
  type: GET_CONNECT_CONEXAO_FETCH,
  payload: { data: { instance } },
});

export const getConexaoFailed = (error) => ({
  type: GET_CONNECT_CONEXAO_FAIL,
  payload: error,
});
export const getConexaoSuccess = (qrcode) => ({
  type: GET_CONNECT_CONEXAO_SUCCESS,
  payload: { data: qrcode },
});
export const getConexaoExit = () => ({
  type: GET_CONNECT_CONEXAO_EXIT,
});

export const getConexoes = () => ({
  type: GET_CONEXOES,
});
export const getConexoesSuccessful = (response) => ({
  type: GET_CONEXOES_SUCCESS,
  payload: { data: response },
});

export const getConexoesFailed = () => ({
  type: GET_CONEXOES_FAIL,
});

export const logoutConexao = (conexao) => ({
  type: LOGOUT_CONEXAO,
  payload: { data: conexao },
});

export const logoutConexaoFetch = (name) => ({
  type: LOGOUT_CONEXAO_FETCH,
  payload: { name },
});

export const logoutConexaoExit = () => ({
  type: LOGOUT_CONEXAO_EXIT,
});
export const logoutConexaosSuccessful = () => ({
  type: LOGOUT_CONEXAO_SUCCESS,
});

export const logoutConexaoFailed = (error) => ({
  type: GET_CONEXOES_FAIL,
  payload: { error },
});
