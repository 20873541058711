import {
    CREATE_CONEXAO,
    CREATE_CONEXAO_EXIT,
    CREATE_CONEXAO_FAIL,
    CREATE_CONEXAO_PLAN_EXCEEDED,
    CREATE_CONEXAO_SUCCESS,
    GET_CONEXOES,
    GET_CONEXOES_FAIL,
    GET_CONEXOES_SUCCESS,
    GET_CONNECT_CONEXAO,
    GET_CONNECT_CONEXAO_EXIT,
    GET_CONNECT_CONEXAO_FAIL,
    GET_CONNECT_CONEXAO_FETCH,
    GET_CONNECT_CONEXAO_SUCCESS,
    LOGOUT_CONEXAO,
    LOGOUT_CONEXAO_EXIT,
    LOGOUT_CONEXAO_FAIL,
    LOGOUT_CONEXAO_FETCH,
    LOGOUT_CONEXAO_SUCCESS,
} from "./actionTypes";

const initialState = {
    loading: false,
    loadingLogout: false,
    loadingQrcode: true,
    conexao: null,
    conexaoLogout: null,
    qrCodeConnect: null,
    conexaoConnect: null,
    error: false,
    conexoes: [],
    loadingConexao: false,
    isOpenModalConexao: false,
    isOpenModalConnect: false,
    isOpenAlertUpgrade: false,
    isOpenAlertLogout: false,
    isOpenAlertLogoutSuccess: false,
};

const conexao = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_CONEXAO:
            return {...state, loadingConexao: true, conexao: null};
        case CREATE_CONEXAO_SUCCESS:
            return {
                ...state,
                loadingConexao: true,
                conexao: action.payload.data,
                isOpenModalConexao: true,
            };
        case CREATE_CONEXAO_FAIL:
            console.error(action.payload.error);
            return {
                ...state,
                loadingConexao: false,
                conexao: null,
                error: true,
                isOpenModalConexao: false,
            };
        case CREATE_CONEXAO_PLAN_EXCEEDED:
            return {
                ...state,
                loadingConexao: true,
                conexao: null,
                isOpenAlertUpgrade: true,
            };
        case CREATE_CONEXAO_EXIT:
            return {
                ...state,
                loadingConexao: false,
                conexao: null,
                isOpenModalConexao: false,
            };
        case GET_CONNECT_CONEXAO:
            return {
                ...state,
                loadingQrcode: true,
                isOpenModalConnect: true,
                conexaoConnect: action.payload.data,
            };
        case GET_CONNECT_CONEXAO_FETCH:
            return {...state, loadingQrcode: true};
        case GET_CONNECT_CONEXAO_SUCCESS:
            return {
                ...state,
                qrCodeConnect: action.payload.data,
                loadingQrcode: false,
            };
        case GET_CONNECT_CONEXAO_FAIL:
            return {
                ...state,
                qrCodeConnect: null,
                loadingQrcode: false,
                isOpenModalConnect: false,
                error: true,
                conexaoConnect: null,
            };
        case GET_CONNECT_CONEXAO_EXIT:
            return {
                ...state,
                qrCodeConnect: null,
                loadingConnect: false,
                conexaoConnect: null,
                isOpenModalConnect: false,
            };
        case GET_CONEXOES:
            return {...state, loading: true};
        case GET_CONEXOES_SUCCESS:
            return {
                ...state,
                conexoes: action.payload.data,
                loading: false,
                error: false,
            };
        case GET_CONEXOES_FAIL:
            return {...state, conexoes: [], loading: false, error: true};
        case LOGOUT_CONEXAO:
            return {
                ...state,
                conexaoLogout: action.payload.data,
                isOpenAlertLogout: true,
            };
        case LOGOUT_CONEXAO_EXIT:
            return {...state, conexaoLogout: null, isOpenAlertLogout: false};
        case LOGOUT_CONEXAO_FETCH:
            return {...state, loadingLogout: true};
        case LOGOUT_CONEXAO_SUCCESS:
            return {
                ...state,
                conexaoLogout: null,
                isOpenAlertLogoutSuccess: true,
                isOpenAlertLogout: false,
            };
        case LOGOUT_CONEXAO_FAIL:
            console.error(action.payload.error);
            return {
                ...state,
                conexaoLogout: null,
                isOpenAlertLogout: false,
                error: true,
            };
        default:
            return state;
    }
};

export default conexao;
