import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Col, FormGroup, Input, Label} from "reactstrap";
import React, {useEffect, useState} from "react";

import * as MensagensActions from "../../../../../store/mensagens/actions";
import * as AgendamentoActions from "../../../../../store/agendamento/actions";
import {camposAgendamentos} from "../validation/fields";

function convertData(campanhas) {
    return campanhas.map((campanha) => {
        return <option key={campanha.id} value={campanha.id}>{campanha.titulo}</option>;
    });
}

const MensagemSelect = (props) => {
    const [data, setData] = useState([]);
    const handleChangeMensagem = (event) => {
        const mensagem = props.mensagensState.mensagens.find(x => x.id === event.target.value);
        props.editingAgendamentos(event.target.value === '0' ? null : mensagem, camposAgendamentos.MENSAGEM)
    }
    useEffect(() => {
        props.getMensagens();
    }, []);

    useEffect(() => {
        setData(convertData(props.mensagensState.mensagens));
    }, [props.mensagensState.mensagens]);

    if (data.length === 0) {
        return null;
    }
    return (
        <FormGroup row>
            <Label for="mensagem-select" sm={3}>
                {props.t("Message")}
            </Label>
            <Col sm={9}>
                <Input type='select' name='mensagem-select' id='mensagem-select' onChange={handleChangeMensagem}
                       value={props.agendamentosState?.agendamento?.mensagem?.id || 0}
                       invalid={props.agendamentosState?.errors?.mensagem}
                       valid={props.agendamentosState?.agendamento?.mensagem}>
                    <option value={0}>Selecione a mensagem</option>
                    {data}
                </Input>
            </Col>
        </FormGroup>
    );
};

const mapStatetoProps = (state) => ({mensagensState: state.mensagens, agendamentosState: state.agendamentos});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators({...MensagensActions, ...AgendamentoActions}, dispatch);

MensagemSelect.propTypes = {
    t: PropTypes.any,
    mensagensState: PropTypes.object,
    getMensagens: PropTypes.func,
    editingAgendamentos: PropTypes.func,
};
export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(MensagemSelect));
