import {Col, FormGroup, Label} from "reactstrap";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as MensagensActions from "../../../../../../store/mensagens/actions";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Select from "react-select";
import {TIPO_OPTIONS} from "../tipoOptions";
import {camposMensagens} from "../fields";

const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
};
const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
};

const formatGroupLabel = (data) => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);

const TipoSelect = (props) => {
    // useEffect(() => {
    //     props.createMensagensSet(props.t("WA Text"), camposMensagens.TIPO);
    // }, []);
    return (
        <FormGroup row>
            <Label for="tipo-select" sm={3}>
                {props.t("WA Message Type")}
            </Label>
            <Col sm={9}>
                <Select
                    className='border border-secondary'
                    value={{
                        value: props.mensagensState.mensagem[camposMensagens.TIPO],
                        label: props.mensagensState.mensagem[camposMensagens.TIPO]
                    }}
                    options={TIPO_OPTIONS}
                    formatGroupLabel={formatGroupLabel}
                    onChange={(e) => props.createMensagensSet(e.value, camposMensagens.TIPO)}
                />
            </Col>
        </FormGroup>
    );
};
TipoSelect.propTypes = {
    t: PropTypes.any,
    createMensagensSet: PropTypes.func,
};
const mapStatetoProps = (state) => ({mensagensState: state.mensagens});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators(MensagensActions, dispatch);

export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(TipoSelect));
