import {
    CREATE_CAMPANHA,
    CREATE_CAMPANHA_EXIT,
    CREATE_CAMPANHA_FAIL,
    CREATE_CAMPANHA_FETCH,
    CREATE_CAMPANHA_SET,
    CREATE_CAMPANHA_SUCCESS,
    DELETAR_CAMPANHA,
    DELETE_GRUPO,
    EDITAR_CAMPANHA,
    EDITAR_CAMPANHA_FETCH,
    EDITAR_CLIQUES_GRUPO,
    EDITAR_CLIQUES_GRUPO_EXIT,
    EDITAR_CLIQUES_GRUPO_FAIL,
    EDITAR_CLIQUES_GRUPO_FETCH,
    EDITAR_CLIQUES_GRUPO_SUCCESS,
    GET_CAMPANHA,
    GET_CAMPANHAS,
    GET_CAMPANHAS_FAIL,
    GET_CAMPANHAS_SUCCESS,
    GET_GRUPOS,
    GET_GRUPOS_FETCH,
    IMPORTAR_GRUPOS,
    IMPORTAR_GRUPOS_EXIT,
    IMPORTAR_GRUPOS_FAIL,
    IMPORTAR_GRUPOS_FETCH,
    IMPORTAR_GRUPOS_SUCCESS,
} from "./actionTypes";

export const getCampanhas = () => ({
    type: GET_CAMPANHAS,
});
export const getCampanhasSuccessful = (response) => ({
    type: GET_CAMPANHAS_SUCCESS,
    payload: {data: response},
});

export const getCampanhasFailed = (error) => ({
    type: GET_CAMPANHAS_FAIL,
    error: error,
});

export const createCampanha = () => ({
    type: CREATE_CAMPANHA,
});
export const createCampanhaSuccessful = (response) => ({
    type: CREATE_CAMPANHA_SUCCESS,
    payload: {data: response},
});

export const createCampanhaFailed = (error) => ({
    type: CREATE_CAMPANHA_FAIL,
    payload: {error},
});

export const createCampanhaExit = () => ({
    type: CREATE_CAMPANHA_EXIT,
});

export const createCampanhaFetch = (data) => ({
    type: CREATE_CAMPANHA_FETCH,
    payload: {data},
});
export const createCampanhaSet = (data, field) => ({
    type: CREATE_CAMPANHA_SET,
    payload: {data, field},
});

export const editarCampanha = (data) => ({
    type: EDITAR_CAMPANHA,
    payload: {data},
});

export const deletarCampanha = (data) => ({
    type: DELETAR_CAMPANHA,
    payload: {data},
});

export const editarCampanhaFetch = (data, oldData) => ({
    type: EDITAR_CAMPANHA_FETCH,
    payload: {data, oldData},
});

export const getCampanha = (idCampanha) => ({
    type: GET_CAMPANHA,
    payload: {data: idCampanha},
});

export const importarGrupos = () => ({
    type: IMPORTAR_GRUPOS,
});

export const importarGruposExit = () => ({
    type: IMPORTAR_GRUPOS_EXIT,
});

export const importarGruposFetch = (campanha, gruposEscolhidos, instancia) => ({
    type: IMPORTAR_GRUPOS_FETCH,
    payload: {data: {campanha, grupos: gruposEscolhidos, instancia}},
});

export const importarGruposFail = () => ({
    type: IMPORTAR_GRUPOS_FAIL,
});

export const importarGruposSuccess = () => ({
    type: IMPORTAR_GRUPOS_SUCCESS,
});

export const getGrupos = (campanha) => ({
    type: GET_GRUPOS,
    payload: {data: campanha},
});

export const deleteGrupo = (idCampanha, grupo) => ({
    type: DELETE_GRUPO,
    payload: {
        data: {grupo, idCampanha},
    }
});

export const getGruposFetch = (grupos) => ({
    type: GET_GRUPOS_FETCH,
    payload: {data: grupos}
});


export const editarCliqueGrupo = () => ({
    type: EDITAR_CLIQUES_GRUPO,
});

export const editarCliqueGrupoExit = () => ({
    type: EDITAR_CLIQUES_GRUPO_EXIT,
});

export const editarCliqueGrupoFetch = (campanha, grupo) => ({
    type: EDITAR_CLIQUES_GRUPO_FETCH,
    payload: {data: {campanha, grupo}},
});

export const editarCliqueGrupoFail = () => ({
    type: EDITAR_CLIQUES_GRUPO_FAIL,
});

export const editarCliqueGrupoSuccess = () => ({
    type: EDITAR_CLIQUES_GRUPO_SUCCESS,
});
