import {Card, CardBody, Col, Row} from "reactstrap";
import ReactApexChart from "react-apexcharts";
import React from "react";

const series1 = [81];

const options1 = {
    plotOptions: {
        radialBar: {
            offsetY: -12,
            hollow: {
                margin: 5,
                size: "60%",
                background: "rgba(69, 203, 133, .25)",
            },
            dataLabels: {
                name: {
                    show: false,
                },
                value: {
                    show: true,
                    fontSize: "12px",
                    offsetY: 5,
                },
                style: {
                    colors: ["#fff"],
                },
            },
        },
    },
    colors: ["#45CB85"],
};
const QtdConversoesCard = (props) => {
    return (
        <Card>
            <CardBody>
                <Row>
                    <Col xs={8}>
                        <div>
                            <h3 className="text-muted fw-medium mt-1 mb-2">Conversões</h3>
                            <h4>R$ 32.695</h4>
                        </div>
                    </Col>

                    <Col xs={4}>
                        <div>
                            <ReactApexChart
                                options={options1}
                                series={series1}
                                type="radialBar"
                                height="120"
                            />
                        </div>
                    </Col>
                </Row>

                <p className="mb-0">
          <span className="badge badge-soft-success me-2">
            {" "}
              0.6% <i className="mdi mdi-arrow-up"></i>{" "}
          </span>{" "}
                    Do dia anterio
                </p>
            </CardBody>
        </Card>
    );
};

export default QtdConversoesCard;
