import React, { useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

//Import Images
import errorImage from "../../assets/images/error-img.png";

const Pages404 = () => {
  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  return (
    <React.Fragment>
      <div className="account-pages my-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody>
                  <div className="text-center p-3">
                    <div className="img">
                      <img src={errorImage} className="img-fluid" alt="" />
                    </div>

                    <h1 className="error-page mt-5">
                      <span>404!</span>
                    </h1>
                    <h4 className="mb-4 mt-5">
                      Desulpe, a página não foi encontrada
                    </h4>
                    <p className="mb-4 w-75 mx-auto">
                      Parece que a página que você está procurando não está
                      disponível no momento. Mas não se preocupe, estamos aqui
                      para ajudar!
                    </p>
                    {/*<h4>Por Que Isso Aconteceu?</h4>*/}
                    {/*<p className="mb-4 w-75 mx-auto">A página que você está tentando acessar pode*/}
                    {/*    ter sido movida, renomeada ou*/}
                    {/*    está temporariamente indisponível.</p>*/}
                    <a
                      className="btn btn-primary mb-4 waves-effect waves-light"
                      href="/"
                    >
                      <i className="mdi mdi-home"></i> Voltar a página inicial
                    </a>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Pages404;
