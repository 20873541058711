import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as MensagensActions from "../../../../../../store/mensagens/actions";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { AvField } from "availity-reactstrap-validation";
import { camposMensagens } from "../fields";

const NomeEnquete = (props) => {
  const [showPicker, setShowPicker] = useState(false);
  const [mensagem, setMensagem] = useState(
    props.mensagensState.mensagem[camposMensagens.ENQUETE_NOME],
  );

  const handleChange = (event) => {
    setMensagem(event.target.value);
  };

  const handleEmojiClick = (emojiObject) => {
    setMensagem((prevInput) => prevInput + emojiObject.native);
    setShowPicker(false);
  };

  useEffect(() => {
    props.createMensagensSet(mensagem, camposMensagens.ENQUETE_NOME);
  }, []);
  return (
    <FormGroup row>
      <Label for="nome-enquete" sm={3}>
        {props.t("WA Poll Question")}
      </Label>
      <Col sm={9}>
        <Row>
          <Col sm={10}>
            <AvField
              id="nome-enquete"
              name="Nome da Enquete"
              type={"text"}
              value={mensagem}
              errorMessage="Digite o Nome da Enquete"
              className="form-control border border-secondary"
              validate={{ required: { value: true } }}
              onChange={handleChange}
            />
          </Col>
          <Col sm={2}>
            <Button
              type="button"
              className="btn btn-primary waves-effect waves-light"
              onClick={() => setShowPicker((val) => !val)}
            >
              <i className="bx bx-smile font-size-16 align-middle me-2"></i>
            </Button>
          </Col>
        </Row>
        {showPicker && (
          <Picker
            data={data}
            onEmojiSelect={handleEmojiClick}
            previewPosition="none"
            searchPosition="none"
            locale="pt"
            dynamicWidth={true}
          />
        )}
      </Col>
    </FormGroup>
  );
};
NomeEnquete.propTypes = {
  t: PropTypes.any,
  createMensagensSet: PropTypes.func,
};
const mapStatetoProps = (state) => ({ mensagensState: state.mensagens });
const mapDispatchtoProps = (dispatch) =>
  bindActionCreators(MensagensActions, dispatch);

export default connect(
  mapStatetoProps,
  mapDispatchtoProps,
)(withTranslation()(NomeEnquete));
