import {Col, FormGroup, Input, Label,} from "reactstrap";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as MensagensActions from "../../../../../../store/mensagens/actions";
import {connect} from "react-redux";
import React from "react";

const ArquivoInputView = (props) => {

    return (
        <FormGroup row>
            <Label for="document-input" sm={3}>
                {props.t("WA Video")}
            </Label>
            <Col sm={9}>
                <Col sm={9}>
                    <Input
                        id="document-input"
                        name="document"
                        type="file"
                        className="form-control border border-secondary"
                    />
                </Col>
            </Col>
        </FormGroup>
    );
};
ArquivoInputView.propTypes = {
    t: PropTypes.any,
    createMensagensSet: PropTypes.func,
};
const mapStatetoProps = (state) => ({mensagensState: state.mensagens});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators(MensagensActions, dispatch);

export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(ArquivoInputView));
