import {Col} from "reactstrap";
import CampanhaSelect from "./fields/CampanhaSelect";
import ConexaoSelect from "./fields/ConexaoSelect";
import DataTempoSelect from "./fields/DataTempoSelect";
import AgendarButton from "./fields/AgendarButton";
import PropTypes, {object} from "prop-types";
import {withTranslation} from "react-i18next";
import ContatosInputs from "./fields/ContatosInputs";
import {fieldsAgendamentoClean} from "./validation/fields";
import {TIPO_ADD_ADMINISTRADORES} from "./validation/tipoOptions";
import * as validation from "./validation/validate";
import {transformAddAdminisradores} from "./validation/transform";
import {bindActionCreators} from "redux";
import * as AgendamentosActions from "../../../../store/agendamento/actions";
import {connect} from "react-redux";

const AddAdministradores = (props) => {
    const handleAddAdministradores = () => {
        const clean = fieldsAgendamentoClean({...props.agendamentosState.agendamento, tipo: TIPO_ADD_ADMINISTRADORES});
        const valid = validation.validateMensagem(clean);
        if (valid.isValid) {
            props.createAgendamentoErrors({})
            const mensagem = transformAddAdminisradores(clean);
            props.createAgendamento(mensagem)
        } else {
            props.createAgendamentoErrors(valid.errors)
        }
    }
    return (
        <Col md={6} className='offset-md-3'>
            <CampanhaSelect/>
            <ConexaoSelect/>
            <ContatosInputs/>
            <DataTempoSelect/>
            <AgendarButton handleAgendar={handleAddAdministradores}/>
        </Col>
    )
}
const mapStatetoProps = (state) => ({agendamentosState: state.agendamentos, campanhasState: state.campanhas});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators(AgendamentosActions, dispatch);

AddAdministradores.propTypes = {
    t: PropTypes.any,
    agendamentosState: object,
    createAgendamento: PropTypes.func,
    createAgendamentoErrors: PropTypes.func
};
export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(AddAdministradores));
