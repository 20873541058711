import {Card, CardBody, CardTitle, Col, Row,} from "reactstrap";
import {MDBBtn, MDBDataTable, MDBIcon} from "mdbreact";
import React from "react";
import {bindActionCreators} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import * as MensagensActions from "../../../../store/mensagens/actions";


const MensagensTable = (props) => {

    function transformData(rows) {
        return rows.map((row) => ({
            ...row,
            actions: (
                <>
                    <MDBBtn
                        className="m-1"
                        tag="a"
                        color="primary"
                        disabled={false}
                        onClick={() => props.editarMensagem(row)}
                    >
                        <MDBIcon far icon="edit"/> Editar
                    </MDBBtn>
                    <MDBBtn
                        className="m-1"
                        tag="a"
                        color="secondary"
                        onClick={() => props.duplicarMensagem(row)}
                    >
                        <MDBIcon far icon="clone"/> Duplicar
                    </MDBBtn>
                    <MDBBtn
                        className="m-1"
                        tag="a"
                        color="success"
                        onClick={() => props.enviarMensagens(row)}
                    >
                        <MDBIcon far icon="paper-plane"/> Enviar
                    </MDBBtn>
                    <MDBBtn
                        className="m-1"
                        color="danger"
                        tag="a"
                        onClick={() => props.removerMensagem(row)}
                    >
                        <MDBIcon far icon="trash-alt"/> Remover
                    </MDBBtn>
                </>
            ),
        }));
    }

    const data = {
        columns: [
            {
                label: "Id",
                field: "id",
                responsive: true,
                width: 20,
                maxWidth: 20,
            },
            {
                label: "Título",
                field: "titulo",
                sort: "asc",
                responsive: true,
                width: 270,
            },
            {
                label: "Tipo",
                field: "tipo",
                sort: "asc",
                responsive: true,
                width: 200,
            },
            {
                label: "Ações",
                field: "actions",
                responsive: true,
                maxWidth: 100,
                center: true,
            },
        ],
        rows: transformData(props.mensagensState.mensagens),
    };

    const getDataToExport = () => {
        return data.rows.map(({id, titulo, tipo}) => ({id, titulo, tipo}))
    }

    if (props.mensagensState.mensagens.length === 0) {
        return null;
    }

    return (
        <Row>
            <Col className="col-12">
                <Card>
                    <CardBody>
                        <CardTitle>{props.t("Messages")} </CardTitle>
                        <MDBDataTable
                            className={"datatable-buttons_wrapper"}
                            responsive
                            striped
                            bordered
                            data={data}
                            searchLabel={props.t("Search")}
                            paginationLabel={[props.t("Previous"), props.t("Next")]}
                            entriesLabel={props.t("Entries Label")}
                            infoLabel={[
                                props.t("Showing"),
                                props.t("To"),
                                props.t("Of"),
                                props.t("Entries"),
                            ]}
                            noRecordsFoundLabel={props.t("No Records Found")}
                            noBottomColumns
                            exportToCSV={true}
                            btn
                            id="datatable"
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

MensagensTable.propTypes = {
    t: PropTypes.any,
    mensagensState: PropTypes.any,
    removerMensagem: PropTypes.func,
    editarMensagem: PropTypes.func,
    enviarMensagens: PropTypes.func,
    duplicarMensagem: PropTypes.func
};
const mapStatetoProps = (state) => ({mensagensState: state.mensagens});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators(MensagensActions, dispatch);

export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(MensagensTable));
