import {
    TIPO_MIDIA_ARQUIVO,
    TIPO_MIDIA_AUDIO,
    TIPO_MIDIA_IMAGEM,
    TIPO_MIDIA_VIDEO,
    TIPO_TEXTO_ENQUETE,
    TIPO_TEXTO_LINK,
    TIPO_TEXTO_TEXTO,
} from "./tipoOptions";

const TIPO = "tipo";
const TITULO = "titulo";
const MENSAGEM = "mensagem";
const MENCAO = "mencao";
const ARQUIVO = "arquivo";
const NOME_ARQUIVO = "nomeArquivo";
const AUDIO = "audio";
const ENQUETE_OPCOES = "opcoesEnquete";
const IMAGEM = "imagem";
const ENQUETE_NOME = "nomeEnquete";
const VIDEO = "video";
const ID = "id";
const LINK = "link";

export const camposMensagens = {
    TIPO,
    ENQUETE_NOME,
    IMAGEM,
    ENQUETE_OPCOES,
    VIDEO,
    TITULO,
    MENSAGEM,
    MENCAO,
    ARQUIVO,
    NOME_ARQUIVO,
    AUDIO,
    ID,
    LINK
};

export const fieldsMensagemClean = (fields) => {
    switch (fields.tipo) {
        case TIPO_TEXTO_TEXTO:
            return Object.fromEntries(
                [TIPO, TITULO, MENSAGEM, MENCAO].map((key) => [key, fields[key]]),
            );
        case TIPO_TEXTO_ENQUETE:
            return Object.fromEntries(
                [TIPO, TITULO, MENCAO, ENQUETE_NOME, ENQUETE_OPCOES].map((key) => [
                    key,
                    fields[key],
                ]),
            );
        case TIPO_TEXTO_LINK:
            return fields.mensagem === "" || fields.mensagem === undefined
                ? Object.fromEntries(
                    [TIPO, TITULO, MENCAO, LINK].map((key) => [key, fields[key]]),
                )
                : Object.fromEntries(
                    [TIPO, TITULO, MENCAO, LINK, MENSAGEM].map((key) => [
                        key,
                        fields[key],
                    ]),
                );
        case TIPO_MIDIA_ARQUIVO:
            return fields.mensagem === "" || fields.mensagem === undefined
                ? Object.fromEntries(
                    [TIPO, TITULO, MENCAO, ARQUIVO, NOME_ARQUIVO].map((key) => [key, fields[key]]),
                )
                : Object.fromEntries(
                    [TIPO, TITULO, MENCAO, ARQUIVO, MENSAGEM, NOME_ARQUIVO].map((key) => [
                        key,
                        fields[key],
                    ]),
                );
        case TIPO_MIDIA_AUDIO:
            return fields.mensagem === "" || fields.mensagem === undefined
                ? Object.fromEntries(
                    [TIPO, TITULO, MENCAO, AUDIO].map((key) => [key, fields[key]]),
                )
                : Object.fromEntries(
                    [TIPO, TITULO, MENCAO, AUDIO, MENSAGEM].map((key) => [
                        key,
                        fields[key],
                    ]),
                );
        case TIPO_MIDIA_IMAGEM:
            return fields.mensagem === "" || fields.mensagem === undefined
                ? Object.fromEntries(
                    [TIPO, TITULO, MENCAO, IMAGEM].map((key) => [key, fields[key]]),
                )
                : Object.fromEntries(
                    [TIPO, TITULO, MENCAO, IMAGEM, MENSAGEM].map((key) => [
                        key,
                        fields[key],
                    ]),
                );
        case TIPO_MIDIA_VIDEO:
            return fields.mensagem === "" || fields.mensagem === undefined
                ? Object.fromEntries(
                    [TIPO, TITULO, MENCAO, VIDEO].map((key) => [key, fields[key]]),
                )
                : Object.fromEntries(
                    [TIPO, TITULO, MENCAO, VIDEO, MENSAGEM].map((key) => [
                        key,
                        fields[key],
                    ]),
                );
        default:
            return {};
    }
};
