import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Col, FormGroup, Label} from "reactstrap";
import React, {useEffect, useState} from "react";
import {getStatus, OFF, ON} from "../../../../../../api/evolution/status";
import {getPhone} from "../../../../../../util/phone";
import chroma from "chroma-js";
import Select from "react-select";
import * as CampanhasActions from "../../../../../../store/campanhas/actions";
import * as ConexoesActions from "../../../../../../store/conexoes/actions";
import {camposCampanhas} from "./fields";

function convertData(conexoes) {
    return conexoes.map((conexao) => {
        const status = getStatus(conexao.connectionStatus);
        if (status === ON) {
            return {
                value: conexao.name,
                label: `${getPhone(conexao.ownerJid)}`,
                color: "#36B37E",
                isDisabled: false,
            };
        }

        if (status === OFF) {
            return {
                value: conexao.name,
                label: `${conexao.name} (${status})`,
                color: "#FF5630",
                isDisabled: true,
            };
        }
    });
}

const dot = (color = "transparent") => ({
    alignItems: "center",
    display: "flex",

    ":before": {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: "block",
        marginRight: 8,
        height: 10,
        width: 10,
    },
});

const colourStyles = {
    control: (styles) => ({...styles, backgroundColor: "white"}),
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? data.color
                    : isFocused
                        ? color.alpha(0.1).css()
                        : undefined,
            color: isDisabled
                ? "#ccc"
                : isSelected
                    ? chroma.contrast(color, "white") > 2
                        ? "white"
                        : "black"
                    : data.color,
            cursor: isDisabled ? "not-allowed" : "default",

            ":active": {
                ...styles[":active"],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? data.color
                        : color.alpha(0.3).css()
                    : undefined,
            },
        };
    },
    input: (styles) => ({...styles, ...dot()}),
    placeholder: (styles) => ({...styles, ...dot("#ccc")}),
    singleValue: (styles, {data}) => ({...styles, ...dot(data.color)}),
};

const ConexaoCampanhaSelect = (props) => {
    const [data, setData] = useState([]);
    const handleConexao = (e) => {
        if (e.isDisabled) {
            props.createCampanhaFailed({[camposCampanhas.CONEXAO]: true});
        } else {
            props.createCampanhaSet(e.value, camposCampanhas.CONEXAO);
        }
    };

    useEffect(() => {
        setData(convertData(props.conexoesState.conexoes));
    }, []);

    if (data.length === 0) {
        return null;
    }
    return (
        <FormGroup row>
            <Label for="conexoes-select" sm={3}>
                {props.t("WA Connections")}
            </Label>
            <Col sm={9}>
                <Select
                    options={data}
                    styles={colourStyles}
                    placeholder="Selecione uma conexão"
                    noOptionsMessage={"Você não tem conexões"}
                    form
                    value={data?.find(
                        (conexao) =>
                            conexao.value === props.campanhasState.campanha.conexao,
                    )}
                    aria-errormessage="feedbackform"
                    aria-invalid={props.campanhasState.campanhaFormError.conexao}
                    onChange={handleConexao}
                />
            </Col>
        </FormGroup>
    );
};

const mapStatetoProps = (state) => ({
    campanhasState: state.campanhas,
    conexoesState: state.conexoes,
});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators({...CampanhasActions, ...ConexoesActions}, dispatch);

ConexaoCampanhaSelect.propTypes = {
    t: PropTypes.any,
    campanhasState: PropTypes.object,
    conexoesState: PropTypes.object,
    createCampanhaSet: PropTypes.func,
    createCampanhaFailed: PropTypes.func,
};
export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(ConexaoCampanhaSelect));
