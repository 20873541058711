import { Col, FormGroup, Label } from "reactstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as MensagensActions from "../../../../../../store/mensagens/actions";
import { connect } from "react-redux";
import React from "react";
import { camposMensagens } from "../fields";

const VideoInputView = (props) => {
  return (
    <FormGroup row>
      <Label for="video-input" sm={3}>
        {props.t("WA Video")}
      </Label>
      <Col sm={9}>
        <video width="100" height="100" controls>
          <track kind="captions" {...props} />
          <source src={props.mensagensState.mensagem[camposMensagens.VIDEO]} />
        </video>
      </Col>
    </FormGroup>
  );
};
VideoInputView.propTypes = {
  t: PropTypes.any,
  createMensagensSet: PropTypes.func,
};
const mapStatetoProps = (state) => ({ mensagensState: state.mensagens });
const mapDispatchtoProps = (dispatch) =>
  bindActionCreators(MensagensActions, dispatch);

export default connect(
  mapStatetoProps,
  mapDispatchtoProps,
)(withTranslation()(VideoInputView));
