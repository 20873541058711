import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Row, Spinner } from "reactstrap";
import PropTypes, { object } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as AgendamentosActions from "../../../store/agendamento/actions";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import CardAgendamentoDescription from "./components/card/CardAgendamentoDescription";
import CardHeader from "./components/card/CardHeader";
import VisualizarMensagemModal from "../Mensagens/modal/VisualizarMensagemModal";

const VisualizacaoAgendamentos = (props) => {
  const endAgendamento =
    !props.agendamentosState.isLoadingAgendamentos &&
    props.agendamentosState.agendamentos !== null;

  useEffect(() => {
    props.getAgendamentos();
  }, []);

  return (
    <div className="page-content">
      <Row>
        <Breadcrumb
          title={props.t("Schedules")}
          breadcrumbItem={props.t("Schedules")}
        />
      </Row>
      {props.agendamentosState.isLoadingAgendamentos && (
        <Spinner type="grow" className="m-auto text-center" color="primary" />
      )}
      <CardHeader />
      {endAgendamento &&
        props.agendamentosState?.agendamentos?.map((agendamento) => (
          <CardAgendamentoDescription
            key={agendamento.id}
            agendamento={agendamento}
          />
        ))}
      <VisualizarMensagemModal />
    </div>
  );
};

const mapStatetoProps = (state) => ({
  agendamentosState: state.agendamentos,
  campanhasState: state.campanhas,
});
const mapDispatchtoProps = (dispatch) =>
  bindActionCreators(AgendamentosActions, dispatch);

VisualizacaoAgendamentos.propTypes = {
  t: PropTypes.any,
  agendamentosState: object,
  getAgendamentos: PropTypes.func,
  createAgendamentoErrors: PropTypes.func,
};
export default connect(
  mapStatetoProps,
  mapDispatchtoProps,
)(withTranslation()(VisualizacaoAgendamentos));
