function validateCampanha(fields) {
  const errors = {};

  for (const [key, value] of Object.entries(fields)) {
    if (
      key !== "imagem" &&
      key !== "id" &&
      key !== "link" &&
      (value === "" || value === null || value === undefined || value < 0)
    ) {
      errors[key] = true;
    }
  }

  if (Object.entries(errors).length > 0) {
    return {
      isValid: false,
      errors,
    };
  }

  return {
    isValid: true,
  };
}

export { validateCampanha };
