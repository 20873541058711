import React from "react";

export const Offsymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#ffffff",
                paddingRight: 2,
            }}
        >
            {" "}
            Não
        </div>
    );
};

export const OnSymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#ffffff",
                paddingRight: 2,
            }}
        >
            {" "}
            Sim
        </div>
    );
};