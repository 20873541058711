import {all, fork} from 'redux-saga/effects';

//public
import AccountSaga from './auth/register/saga';
import AuthSaga from './auth/login/saga';
import ForgetSaga from './auth/forgetpwd/saga';
import ProfileSaga from './auth/profile/saga';
import LayoutSaga from './layout/saga';
import ConexaoSaga from './conexoes/saga';
import campanhaSaga from "./campanhas/saga";
import mensagenSaga from "./mensagens/saga";
import agendamentoSaga from "./agendamento/saga";

export default function* rootSaga() {
    yield all([
        //public
        AccountSaga(),
        fork(AuthSaga),
        ProfileSaga(),
        ForgetSaga(),
        fork(LayoutSaga),
        fork(ConexaoSaga),
        fork(mensagenSaga),
        fork(campanhaSaga),
        fork(agendamentoSaga),
    ]);
}
