import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as MensagensActions from "../../../../../../store/mensagens/actions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Col, FormGroup, Label } from "reactstrap";
import { useEffect, useState } from "react";
import { getStatus, OFF, ON } from "../../../../../../api/evolution/status";
import { getPhone } from "../../../../../../util/phone";
import chroma from "chroma-js";
import Select from "react-select";

function convertData(conexoes) {
  return conexoes.map((conexao) => {
    const status = getStatus(conexao.connectionStatus);
    if (status === ON) {
      return {
        value: conexao.name,
        label: `${conexao.name} (${getPhone(conexao.ownerJid)})`,
        color: "#36B37E",
        isDisabled: false,
      };
    }

    if (status === OFF) {
      return {
        value: conexao.name,
        label: `${conexao.name} (${status})`,
        color: "#FF5630",
        isDisabled: true,
      };
    }
  });
}

const dot = (color = "transparent") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? data.color
          : isFocused
            ? color.alpha(0.1).css()
            : undefined,
      color: isDisabled
        ? "#ccc"
        : isSelected
          ? chroma.contrast(color, "white") > 2
            ? "white"
            : "black"
          : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : color.alpha(0.3).css()
          : undefined,
      },
    };
  },
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles, ...dot("#ccc") }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

const ConexaoSelect = (props) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(convertData(props.conexoesState.conexoes));
  }, []);

  if (data.length === 0) {
    return null;
  }

  return (
    <FormGroup row>
      <Label for="conexoes-select" sm={3}>
        {props.t("WA Connections")}
      </Label>
      <Col sm={9}>
        <Select
          defaultValue={data[0]}
          options={data}
          styles={colourStyles}
          onChange={(e) => props.enviarMensagensConexaoSet(e.value)}
        />
      </Col>
    </FormGroup>
  );
};

ConexaoSelect.propTypes = {
  t: PropTypes.any,
  conexoesState: PropTypes.object,
  enviarMensagensConexaoSet: PropTypes.func,
};
const mapStatetoProps = (state) => ({ conexoesState: state.conexoes });
const mapDispatchtoProps = (dispatch) =>
  bindActionCreators(MensagensActions, dispatch);

export default connect(
  mapStatetoProps,
  mapDispatchtoProps,
)(withTranslation()(ConexaoSelect));
