import {
  CREATE_CAMPANHA,
  CREATE_CAMPANHA_EXIT,
  CREATE_CAMPANHA_FAIL,
  CREATE_CAMPANHA_FETCH,
  CREATE_CAMPANHA_SET,
  CREATE_CAMPANHA_SUCCESS,
  DELETAR_CAMPANHA,
  DELETE_GRUPO,
  EDITAR_CAMPANHA,
  EDITAR_CAMPANHA_FETCH,
  EDITAR_CLIQUES_GRUPO,
  EDITAR_CLIQUES_GRUPO_EXIT,
  EDITAR_CLIQUES_GRUPO_FAIL,
  EDITAR_CLIQUES_GRUPO_FETCH,
  EDITAR_CLIQUES_GRUPO_SUCCESS,
  GET_CAMPANHA,
  GET_CAMPANHAS,
  GET_CAMPANHAS_FAIL,
  GET_CAMPANHAS_SUCCESS,
  GET_GRUPOS,
  GET_GRUPOS_FETCH,
  IMPORTAR_GRUPOS,
  IMPORTAR_GRUPOS_EXIT,
  IMPORTAR_GRUPOS_FAIL,
  IMPORTAR_GRUPOS_FETCH,
  IMPORTAR_GRUPOS_SUCCESS,
} from "./actionTypes";

const initialCampanha = {
  nome: "",
  imagem: "",
  link: "",
  limitePessoas: 0,
  isLeadDuplicado: false,
  conexao: null,
  isCriarGrupo: false,
  isMensagemBoasVindas: false,
  nomeGrupo: "",
  descricaoGrupo: "",
  imagemGrupo: "",
  quantidadeGrupo: 0,
  iniciarNumeracaoGrupo: 0,
  administradoresGrupo: "",
  mensagemBoasVindas: "",
  anexoBoasVindas: "",
  titulo: "",
};

const initialCampanhaForm = {
  nome: false,
  imagem: false,
  link: false,
  limitePessoas: false,
  isLeadDuplicado: false,
  conexao: false,
  isCriarGrupo: false,
  isMensagemBoasVindas: false,
  nomeGrupo: false,
  descricaoGrupo: false,
  imagemGrupo: false,
  quantidadeGrupo: false,
  iniciarNumeracaoGrupo: false,
  administradoresGrupo: false,
  mensagemBoasVindas: false,
  anexoBoasVindas: false,
};
const initialState = {
  campanhas: [],
  campanha: initialCampanha,
  loagingCriarCampanha: false,
  loadingCampanhas: true,
  loadingImportarGrupos: false,
  loadingGrupos: true,
  grupos: [],
  isOpenModalCriarEditarCampanha: false,
  error: false,
  campanhaFormError: initialCampanhaForm,
  isOpenModalImportarGrupos: false,
  isOpenModalEditarCliques: false,
};

const campanhas = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAMPANHA:
      return {
        ...state,
        campanha: state.campanhas.find(
          (campanha) => campanha.id === action.payload.data,
        ),
        loadingGrupos: false,
      };
    case GET_CAMPANHAS:
      return { ...state, loadingCampanhas: true };
    case GET_CAMPANHAS_SUCCESS:
      return {
        ...state,
        loadingCampanhas: false,
        campanhas: action.payload.data,
      };
    case GET_CAMPANHAS_FAIL:
      return { ...state, loadingMensagens: false, error: action.payload.error };
    case CREATE_CAMPANHA:
      return {
        ...state,
        loagingCriarCampanha: true,
        isOpenModalCriarEditarCampanha: true,
        campanha: initialCampanha,
      };
    case CREATE_CAMPANHA_FETCH:
      return { ...state, loagingCriarCampanha: true };
    case CREATE_CAMPANHA_SUCCESS:
      return {
        ...state,
        loadingCampanhas: true,
        loagingCriarCampanha: false,
        isOpenModalCriarEditarCampanha: false,
        campanha: initialCampanha,
      };
    case CREATE_CAMPANHA_FAIL:
      return {
        ...state,
        error: true,
        campanhaFormError: { ...action.payload.error },
      };
    case CREATE_CAMPANHA_EXIT:
      return {
        ...state,
        loagingCriarCampanha: false,
        campanha: initialCampanha,
        isOpenModalCriarEditarCampanha: false,
        error: false,
      };
    case CREATE_CAMPANHA_SET: {
      const data = action.payload.data;
      const field = action.payload.field;
      return { ...state, campanha: { ...state.campanha, [field]: data } };
    }
    case EDITAR_CAMPANHA:
      return {
        ...state,
        loagingCriarCampanha: true,
        isOpenModalCriarEditarCampanha: true,
        campanha: action.payload.data,
      };
    case DELETAR_CAMPANHA:
      return {
        ...state,
      };
    case DELETE_GRUPO:
      return {
        ...state,
      };
    case EDITAR_CAMPANHA_FETCH:
      return { ...state, loagingCriarCampanha: true };
    case GET_GRUPOS:
      return { ...state, loadingGrupos: true };
    case GET_GRUPOS_FETCH:
      return { ...state, loadingGrupos: false, grupos: action.payload.data };
    case IMPORTAR_GRUPOS:
      return { ...state, isOpenModalImportarGrupos: true };
    case IMPORTAR_GRUPOS_EXIT:
    case IMPORTAR_GRUPOS_FAIL:
      return {
        ...state,
        isOpenModalImportarGrupos: false,
        loadingImportarGrupos: false,
      };
    case IMPORTAR_GRUPOS_FETCH:
      return {
        ...state,
        isOpenModalImportarGrupos: true,
        loadingImportarGrupos: true,
      };
    case IMPORTAR_GRUPOS_SUCCESS:
      return {
        ...state,
        isOpenModalImportarGrupos: false,
        loadingImportarGrupos: false,
        loadingGrupos: true,
      };
    case EDITAR_CLIQUES_GRUPO:
      return { ...state, isOpenModalEditarCliques: true };
    case EDITAR_CLIQUES_GRUPO_EXIT:
    case EDITAR_CLIQUES_GRUPO_FAIL:
      return {
        ...state,
        isOpenModalEditarCliques: false,
      };
    case EDITAR_CLIQUES_GRUPO_FETCH:
      return {
        ...state,
        isOpenModalEditarCliques: true,
      };
    case EDITAR_CLIQUES_GRUPO_SUCCESS:
      return {
        ...state,
        isOpenModalEditarCliques: false,
      };
    default:
      return state;
  }
};

export default campanhas;
