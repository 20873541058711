import {CloseButton, Col, FormGroup, Input, Label} from "reactstrap";
import React, {useState} from "react";
import {getBase64} from "../../../../../util/image";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {bindActionCreators} from "redux";
import * as AgendamentoActions from "../../../../../store/agendamento/actions";
import {connect} from "react-redux";
import {camposAgendamentos} from "../validation/fields";

const ImagemGrupoField = (props) => {
    const [img, setImg] = useState()

    function handleChangeImage(e) {
        try {
            if (e.target?.files[0].type.includes("image")) {
                const url = URL.createObjectURL(e.target.files[0]);
                getBase64(e.target.files[0]).then((baseUrl) => {
                    setImg(baseUrl)
                    props.editingAgendamentos(baseUrl, camposAgendamentos.IMAGEM_GRUPO);
                    return () => URL.revokeObjectURL(url);
                });
            } else {
                props.createAgendamentoErrors({[camposAgendamentos.IMAGEM_GRUPO]: true});
            }
        } catch (e) {
            console.error(e);
            props.createAgendamentoErrors({[camposAgendamentos.IMAGEM_GRUPO]: true});
        }
    }

    return (
        <FormGroup row>
            <Label for="campanha-select" sm={3}>
                {props.t("Campaign")}
            </Label>
            <Col sm={9}>
                <Input
                    id="imagem-grupo-input"
                    type={"file"}
                    className={"border border-secondary"}
                    onChange={handleChangeImage}
                    invalid={props.agendamentosState?.errors?.imagemGrupo}
                    accept={"image/*"}
                />
                {img &&
                    <>
                        <CloseButton
                            onClick={
                                () => {
                                    setImg(null)
                                    props.editingAgendamentos("", camposAgendamentos.IMAGEM_GRUPO)
                                }
                            }
                        />
                        <img
                            className="img-thumbnail rounded-circle avatar-xl"
                            alt="Imagem Grupo"
                            src={img}
                        />
                    </>

                }
            </Col>
        </FormGroup>
    )
}
const mapStatetoProps = (state) => ({agendamentosState: state.agendamentos});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators({...AgendamentoActions}, dispatch);

ImagemGrupoField.propTypes = {
    t: PropTypes.any,
    agendamentosState: PropTypes.object,
    editingAgendamentos: PropTypes.func,
};
export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(ImagemGrupoField));

