export const getStatus = (status) => {
    switch (status) {
        case "close":
        case "connecting":
            return "Desconectada";
        case "open":
            return "Conectada";
        default:
            return "Desconectada";
    }
};

export const getStatusColor = (status) => {
    switch (status) {
        case "close":
        case "connecting":
            return <span style={{color: "#f46a6a"}}>Desconectada</span>;
        case "open":
            return <span style={{color: "#34c38f"}}>Conectada</span>;
        default:
            return <span style={{color: "#f46a6a"}}>Desconectada</span>;
    }
};

export const ON = "Conectada";
export const OFF = "Desconectada";
export const CONECTADO = "open";
export const CONNECTING = "connecting";
export const CLOSE = "close";
