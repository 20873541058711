import {get, post} from "./requests";
import * as url from "./url";

const postCreateGroup = (instance, body) =>
    post(`${url.POST_CREATE_GROUP}/${instance}`, body);

const getFetchAllGroups = (instance) =>
    get(`${url.FETCH_ALL_GROUPS}/${instance}?getParticipants=false`);

const getFetchAllGroupsWithPaticipants = (instance) =>
    get(`${url.FETCH_ALL_GROUPS}/${instance}?getParticipants=true`);

const getGroupByJid = (instance, jid) =>
    get(`${url.GET_FIND_JID_GROUP}/${instance}?groupJid=${jid}`);

const getGroupByInviteCode = (instance, jid) =>
    get(`${url.GET_FIND_INVITE_GROUP}/${instance}?inviteCode=${jid}`);

const getGroupInvite = (instance, jid) =>
    get(`${url.GET_FETCH_INVITE_CODE}/${instance}?groupJid=${jid}`);

export {
    getGroupInvite,
    getFetchAllGroups,
    getFetchAllGroupsWithPaticipants,
    postCreateGroup,
    getGroupByJid,
    getGroupByInviteCode
};
