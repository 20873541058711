export const GET_CAMPANHA = "GET_CAMPANHA";
export const GET_CAMPANHAS = "GET_CAMPANHAS";
export const GET_CAMPANHAS_SUCCESS = "GET_CAMPANHAS_SUCCESS";
export const GET_CAMPANHAS_FAIL = "GET_CAMPANHAS_FAIL";
export const CREATE_CAMPANHA = "CREATE_CAMPANHA";
export const CREATE_CAMPANHA_FETCH = "CREATE_CAMPANHA_FETCH";
export const CREATE_CAMPANHA_SUCCESS = "CREATE_CAMPANHA_SUCCESS";
export const CREATE_CAMPANHA_FAIL = "CREATE_CAMPANHA_FAIL";
export const CREATE_CAMPANHA_EXIT = "CREATE_CAMPANHA_EXIT";
export const CREATE_CAMPANHA_SET = "CREATE_CAMPANHA_SET";
export const EDITAR_CAMPANHA = "EDITAR_CAMPANHA";
export const EDITAR_CAMPANHA_FETCH = "EDITAR_CAMPANHA_FETCH";
export const EDITAR_CAMPANHA_SUCCESS = "EDITAR_CAMPANHA_SUCCESS";
export const EDITAR_CAMPANHA_FAIL = "EDITAR_CAMPANHA_FAIL";
export const EDITAR_CAMPANHA_EXIT = "EDITAR_CAMPANHA_EXIT";
export const EDITAR_CAMPANHA_SET = "EDITAR_CAMPANHA_SET";
export const ENVIAR_CAMPANHAS = "ENVIAR_CAMPANHAS";
export const ENVIAR_CAMPANHAS_FETCH = "ENVIAR_CAMPANHAS_FETCH";
export const ENVIAR_CAMPANHAS_SUCCESS = "ENVIAR_CAMPANHAS_SUCCESS";
export const ENVIAR_CAMPANHAS_FAIL = "ENVIAR_CAMPANHAS_FAIL";
export const ENVIAR_CAMPANHAS_EXIT = "ENVIAR_CAMPANHAS_EXIT";
export const ENVIAR_CAMPANHAS_CONEXAO_SET = "ENVIAR_CAMPANHAS_CONEXAO_SET";
export const DELETAR_CAMPANHA = "DELETAR_CAMPANHA";

// GRUPOS
export const IMPORTAR_GRUPOS = "IMPORTAR_GRUPOS";
export const IMPORTAR_GRUPOS_EXIT = "IMPORTAR_GRUPOS_EXIT";
export const IMPORTAR_GRUPOS_FETCH = "IMPORTAR_GRUPOS_FETCH";
export const IMPORTAR_GRUPOS_SUCCESS = "IMPORTAR_GRUPOS_SUCCESS";
export const IMPORTAR_GRUPOS_FAIL = "IMPORTAR_GRUPOS_FAIL";
export const GET_GRUPOS = "GET_GRUPOS";
export const GET_GRUPOS_FETCH = "GET_GRUPOS_FETCH";
export const DELETE_GRUPO = "DELETE_GRUPO";
export const EDITAR_CLIQUES_GRUPO = "EDITAR_CLIQUES_GRUPO";
export const EDITAR_CLIQUES_GRUPO_EXIT = "EDITAR_CLIQUES_GRUPO_EXIT";
export const EDITAR_CLIQUES_GRUPO_FETCH = "EDITAR_CLIQUES_GRUPO_FETCH";
export const EDITAR_CLIQUES_GRUPO_SUCCESS = "EDITAR_CLIQUES_GRUPO_SUCCESS";
export const EDITAR_CLIQUES_GRUPO_FAIL = "EDITAR_CLIQUES_GRUPO";
