export const CREATE_CONEXAO = "CREATE_CONEXAO";
export const CREATE_CONEXAO_SUCCESS = "CREATE_CONEXAO_SUCCESS";
export const CREATE_CONEXAO_FAIL = "CREATE_CONEXAO_FAIL";
export const CREATE_CONEXAO_PLAN_EXCEEDED = "CREATE_CONEXAO_PLAN_EXCEEDED";
export const CREATE_CONEXAO_EXIT = "CREATE_CONEXAO_EXIT";

export const GET_CONEXOES = "GET_CONEXAO";
export const GET_CONEXOES_SUCCESS = "GET_CONEXAO_SUCCESS";
export const GET_CONEXOES_FAIL = "GET_CONEXAO_FAIL";

export const LOGOUT_CONEXAO = "LOGOUT_CONEXAO";
export const LOGOUT_CONEXAO_FETCH = "LOGOUT_CONEXAO_FETCH";
export const LOGOUT_CONEXAO_SUCCESS = "LOGOUT_CONEXAO_SUCCESS";
export const LOGOUT_CONEXAO_FAIL = "LOGOUT_CONEXAO_FAIL";
export const LOGOUT_CONEXAO_EXIT = "LOGOUT_CONEXAO_EXIT";

export const GET_CONNECT_CONEXAO = "GET_CONNECT_CONEXAO";
export const GET_CONNECT_CONEXAO_FETCH = "GET_CONNECT_CONEXAO_FETCH";
export const GET_CONNECT_CONEXAO_SUCCESS = "GET_CONNECT_CONEXAO_SUCCESS";
export const GET_CONNECT_CONEXAO_FAIL = "GET_CONNECT_CONEXAO_FAIL";
export const GET_CONNECT_CONEXAO_EXIT = "GET_CONNECT_CONEXAO_EXIT";
