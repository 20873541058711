//INSTANCE
const INSTANCE = "/instance";
export const POST_CREATE_INSTANCE = INSTANCE + "/create";
export const GET_FETCH_INSTANCES = INSTANCE + "/fetchInstances";
export const GET_CONNECT_INSTANCE = INSTANCE + "/connect";
export const PUT_RESTART_INSTANCE = INSTANCE + "/restart";
export const GET_CONNECTION_STATE_INSTANCE = INSTANCE + "/connectionState";
export const DELETE_LOGOUT_INSTANCE = INSTANCE + "/logout";
export const DELETE_INSTANCES = INSTANCE + "/delete";

// GROUP
const GROUP = "/group";
export const POST_CREATE_GROUP = GROUP + "/create";
export const GET_FIND_JID_GROUP = GROUP + "/findGroupInfos";
export const GET_FIND_INVITE_GROUP = GROUP + "/inviteInfo";
export const GET_FETCH_INVITE_CODE = GROUP + "/inviteCode";
export const POST_SEND_INVITE_URL = GROUP + "/sendInvite";
export const FETCH_ALL_GROUPS = GROUP + "/fetchAllGroups";
export const UPDATE_GROUP_PICTURE = GROUP + "/updateGroupPicture/{{instance}}?groupJid={{groupJid}}";
export const UPDATE_PARTICIPANTS = GROUP + "/updateParticipant/{{instance}}?groupJid={{groupJid}}";
export const UPDATE_GROUP_NAME = GROUP + "/updateGroupSubject/{{instance}}?groupJid={{groupJid}}";
export const UPDATE_GROUP_DESCRIPTION = GROUP + "/updateGroupDescription/{{instance}}?groupJid={{groupJid}}";

//MESSAGES
const MESSAGES = "/message";
export const POST_MESSAGE_SENDTEXT = `${MESSAGES}/sendText`;
export const POST_MESSAGE_SENDMEDIA = `${MESSAGES}/sendMedia`;
const POST_MESSAGE_SENDSTATUS = `${MESSAGES}/sendStatus`;
export const POST_MESSAGE_SENDWHATSAPPAUDIO = `${MESSAGES}/sendWhatsAppAudio`;
export const POST_MESSAGE_SENDSTICKER = `${MESSAGES}/sendSticker`;
export const POST_MESSAGE_SENDLOCATION = `${MESSAGES}/sendLocation`;
export const POST_MESSAGE_SENDCONTACT = `${MESSAGES}/sendContact`;
export const POST_MESSAGE_SENDREACTION = `${MESSAGES}/sendReaction`;
export const POST_MESSAGE_SENDPOLL = `${MESSAGES}/sendPoll`;
export const POST_MESSAGE_SENDLIST = `${MESSAGES}/sendList`;

//LOGIN
export const POST_FAKE_LOGIN = "/instance/fetchInstances";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

//PRODUCTS
export const GET_PRODUCTS = "/products";
export const GET_PRODUCTS_DETAIL = "/product";

//CALENDER
export const GET_EVENTS = "/events";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";
export const GET_CATEGORIES = "/categories";

//CHATS
export const GET_CHATS = "/chats";
export const GET_GROUPS = "/groups";
export const GET_CONTACTS = "/contacts";
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "/add/messages";

//ORDERS
export const GET_ORDERS = "/orders";

//CART DATA
export const GET_CART_DATA = "/cart";

//CUSTOMERS
export const GET_CUSTOMERS = "/customers";

//SHOPS
export const GET_SHOPS = "/shops";

//CRYPTO
export const GET_WALLET = "/wallet";
export const GET_CRYPTO_ORDERS = "/crypto/orders";

//INVOICES
export const GET_INVOICES = "/invoices";
export const GET_INVOICE_DETAIL = "/invoice";

//PROJECTS
export const GET_PROJECTS = "/projects";
export const GET_PROJECT_DETAIL = "/project";

//TASKS
export const GET_TASKS = "/tasks";

//CONTACTS
export const GET_USERS = "/users";
export const GET_USER_PROFILE = "/user";
