import {
    CREATE_MENSAGENS,
    CREATE_MENSAGENS_EXIT,
    CREATE_MENSAGENS_FAIL,
    CREATE_MENSAGENS_FETCH,
    CREATE_MENSAGENS_SET,
    CREATE_MENSAGENS_SUCCESS,
    EDITAR_MENSAGEM,
    EDITAR_MENSAGEM_EXIT,
    EDITAR_MENSAGEM_FAIL,
    EDITAR_MENSAGEM_FETCH,
    EDITAR_MENSAGEM_SET,
    EDITAR_MENSAGEM_SUCCESS,
    ENVIAR_MENSAGENS,
    ENVIAR_MENSAGENS_CONEXAO_SET,
    ENVIAR_MENSAGENS_EXIT,
    ENVIAR_MENSAGENS_FAIL,
    ENVIAR_MENSAGENS_FETCH,
    ENVIAR_MENSAGENS_SUCCESS,
    GET_MENSAGENS,
    GET_MENSAGENS_FAIL,
    GET_MENSAGENS_SUCCESS,
    REMOVER_MENSAGEM,
    REMOVER_MENSAGEM_EXIT,
    REMOVER_MENSAGEM_FAIL,
    REMOVER_MENSAGEM_FETCH,
    REMOVER_MENSAGEM_SUCCESS,
} from "./actionTypes";

export const getMensagens = () => ({
    type: GET_MENSAGENS,
});
export const getMensagensSuccessful = (response) => ({
    type: GET_MENSAGENS_SUCCESS,
    payload: {data: response},
});

export const getMensagensFailed = (error) => ({
    type: GET_MENSAGENS_FAIL,
    error: error,
});

export const createMensagem = () => ({
    type: CREATE_MENSAGENS,
});
export const createMensagensSuccessful = (response) => ({
    type: CREATE_MENSAGENS_SUCCESS,
    payload: {data: response},
});

export const createMensagensFailed = (error) => ({
    type: CREATE_MENSAGENS_FAIL,
    error: error,
});

export const createMensagensExit = () => ({
    type: CREATE_MENSAGENS_EXIT,
});
export const createMensagensFetch = (data) => ({
    type: CREATE_MENSAGENS_FETCH,
    payload: {data},
});

export const duplicarMensagem = (data) => ({
    type: CREATE_MENSAGENS_FETCH,
    payload: {data},
});
export const createMensagensSet = (data, field) => ({
    type: CREATE_MENSAGENS_SET,
    payload: {data, field},
});

export const removerMensagem = (data) => ({
    type: REMOVER_MENSAGEM,
    payload: {data},
});
export const removerMensagemSuccessful = (response) => ({
    type: REMOVER_MENSAGEM_SUCCESS,
    payload: {data: response},
});

export const removerMensagemFailed = (error) => ({
    type: REMOVER_MENSAGEM_FAIL,
    error: error,
});

export const removerMensagemExit = () => ({
    type: REMOVER_MENSAGEM_EXIT,
});
export const removerMensagemFetch = (data) => ({
    type: REMOVER_MENSAGEM_FETCH,
    payload: {data},
});

export const editarMensagem = (data) => ({
    type: EDITAR_MENSAGEM,
    payload: {data},
});
export const editarMensagemSuccessful = (response) => ({
    type: EDITAR_MENSAGEM_SUCCESS,
    payload: {data: response},
});

export const editarMensagemFailed = (error) => ({
    type: EDITAR_MENSAGEM_FAIL,
    error: error,
});

export const editarMensagemExit = () => ({
    type: EDITAR_MENSAGEM_EXIT,
});
export const editarMensagemFetch = (data, oldData) => ({
    type: EDITAR_MENSAGEM_FETCH,
    payload: {data, oldData},
});
export const editarMensagemSet = (data) => ({
    type: EDITAR_MENSAGEM_SET,
    payload: {data},
});

export const enviarMensagens = (mensagem) => ({
    type: ENVIAR_MENSAGENS,
    payload: {data: mensagem},
});
export const enviarMensagensSuccessful = (response) => ({
    type: ENVIAR_MENSAGENS_SUCCESS,
    payload: {data: response},
});

export const enviarMensagensFailed = (error) => ({
    type: ENVIAR_MENSAGENS_FAIL,
    error: error,
});

export const enviarMensagensExit = () => ({
    type: ENVIAR_MENSAGENS_EXIT,
});

export const enviarMensagensFetch = (data) => ({
    type: ENVIAR_MENSAGENS_FETCH,
    payload: {data},
});

export const enviarMensagensConexaoSet = (data) => ({
    type: ENVIAR_MENSAGENS_CONEXAO_SET,
    payload: {data},
});
