import {Button, Col, FormGroup, Label, Row} from "reactstrap";
import {withTranslation} from "react-i18next";
import PropTypes, {func} from "prop-types";
import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import {AvForm} from "availity-reactstrap-validation";
import {camposAgendamentos} from "../validation/fields";
import {bindActionCreators} from "redux";
import * as AgendamentoActions from "../../../../../store/agendamento/actions";
import {PhoneInput} from "react-international-phone";
import 'react-international-phone/style.css';
import {isPhoneValid} from "../../../../../util/phone";


const ContatosInput = (props) => {
    const [rows, setRows] = useState([""]);

    function handleAddRowNested() {
        setRows([...rows, ""]);
    }

    function handleRemoveRowNested(e, id) {
        let data = [...rows];
        data.splice(id, 1);
        setRows(data);
    }

    function handleChange(e, id) {
        const updatedFields = [...rows];
        // updatedFields[id] = e.target.value;
        let phoneValid = isPhoneValid(e);
        updatedFields[id] = e;
        setRows(updatedFields);
    }

    useEffect(() => {
        props.editingAgendamentos(rows, camposAgendamentos.CONTATOS);
    }, [rows]);

    return (
        <FormGroup row>
            <Label for="opcoes-enquete" sm={3}>
                {props.t("Contacts")}
            </Label>
            <Col sm={9}>
                <AvForm>
                    <table style={{width: "100%"}}>
                        <tbody>
                        {rows.map((item1, id) => (
                            <tr id={"nested" + id} key={id}>
                                <td>
                                    <Row className="mb-2">
                                        <Col md="8">
                                            <PhoneInput
                                                prefix=''
                                                defaultCountry="br"
                                                onChange={(e) => handleChange(e, id)}
                                            />
                                            {!isPhoneValid(item1) &&
                                                <div style={{color: 'red'}}>Whatsapp inválido</div>}
                                        </Col>
                                        <Col md="4">
                                            <Button
                                                onClick={(e) => {
                                                    handleRemoveRowNested(e, id);
                                                }}
                                                color="primary"
                                                className="btn-block inner border border-secondary"
                                                style={{width: "100%"}}
                                            >
                                                {props.t("WA Poll Options Delete")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <Button
                        onClick={() => {
                            handleAddRowNested();
                        }}
                        color="success"
                        className="mt-1"
                    >
                        {props.t("WA Poll Options Add")}
                    </Button>
                </AvForm>
            </Col>
        </FormGroup>
    );
};
const mapStatetoProps = (state) => ({agendamentosState: state.agendamentos});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators({...AgendamentoActions}, dispatch);

ContatosInput.propTypes = {
    t: PropTypes.any,
    agendamentosState: PropTypes.object,
    editingAgendamentos: func
};
export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(ContatosInput));
