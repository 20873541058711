import PropTypes, {func} from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Col, FormGroup, Input, Label} from "reactstrap";
import React from "react";

import * as AgendamentoActions from "../../../../../store/agendamento/actions";
import {camposAgendamentos} from "../validation/fields";


const VelocidadeSelect = (props) => {
    const handleChangeVelocidade = (event) => {
        props.editingAgendamentos(event.target.value === '0' ? null : event.target.value, camposAgendamentos.VELOCIDADE)
    }
    return (
        <FormGroup row>
            <Label for="mensagem-select" sm={3}>
                {props.t("Speed")}
            </Label>
            <Col sm={9}>
                <Input type='select' name='mensagem-select' id='mensagem-select' onChange={handleChangeVelocidade}
                       value={props.agendamentosState?.agendamento?.velocidade || 0}
                       invalid={props.agendamentosState?.errors?.velocidade}
                       valid={props.agendamentosState?.agendamento?.velocidade}>
                    <option value={0}>Selecione a velocidade</option>
                    <option value={1000}>1s</option>
                    <option value={10000}>Entre 10 e 20s</option>
                    <option value={40000}>Entre 40 e 60s</option>
                </Input>
            </Col>
        </FormGroup>
    );
};

const mapStatetoProps = (state) => ({agendamentosState: state.agendamentos});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators({...AgendamentoActions}, dispatch);

VelocidadeSelect.propTypes = {
    t: PropTypes.any,
    agendamentosState: PropTypes.object,
    editingAgendamentos: func
};
export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(VelocidadeSelect));
