import {Card, CardBody, CardHeader, CardTitle, Col, Row} from "reactstrap";
import React, {useEffect} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as CampanhasActions from "../../../../store/campanhas/actions";
import {useParams} from "react-router-dom";
import QtdLeads from "./components/chart/QtdLeads";
import QtdConversoes from "./components/chart/QtdConversoes";
import QtdLeadsCard from "./components/card/QtdLeadsCard";
import QtdConversoesCard from "./components/card/QtdConversoesCard";
import QtdGruposCard from "./components/card/QtdGruposCard";

const Analytics = (props) => {
    const {idCampanha} = useParams();
    useEffect(() => {
        props.getCampanhas();
    }, []);

    useEffect(() => {
        if (props.campanhasState.campanhas.length !== 0) {
            props.getCampanha(idCampanha);
        }
    }, [props.campanhasState.campanhas]);

    return (
        <div className="page-content">
            <Row>
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h4 className="page-title mb-0 font-size-18">Analytics</h4>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item active">
                                    {props.t("Analytics")}
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader>
                            <CardTitle className="h1 m-auto ">
                                {" "}
                                Quantidade de Leads{" "}
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <QtdLeads/>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={4}>
                    <QtdLeadsCard/>
                </Col>
                <Col lg={4}>
                    <QtdConversoesCard/>
                </Col>
                <Col lg={4}>
                    <QtdGruposCard/>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader>
                            <CardTitle className="h1 m-auto ">
                                {" "}
                                Quantidade de Conversões{" "}
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <QtdConversoes/>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

Analytics.propTypes = {
    t: PropTypes.any,
    campanhasState: PropTypes.object,
    getCampanha: PropTypes.func.isRequired,
    getCampanhas: PropTypes.func.isRequired,
};
const mapStatetoProps = (state) => ({campanhasState: state.campanhas});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators({...CampanhasActions}, dispatch);

export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(Analytics));
