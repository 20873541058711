import {Card, CardBody, Col, Row} from "reactstrap";
import ReactApexChart from "react-apexcharts";
import React from "react";

const series = [70];

const options = {
    plotOptions: {
        radialBar: {
            offsetY: -12,
            hollow: {
                margin: 5,
                size: "60%",
                background: "rgba(59, 93, 231, .25)",
            },
            dataLabels: {
                name: {
                    show: false,
                },
                value: {
                    show: true,
                    fontSize: "12px",
                    offsetY: 5,
                },
                style: {
                    colors: ["#fff"],
                },
            },
        },
    },
    colors: ["#3b5de7"],
};

const QtdLeadsCard = (props) => {
    return (
        <Card>
            <CardBody>
                <Row>
                    <Col xs={8}>
                        <div>
                            <h3 className="text-muted fw-medium mt-1 mb-2">Leads</h3>
                            <h4>1.368</h4>
                        </div>
                    </Col>

                    <div className="col-4">
                        <div>
                            <ReactApexChart
                                options={options}
                                series={series}
                                type="radialBar"
                                height="120"
                            />
                        </div>
                    </div>
                </Row>

                <p className="mb-0">
          <span className="badge badge-soft-success me-2">
            {" "}
              0.8% <i className="mdi mdi-arrow-up"></i>{" "}
          </span>{" "}
                    Do dia anterior
                </p>
            </CardBody>
        </Card>
    );
};

export default QtdLeadsCard;
