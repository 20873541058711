export const genHash = () => Date.now().toString(12);


export function userHash(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        let char = str.charCodeAt(i);
        hash = (hash * 31 + char) % 1000000; // Mantém o hash dentro de 6 dígitos
    }
    return hash;
}
