import {Col, FormGroup, Label} from "reactstrap";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as MensagensActions from "../../../../../../store/mensagens/actions";
import {connect} from "react-redux";
import React from "react";
import {AvField} from "availity-reactstrap-validation";
import {camposMensagens} from "../fields";

const TituloInput = (props) => {
    return (
        <FormGroup row>
            <Label for="titulo-input" sm={3}>
                {props.t("WA Message Title")}
            </Label>
            <Col sm={9}>
                <AvField
                    id="titulo-input"
                    name="titulo"
                    type={"text"}
                    errorMessage="Digite o Título da Mensagem"
                    className="form-control border border-secondary"
                    validate={{required: {value: true}}}
                    value={props.mensagensState.mensagem[camposMensagens.TITULO]}
                    onChange={(e) =>
                        props.createMensagensSet(e.target.value, camposMensagens.TITULO)
                    }
                />
            </Col>
        </FormGroup>
    );
};
TituloInput.propTypes = {
    t: PropTypes.any,
    createMensagensSet: PropTypes.func,
};
const mapStatetoProps = (state) => ({mensagensState: state.mensagens});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators(MensagensActions, dispatch);

export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(TituloInput));
