export const zapeaseRoutes = {
    schedules: "/agendamentos",
    scheduling: "/agendar",
    campaigns: "/campanhas",
    messages: "/mensagens",
    devices: "/dispositivos",
    groupsDetail: "/campanhas/grupos/detalhes/:idCampanha",
    analytics: "/campanhas/analytics/:idCampanha",
    redirect: "/:idUser/:idLink",
};

export const campanhaGruposRoutes = (idCampanha) => {
    return zapeaseRoutes.groupsDetail.replace(":idCampanha", idCampanha);
};
export const campanhaAnalyticsRoutes = (idCampanha) => {
    return zapeaseRoutes.analytics.replace(":idCampanha", idCampanha);
};
