export const TIPO_MIDIA_IMAGEM = "Imagem";
export const TIPO_TEXTO_TEXTO = "Texto";
export const TIPO_MIDIA_ARQUIVO = "Arquivo";
export const TIPO_MIDIA_VIDEO = "Video";
export const TIPO_TEXTO_LINK = "Link";
export const TIPO_TEXTO_ENQUETE = "Enquete";
export const TIPO_MIDIA_AUDIO = "Audio";

export const midiaOptions = [
    {value: TIPO_MIDIA_IMAGEM, label: TIPO_MIDIA_IMAGEM},
    {value: TIPO_MIDIA_VIDEO, label: TIPO_MIDIA_VIDEO},
    {value: TIPO_MIDIA_ARQUIVO, label: TIPO_MIDIA_ARQUIVO},
    {value: TIPO_MIDIA_AUDIO, label: TIPO_MIDIA_AUDIO},
];

export const textoOptions = [
    {value: TIPO_TEXTO_TEXTO, label: TIPO_TEXTO_TEXTO},
    {value: TIPO_TEXTO_LINK, label: TIPO_TEXTO_LINK},
    {value: TIPO_TEXTO_ENQUETE, label: TIPO_TEXTO_ENQUETE},
];

export const TIPO_OPTIONS = [
    {
        label: "Texto",
        options: textoOptions,
    },
    {
        label: "Mídia",
        options: midiaOptions,
    },
];
