// import React from "react"
// import ReactDOM from "react-dom"
// import App from "./App"
// import * as serviceWorker from "./serviceWorker"
// import { BrowserRouter } from "react-router-dom"
// import { Provider } from "react-redux"

// import store from "./store"

// const app = (
//   <Provider store={store}>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </Provider>
// )

// ReactDOM.render(app, document.getElementById("root"))
// serviceWorker.unregister()

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';

import store from './store';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>
);

serviceWorker.unregister();