import {
    TIPO_ADD_ADMINISTRADORES,
    TIPO_ADD_CONTATOS,
    TIPO_DESCRICAO_GRUPO,
    TIPO_IMAGEM_GRUPO,
    TIPO_MENSAGEM,
    TIPO_NOME_GRUPO,
    TIPO_REMOVER_CONTATO
} from "./tipoOptions";

const ID_CAMPANHA = "idCampanha";
const CONEXAO = "conexao";
const MENSAGEM = "mensagem";
const VELOCIDADE = "velocidade";
const DATA_ENVIO = "dataEnvio";
const CONTATOS = "contatos";
const DESCRICAO_GRUPO = "descricaoGrupo";
const IMAGEM_GRUPO = "imagemGrupo";
const NOME_GRUPO = "nomeGrupo";
const GRUPOS = "grupos";

export const camposAgendamentos = {
    ID_CAMPANHA,
    CONEXAO,
    VELOCIDADE,
    DATA_ENVIO,
    CONTATOS,
    DESCRICAO_GRUPO,
    MENSAGEM,
    IMAGEM_GRUPO,
    NOME_GRUPO,
    GRUPOS
};

export const fieldsAgendamentoClean = (fields) => {
    switch (fields.tipo) {
        case TIPO_MENSAGEM:
            return Object.fromEntries(
                [ID_CAMPANHA, CONEXAO, VELOCIDADE, MENSAGEM, DATA_ENVIO, GRUPOS].map((key) => [key, fields[key]]),
            );
        case TIPO_ADD_ADMINISTRADORES:
        case TIPO_ADD_CONTATOS:
        case TIPO_REMOVER_CONTATO:
            return Object.fromEntries(
                [ID_CAMPANHA, CONEXAO, CONTATOS, DATA_ENVIO, GRUPOS].map((key) => [
                    key,
                    fields[key],
                ]),
            );
        case TIPO_DESCRICAO_GRUPO:
            return Object.fromEntries(
                [ID_CAMPANHA, CONEXAO, DESCRICAO_GRUPO, DATA_ENVIO, GRUPOS].map((key) => [
                    key,
                    fields[key],
                ]),
            );
        case TIPO_IMAGEM_GRUPO:
            return Object.fromEntries(
                [ID_CAMPANHA, CONEXAO, IMAGEM_GRUPO, DATA_ENVIO, GRUPOS].map((key) => [
                    key,
                    fields[key],
                ]),
            );
        case TIPO_NOME_GRUPO:
            return Object.fromEntries(
                [ID_CAMPANHA, CONEXAO, NOME_GRUPO, DATA_ENVIO, GRUPOS].map((key) => [
                    key,
                    fields[key],
                ]),
            );
        default:
            return {};
    }
};
