import React, {useEffect, useState} from "react";
import Microlink from "@microlink/react";
import {Col, FormGroup, Label} from "reactstrap";
import {AvField} from "availity-reactstrap-validation";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as MensagensActions from "../../../../../../store/mensagens/actions";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {camposMensagens} from "../fields";

const LinkInput = (props) => {
    const [url, setUrl] = useState(
        props.mensagensState.mensagem[camposMensagens.LINK],
    );
    const [preview, setPreview] = useState(null);

    const handleInputChange = (e) => {
        setUrl(e.target.value);
        setPreview(null); // Reset preview when input changes
    };

    useEffect(() => {
        if (RegExp(/^(ftp|http|https):\/\/[^ "]+$/).exec(url)) {
            setPreview(url);
            props.createMensagensSet(url, camposMensagens.LINK);
        }
    }, [url]);

    return (
        <div>
            <FormGroup row>
                <Label for="link-input" sm={3}>
                    {props.t("WA Link Title")}
                </Label>
                <Col sm={9}>
                    <AvField
                        id="link-input"
                        name="link"
                        type={"text"}
                        value={url}
                        className="form-control border border-secondary"
                        validate={{
                            required: {value: true, errorMessage: "Campo Obrigatório"},
                            pattern: {
                                value: '/^(ftp|http|https):\\/\\/[^ "]+$/',
                                errorMessage: "Digite um Link Válido",
                            },
                        }}
                        onChange={handleInputChange}
                    />
                </Col>
            </FormGroup>
            {preview && (
                <FormGroup row>
                    <Label for="link-preview" sm={3}>
                        {props.t("WA Link Preview")}
                    </Label>
                    <Col sm={9}>
                        <Microlink url={preview} id="link-preview"/>
                    </Col>
                </FormGroup>
            )}
        </div>
    );
};
LinkInput.propTypes = {
    t: PropTypes.any,
    createMensagensSet: PropTypes.func,
};
const mapStatetoProps = (state) => ({mensagensState: state.mensagens});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators(MensagensActions, dispatch);

export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(LinkInput));
